import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FireSvg from "../../../../../../assets/img/games/fire.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import hotToast from 'react-hot-toast';

const BetButtons = ({ gameBetStatus, maxBet }) => {
    const userData = useSelector(state => state.auth.userData)
    const { gameAddress } = useParams()
    const [raiseAmount, setRaiseAmount] = useState(0.1)
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if (gameBetStatus?.userId === userData.id) {
            setIsSelected(false)
        }
    }, [gameBetStatus])

    const myBalance = gameBetStatus?.userBalances?.find(player => player?.userId == userData.id)?.amount || 0

    // Function to handle the call action
    const handleCall = async () => {
        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'Call', amount: gameBetStatus?.betAmount })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
                // toast.error(<ToastContent title="Error" message={responseData.message} />, {
                //     transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                // })
            }
        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    }

    // Function to handle the fold action
    const handleFold = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'Fold', amount: 0 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
            }
        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    }

    // Function to handle the raise action
    const handleRaise = async () => {

        try {
            let raisedAmount = Number(formik.values.raisedAmount)
            let maxBetAmount = Number(gameBetStatus.betAmount)

            if (raisedAmount <= maxBetAmount) return hotToast.error('Amount must be greater than bet amount', { duration: 2000 })
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'Raise', amount: Number(formik.values.raisedAmount).toFixed(2) })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    }

    // Function to handle the check action
    const handleCheck = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'Check', amount: 0 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    }


    const handleBet = async () => {
        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'Bet', amount: 0.1 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    
    }

    const handleAllIn = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/5-card-poker/play-bet', { gameAddress, action: 'allin', amount: 0.1 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 3000})
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 3000})
        }
    }



    const handleAction = (action) => {
        if (action === 'Call') handleCall();
        if (action === 'Fold') handleFold();
        if (action === 'Raise') handleRaise();
        if (action === 'Check') handleCheck();
        if (action === 'Bet') handleBet();
        if (action === 'allin') handleAllIn();
        return;
    }


    const buttons = gameBetStatus.buttons;

    // let raisedAmount = Number((Number(Number(gameBetStatus?.maxBet).toFixed(2)) + 0.1).toFixed(2))
    let raisedAmount = Number(gameBetStatus?.betAmount).toFixed(2)
    raisedAmount = Number(raisedAmount)
    

    if (gameBetStatus?.betAmount <= 0) {
        raisedAmount = 0.1
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            raisedAmount: (Number(Number(raiseAmount).toFixed(2)) + Number(raisedAmount)).toFixed(2)
        },
        validationSchema: Yup.object({
            raisedAmount: Yup.number().required('Required').min(raisedAmount, `Amount must be greater than ${Number(raisedAmount).toFixed(2)}`).max(myBalance, 'Insufficient balance')
        }),
        onSubmit: () => {
            handleAction('Raise')
        }
    })

    const handleIncreaseAmount = () => {
        if (raiseAmount >= myBalance) return
        setRaiseAmount(raiseAmount + 0.1)
    }

    const handleDecreseAmount = () => {
        if (Number(raiseAmount).toFixed(2) <= 0.1) return
        setRaiseAmount(raiseAmount - 0.1)
    }


    if (isSelected) return null;

    return (
        <>
            <div className="flex flex-col justify-center space-x-4 p-1 w-full md:w-full lg:w-1/2">
                <div className='call_action p-0 text-xs flex flex-row items-start justify-center rounded-lg gap-1'>
                    {buttons.includes('Call') && <button onClick={() => handleAction('Call')} className="bg-blue-900 text-xs w-full py-2 px-3 rounded-lg mb-1 text-white">
                        Call <span >({Number(gameBetStatus?.betAmount).toFixed(2)} Sol)</span>
                    </button>}
                    {buttons.includes('Check') && <button onClick={() => handleAction('Check')} className="bg-blue-800 text-xs w-full py-2 px-3 rounded-lg mb-1 text-white">
                        Check
                    </button>}
                    {buttons.includes('Fold') && <button onClick={() => handleAction('Fold')} className="bg-red-800 text-xs py-2 rounded-lg text-white w-full">
                        Fold
                    </button>}
                    {buttons.includes('Bet') && <button onClick={() => handleAction('Bet')} className="bg-blue-300 text-xs py-2 rounded-lg text-black w-full">
                        Bet (0.1 Sol)
                    </button>}
                    {buttons.includes('AllIn') && <button onClick={() => handleAction('allin')} className="bg-transparent text-red-400 text-xs py-1.5 rounded-lg font-medium border-2 border-red-400 w-full flex items-center justify-center">
                        All-In <img src={FireSvg} className='w-4 h-4 ml-1' />
                    </button>}

                </div>




                {buttons.includes('Raise') && <form onSubmit={formik.handleSubmit}  className='raise_action mx-0 mt-2 gap-1 flex flex-row text-xs items-center justify-between rounded-lg w-full' >
                    <div className='flex flex-col w-36'>
                        <input placeholder='Example: 0.30' type="number" name='raisedAmount' value={formik.values.raisedAmount} onChange={formik.handleChange} className=" py-2 px-3 rounded-lg  text-white bg-gray-600 border border-white" />
                    </div>

                    <button type='submit' className="bg-green-800 border py-2 rounded-lg text-white w-full">

                        Raise {Number(formik.values.raisedAmount).toFixed(2)} Sol
                    </button>
                    <button type='button' onClick={handleIncreaseAmount} className="text-white text-xs h-full py-1 px-2 border bg-green-800 rounded-lg">
                        <AddIcon />
                    </button>
                    <button type='button' onClick={handleDecreseAmount} className="text-white text-xs h-full py-1 px-2 border bg-green-800 rounded-lg">
                        <RemoveIcon />
                    </button>
                </form>}
            {formik.errors.raisedAmount && formik.touched.raisedAmount && <span className='text-danger text-xs'>{formik.errors.raisedAmount}</span>}

            </div>
        </>

    )
}

export default BetButtons
