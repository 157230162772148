import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CopyrightIcon from '@mui/icons-material/Copyright';
export default function Footer() {


  const redirectToTwitter = () => {
    const url = 'https://x.com/PvPSolanaCasino'
    window.open(url, '_blank')
  }

  return (
    <div className="flex flex-col lg:flex-row bg-gradient-to-r from-[#c407f822] via-purple-700 to-[#c407f822] justify-between w-full px-4 lg:px-32 py-6 lg:py-10">
      <div className="flex flex-col mb-6 lg:mb-0">
        <p className="text-white">
          Copyright <CopyrightIcon /> 2024, All rights reserved.
        </p>
        <p className="text-white">
        This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.  All bets are final
        </p>
        {/* <p className="text-white">and at your own risk.</p> */}
      </div>
      <div className="flex justify-center items-center gap-4 lg:gap-10">
        <span className="border rounded-full p-2 cursor-pointer hover:bg-black">
          <FacebookIcon className="text-white" />
        </span>
        <span className="border rounded-full p-2 cursor-pointer hover:bg-black">
          <WhatsAppIcon className="text-white" />
        </span>
        <span onClick={() => redirectToTwitter()} className="border rounded-full p-2 cursor-pointer hover:bg-black">
          <XIcon className="text-white" />
        </span>
        <span className="border rounded-full p-2 cursor-pointer hover:bg-black">
          <LinkedInIcon className="text-white" />
        </span>
      </div>
    </div>
  );
}
