import React, { useEffect, useState } from 'react'
import BetButtons from './BetButtons';
import PlayButtons from './PlayButtons';
import { useSelector } from 'react-redux';
const SevenTwentySevenActionbuttons = ({ maxBet, pot, gameStatus, gameBetStatus, currentMoveInfo }) => {
    
    const userData = useSelector(state => state.auth.userData)

    return (
        <div className='flex flex-col items-center justify-start h-full w-full md:w-full'>
            {(gameStatus == 'betting') && (gameBetStatus?.currentPlayer == userData?.id) && <BetButtons gameBetStatus={gameBetStatus} />}
            {(gameStatus == 'playing') && (currentMoveInfo?.currentPlayer == userData?.id) && <PlayButtons pot={pot} maxBet={maxBet} currentMoveInfo={currentMoveInfo}/>}
        </div>
    )
}

export default SevenTwentySevenActionbuttons
