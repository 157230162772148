import React from 'react';
import { motion } from 'framer-motion';
import img from "../Image/nodatafound.png";

export default function NodataFound() {
  return (
    <div className='flex justify-center items-center h-full'>
      <motion.img
        src={img}
        alt="No data found"
        className="h-[50%] w-[50%]"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      />
    </div>
  );
}
