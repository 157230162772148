import React, { useEffect, useState } from 'react'
import { formatAddress } from '../../../../../../Config/function'
import SolToUSD from '../../../../../../CommonComponent/SolToUSD'
import FoldImage from '../../../../../../assets/img/fold.png'
import LeftImage from '../../../../../../assets/img/left.png'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';

const Player = ({ showResultCard, gameStatus, player, userBalances, foldedPlayers, isWaiting, leftPlayers, currentPlayer, socket, timer, position }) => {

  const [userBetInfo, setUserBetInfo] = useState(null)
  const [userMoveInfo, setUserMoveInfo] = useState(null)
  const userData = useSelector(state => state.auth.userData)
  const solPrice = useSelector(state => state.auth.solPrice)
  const userBalance = userBalances?.find(balance => balance?.userId === player?.userId)
  const isFolded = foldedPlayers?.includes(player.userId)
  const isLeft = leftPlayers?.includes(player.userId)


  const RenderBetInfo = ({ amount, action }) => {

    if (action === 'Fold') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl'>
        <span className='text-xs'>{action}</span>
      </span>
    }
    if (action === 'Check') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl'>
        <span className='text-xs'>{action}</span>
      </span>
    }
    if (action === 'Call') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>{action}</span>
        <span className='text-xs'>{Number(amount).toFixed(2)} Sol</span>
      </span>
    }

    if (action === 'Raise') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>{action}</span>
        <span className='text-xs'>{Number(amount).toFixed(2)} Sol</span>
      </span>
    }
    if (action === 'Bet') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>{action}</span>
        <span className='text-xs'>{Number(amount).toFixed(2)} Sol</span>
      </span>
    }

    return null


  }

  const RenderMoveInfo = ({ amount, action }) => {
    if (action === 'high') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>High</span>
        <span className='text-xs'>{Number(amount).toFixed(2)} Sol</span>
      </span>
    }
    if (action === 'low') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>Low</span>
        <span className='text-xs'>{Number(amount).toFixed(2)} Sol</span>
      </span>
    }
    if (action === 'Timeout') {
      return <span className='hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2  font-extrabold flex items-center justify-center bg-white text-black shadow-2xl flex-col'>
        <span className='text-xs'>Timeout</span>
      </span>
    }
  }




  useEffect(() => {
    if (!socket) return;

    socket.on('userBetPlayed', (data) => {
      setUserBetInfo(data);

      setTimeout(() => {
        setUserBetInfo(null)
      }, 3000);
    })

    socket.on('userMovePlayed', (data) => {
      setUserMoveInfo(data);

      setTimeout(() => {
        setUserMoveInfo(null)
      }, 3000);
    })


    return () => {
      socket.off('userBetPlayed')
      socket.off('userMovePlayed')
    }
  }, [socket])

  const isTimer = timer > 0

  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)

    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        if (timeLeft === 0) return;
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])

    if (gameStatus === 'starting') return null;
    if (timeLeft <= 0) return null;

    if (showResultCard) return null;


    const calculateDashOffset = () => {
      const circumference = 2 * Math.PI * 45
      const dashOffset = circumference * (1 - timeLeft / 30)
      return dashOffset
    }

    let color = '#55eb34'
    if (timer <= 10) {
      color = 'red'
    } else if (timer <= 20) {
      color = 'yellow'
    }

    return <svg className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="purple"
        strokeWidth="6"
      />
      {/* Progress arc */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={calculateDashOffset()}
        transform="rotate(-90 50 50)"
      />
    </svg>
  }

  const RenderPlayerBalance = ({ amount }) => {
    if (!amount) return null
    return <div className='flex flex-col justify-center items-center'>
      {Number(amount).toFixed(2)} Sol
      <span className='text-xs '>({(Number(amount) * solPrice).toFixed(2)} USD)</span>
    </div>
  }

  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600 text-white`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }


  return (
    <>
      {false && <div className='hi-lo-player flex items-center flex-col	'>
        {(userBetInfo && userBetInfo?.userId === player.userId) && <RenderBetInfo amount={userBetInfo?.amount} action={userBetInfo?.action} />}

        {(userMoveInfo && userMoveInfo?.userId === player.userId) && <RenderMoveInfo amount={userMoveInfo?.amount} action={userMoveInfo?.action} />}

        {!(userBetInfo && userBetInfo?.userId === player.userId) && (!(userMoveInfo && userMoveInfo?.userId === player.userId)) && !isFolded && !isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${player.userId === currentPlayer && 'hi_lo_player__profile_active'}`} />}


        {isFolded && !isLeft && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-12 w-20 p-1/2 `} />}
        {isLeft && !isFolded && <img src={LeftImage} alt='profile' className={`hi-lo- h-12 w-20 p-1/2 `} />}
        {isLeft && isFolded && <img src={FoldImage} alt='profile' className={`hi-lo- h-12 w-20 p-1/2 `} />}

        <div className={`${currentPlayer == player.userId ? "bg-green-500 text-black" : "bg-black text-white "}`}>
          <div className={`flex flex-col items-center justify-center  font-extrabold px-5 text-center border-2 rounded-md `}>
            <div id={`hilo_player_${player.userId}`} className='name mb-1 text-sm'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>

            <div className='relative flex flex-row items-center justify-center'>
              {isTimer && currentPlayer === player.userId && <RenderTimer />}
              {player.userId === userData?.id ? <span className='text-xs'>{isWaiting ? Number(player.amount).toFixed(2) : Number(userBalance?.amount).toFixed(2)} Sol</span> : <span className='text-xs'>***** Sol</span>}
              {player.userId === userData?.id ? (<span className='text-xs ml-1'>
                (<SolToUSD amount={isWaiting ? (player.amount) : userBalance?.amount} symbol='USD' />)
              </span>) : <span className='text-xs ml-1'>
                (***** USD)
              </span>}
            </div>

          </div>
        </div>

      </div>}

      <div className='hi-lo-player  rounded-full'>

        <div className='user_info flex items-center justify-center flex-col'>
          {currentPlayer === player.userId && <RenderTimer />}

          {isFolded && !isLeft && <img src={FoldImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {isLeft && !isFolded && <img src={LeftImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {isLeft && isFolded && <img src={FoldImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {!isLeft && !isFolded && <img src={player.profileUrl} alt='profile' className='rounded-full h-10 w-10 border-white border-2' />}
          {player.userId !== userData?.id && <span id={`hilo_player_${player.userId}`} className='text-xs bg-red-600 text-white  px-2 py-1 font-extrabold w-auto'>
            {<span className='text-xs w-full'>{
              player.userName ? player.userName : formatAddress(player.address, 3)
            }</span>}
          </span>}
          {player.userId === userData?.id && <span id={`hilo_player_${player.userId}`} className='text-xs bg-red-600 text-white px-2 py-1 font-extrabold w-max flex flex-col items-center justify-between rounded-md'>
            {<span className='text-xs'>{isWaiting ? Number(player.amount).toFixed(2) : Number(userBalance?.amount).toFixed(2)} Sol</span>}
            {(<span className='text-xs ml-1'>(<SolToUSD amount={isWaiting ? (player.amount) : userBalance?.amount} symbol='USD' />)
            </span>)}
          </span>}
        </div>
      </div>
    </>

  )
}

export default Player
