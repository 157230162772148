import React, { useState } from 'react'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../CommonComponent/Toast';
import BetMoneyModal from '../../../../CommonComponent/BetMoneyModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
const JoinGame = ({ joinGame, gameInfo }) => {


  // get Query params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const join = urlParams.get('join');
  const navigate = useNavigate()

  const [isJoin, setisJoin] = useState(false)

  const handleCopy = () => {
    try {
      toast.success(<ToastContent status='success' message='Address copied' />, {
        transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      })
    } catch (error) {
      toast.error(<ToastContent status='error' message={error.message} />, {
        transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      })
    }
  }


  const handleJoinGame = () => {
    setisJoin(true)
    joinGame().then(() => {
      setisJoin(false)
    }).catch(() => {
      setisJoin(false)
    })
  }

  const copiedContent = () => {
    return `${gameInfo?.gameAddress}`;
  }

  const handleClick = () => {
    navigate("/all-game-page")
  }

  return (
    <div className='relative w-full'>
      <button className=' text-white text-xs font-extrabold py-2' onClick={handleClick} role='button'>
        <ArrowBackIcon /> Go Back
      </button>
      <div className='text-white border w-full border-gray-300 rounded-lg p-6  shadow-lg shadow-white'>

        <div className='flex  justify-center'>
          <img src={gameInfo?.gameInfo?.image} alt='game' className='w-[100px] h-[100px] rounded-circle' />
        </div>

        <h2 className='text-center text-2xl font-bold'>{gameInfo?.gameInfo?.title}</h2>
        <p className='text-center text-lg my-4'>{gameInfo?.gameInfo?.description}</p>
        <div className='flex justify-center '>
          <button disabled={isJoin} onClick={handleJoinGame} type="button" class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-bold rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            {join ? 'Play Game' : <>
              {isJoin ? 'Joining...' : 'Join Game'}
            </>}
            <PlayArrowOutlinedIcon className='ms-2' />
          </button>
          {!join && gameInfo?.gameInfo?.slug == 'rock-paper-scissors' && <CopyToClipboard text={copiedContent()} onCopy={handleCopy} >
            <button type="button" class=" bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-300 text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
              Copy Address
              <FileCopyOutlinedIcon className='ms-2' />
            </button>
          </CopyToClipboard>}
        </div>
      </div>
    </div>
  )
}

export default JoinGame