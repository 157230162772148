import React, { useState } from 'react'
import MenuActions from '../PlayGame/MenuActions'
import Player from '../PlayGame/Player'
import { useSelector } from 'react-redux'
import { isEmpty } from '../../../../../Config/function'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ToastContent from '../../../../../CommonComponent/Toast'
import axiosInstance from '../../../../../Config/axios'
import StartGameAction from './StartGameAction'
import { useNavigate } from 'react-router-dom'
import hotToast from 'react-hot-toast'
import Chat from '../../../../ChatingComponent/chatingComponent'
import HiloIcon from "../../../../../assets/img/games/hilo_background_image.png";
import TableLeft from "../../../../../assets/img/games/hilo/table_left.png";
import TableRight from "../../../../../assets/img/games/hilo/table_right.png";
const WaitingForPlayers = ({ gameInfo, fetchGameInfo }) => {
    const userData = useSelector(state => state.auth.userData)
    const gameCreated = gameInfo?.createdBy
    const navigate = useNavigate()

    const gameAddress = gameInfo?.gameAddress
    const joinedPlayers = gameInfo?.players || []
    const minPlayers = gameInfo.minPlayers
    const maxPlayers = gameInfo.maxPlayers

    const myProfile = joinedPlayers.find(player => player.userId === userData?.id)
    const restOtherPlayers = joinedPlayers.filter(player => player.userId !== userData?.id)

    const remainingPlayers = Array(maxPlayers - joinedPlayers.length).fill({
        address: '0x',
        joined: false
    })

    let players = []
    if (myProfile) {
        players = [myProfile, ...restOtherPlayers]
    } else {
        players = [...joinedPlayers]
    }


    const isInGame = !isEmpty(myProfile)


    const handleJoinGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/join', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
            } else {
                const data = { slug: 'highlow', gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
            }


        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    let gameCanStart = false
    const totalPlayers = joinedPlayers.length
    if (totalPlayers >= minPlayers) {
        gameCanStart = true
    }


    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (players.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (players.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (players.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/4 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-1/4 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 8) { }

        return className
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


        if (totalPlayers == 9) {
            if (index == 0) {
                return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
            }
            if (index == 1) {
                return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
            }
            if (index == 2) {
                return `absolute left-0 sm:left-5 bottom-1/4 `;
            }
            if (index == 3) {
                return `absolute left-0 sm:left-5 top-1/4 `;
            }
            if (index == 4) {
                return `absolute top-0 left-1/4 -translate-x-1/4 `;
            }
            if (index == 5) {
                return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
            }
            if (index == 6) {
                return `absolute right-6 sm:right-16 top-1/4 `;
            }
            if (index == 7) {
                return `absolute right-6 sm:right-16 bottom-1/4`;
            }
            if (index == 8) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }
            if (index == 9) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }

        }

    };

    let samplePlayers = players
    if (samplePlayers.length == 2) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 3) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 4) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 5) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 6) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
    } else if (samplePlayers.length == 7) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
    } else if (samplePlayers.length == 8) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        const eighthPlayer = samplePlayers[7];
        samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
    } else {
        samplePlayers = samplePlayers;
    }



    return (
        <>
            {false && <div id='hilo_screen' className='game relative flex flex-col items-center h-full'>
                {/* <Chat userId={userData?.id} roomId={gameAddress} /> */}
                <MenuActions gameStatus={gameInfo?.status} isInGame={isInGame} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} />
                <div className='w-full h-[75%] p-3 bg-gray-900 border-4  mt-10 container rounded-2xl '>
                    <div className="relative rounded-lg w-full h-full  bg-green-900 border-4">


                        {players.map((player, index) => {
                            return (
                                <div key={index} className={getClassName(index)}>
                                    <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                        <Player isWaiting={true} player={player} />
                                    </div>
                                </div>
                            );

                        })}
                        {!isInGame && <div className="absolute left-1/2 w-100  right-1/2 top-1/2 flex transform -translate-x-1/2 -translate-y-1/2 items-center justify-center  ">
                            <button onClick={handleJoinGame} className='bg-gray-200 mr-2 text-black px-5 py-2.5 rounded-md btn text-center text-xs font-extrabold'>Join Game</button>
                        </div>}


                    </div>
                </div>



                {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
                    <div className='flex items-center justify-center h-full'>
                        {!gameCanStart && <span className='text-white font-bold text-md'>
                            {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                        </span>}
                        {!isInGame && (
                            <button
                                onClick={handleJoinGame}
                                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                            >
                                Join Game
                            </button>
                        )}
                        {gameCanStart && <StartGameAction fetchGameInfo={fetchGameInfo} />}
                    </div>
                </div>}

            </div>}

            <div id='hilo_screen' className="bg-gradient-to-br from-[#7023b8] via-[#240341] to-[#5d03ac] h-screen w-full mx-auto p-2 pt-5 ">

                <div className='mx-auto mt-3 px-3 container absolute top-0 left-0 bg-transparent font-extrabold ' >
                    <MenuActions isInGame={isInGame} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} />
                </div>
                <div className="poker_table_golden relative w-full h-[75vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 mt-10 "
                    // style={{
                    //     backgroundImage: `url('https://i.pinimg.com/736x/f6/e6/91/f6e691bc7fed916c02d373464d8c3257.jpg')`,
                    //     backgroundSize: 'cover',
                    //     backgroundRepeat: 'no-repeat',
                    //     backgroundPosition: 'center',
                    //     boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                    // }}
                >

                    <div className="absolute md:block top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2  text-2xl font-bold opacity-90 h-36">
                        <img src={HiloIcon} className='h-32 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90 h-36">
                        <img src={TableLeft} className='h-52 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90 h-36">
                        <img src={TableRight} className='h-52 w-30' />
                    </div>
                    {/* <div className="absolute md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-70 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div> */}



                    <div className="elative h-full bg-[#250a3d] border-1 rounded-full shadow-xl border-gray-400" style={{
                        boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`,
                        background: 'linear-gradient(90deg, rgba(62,50,0,1) 0%, rgba(108,91,0,1) 18%, rgba(133,113,1,1) 41%, rgba(133,113,1,1) 64%, rgba(108,91,0,1) 84%, rgba(62,50,0,1) 99%)'
                    }}>

                        <div className='flex md:hidden w-full text-center flex-col absolute top-1/4 left-1/2 transform -translate-x-1/2 opacity-70'>
                            <h2 className=' sm:text-2xl md:text-3xl text-yellow-400 w-full font-extrabold mt-2 font-banger'>PVP Solana Casino</h2>
                        </div>
                        <div className='flex w-full text-center flex-col absolute bottom-1/4 left-1/2 transform -translate-x-1/2 opacity-70'>
                            <h2 className=' sm:text-2xl md:text-4xl text-white w-full font-extrabold mt-2 font-banger'>Hi-Lo Card</h2>
                        </div>
                        {samplePlayers.map((player, index) => {
                            return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)}`} >
                                <Player isWaiting={true} player={player} />
                            </div>
                        })}


                    </div>

                </div>


                <div className='w-full h-auto mt-10 container rounded-lg'>
                    <div className='flex items-center justify-center h-full'>
                        {isInGame && !gameCanStart && <span className='text-white font-bold text-md text-center'>
                            {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                        </span>}
                        {!isInGame && (
                            <button
                                onClick={handleJoinGame}
                                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                            >
                                Join Game
                            </button>
                        )}
                        {(gameCanStart && (gameCreated == userData?.id)) && <StartGameAction fetchGameInfo={fetchGameInfo} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default WaitingForPlayers
