import React from 'react'
import SolToUSD from '../../../../../../CommonComponent/SolToUSD'
const PotMoney = ({ pot }) => {

    if (pot == 0) return null;
    
    return (
        <div className='pvp04 flex flex-col items-center justify-center'>
            <h4 className='text-white text-lg'>{Number(pot).toFixed(2)} Sol</h4>
            <span className='text-white font-extrabold'>
                (<SolToUSD amount={pot} symbol={'USD'} />)
            </span>
        </div>
    )
}

export default PotMoney
