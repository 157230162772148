import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import hotToast from 'react-hot-toast'; 
const ShareModal = ({ handleClose, isOpen }) => {


    const shareUrl = process.env.REACT_APP_BASE_URL;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        hotToast.success('Copied to clipboard');
    };


    const shareOnFacebook = () => {
    };


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md relative">

                <button
                    onClick={handleClose}
                    className="absolute top-2 right-2 text-white hover:text-white"
                >
                    <CloseIcon size={24} />
                </button>


                <div className="flex mb-4 mt-3  border rounded-md">
                    <input
                        type="text"
                        value={process.env.REACT_APP_BASE_URL}
                        readOnly
                        disabled
                        className="flex-grow text-white px-3 text-sm italic py-1"
                    />
                    <span
                        onClick={copyToClipboard}
                        role='button'
                        className=" text-white text-xs px-2 py-1 rounded-r-md  flex items-center"
                    >
                        <ContentCopyIcon size={18} className="mr-2" />
                    </span>
                </div>
                <div className="flex flex-col justify-between mt-2">
                   
                    <span onClick={shareOnFacebook} type="button" class="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 me-0 mb-2">
                        <FacebookSharpIcon fontSize="medium" className='mr-2' />
                        Share on Facebook
                    </span>
                    <span type="button" class="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-0 mb-2">
                        <XIcon fontSize="medium" className='mr-2' />
                        Share on X
                    </span>
                    <span type="button" class="text-white bg-gradient-to-tr from-[#f9ce34] via-[#ee2a7b] to-[#662a7c] focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 me-0 mb-2">
                        <InstagramIcon fontSize="medium" className='mr-2' />
                        Share on Instagram
                    </span>
                    <span type="button" class="text-white bg-[#25d366] hover:bg-[#25d366]/90 focus:ring-4 focus:outline-none focus:ring-[#25d366]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#25d366]/55 me-0 mb-2">
                        <WhatsAppIcon fontSize="medium" className='mr-2' />
                        Share on WhatsApp
                    </span>
                    <span type="button" class="text-white bg-[#24A1DE] hover:bg-[#24A1DE]/90 focus:ring-4 focus:outline-none focus:ring-[#24A1DE]/50 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-[#050708]/50 me-0 mb-2">
                        <TelegramIcon fontSize="medium" className='mr-2' />
                        Share on Telegram
                    </span>

                </div>
            </div>
        </div>
    )
}

export default ShareModal
