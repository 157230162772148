import React, { useEffect, useState } from 'react'

import rock from '../../../../Image/rock2.png';
import paper from '../../../../Image/paper2.png';
import scissor from '../../../../Image/scissor2.png';

const moves = [{
    name: 'rock',
    image: rock,
  }, {
    name: 'paper',
    image: paper,
  }, {
    name: 'scissors',
    image: scissor,
  }];


const ActionButtons = ({ handleMove, isGameDisabled, gameInfo }) => {

    const [isSelected, setIsSelected] = useState(false);

    const handleSelect = (move) => {
        handleMove(move).then(() => {
            setIsSelected(true);
        }).catch(() => {
            setIsSelected(false);
        })
    }


    useEffect(() => {
        if (gameInfo?.userMove?.status == 'choosed') {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [gameInfo])


  return (
    <div className="absolute bottom-5 left-1/2 -translate-x-1/2  flex flex-col items-center ">
        <div className="flex justify-around w-full  ">
          {moves.map((move, index) => (
            <button onClick={() => handleSelect(move)} key={index}
              disabled={isGameDisabled() || isSelected}
              // 
              className={`p-2 rounded-full border-2 px-5 capitalize mr-1 bg-gradient-to-r from-amber-300 via-yellow-400 to-amber-300 border-black text-black  ${isGameDisabled() && "cursor-not-allowed"}`}>
              <span className='text-black text-sm font-extrabold btn-theme'>{move.name}</span>
            </button>
          ))}
        </div>
      </div>
  )
}

export default ActionButtons
