import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../CommonComponent/Toast';
import axiosInstance from '../../../../Config/axios';
import socketIOClient from 'socket.io-client';

import WaitingForPlayers from './WaitingUsers';
// import PlayPoker from './Play/Index';
import PlayPoker from '../PokerTest/index';
import Loading from './Loading';
import { isEmpty } from '../../../../Config/function';
import GameResult from './GameResult';
import hotToast from 'react-hot-toast';
import Chat from '../../../ChatingComponent/chatingComponent';
import PokerAudio from '../../../../CommonComponent/PokerAudio';

const ENDPOINT = process.env.REACT_APP_API_URL;

const PokerPlay = () => {

  const { gameAddress } = useParams()

  const [gameInfo, setGameInfo] = useState({})
  const [socket, setSocket] = useState(null);


  const leaveGame = async () => {



    try {
      const response = await axiosInstance.post('/api/game/poker/leave', { gameAddress })

      const { data: responseData } = response
      if (!responseData.status) {
      } else {
      }
    } catch (error) {
    }
  }



  useEffect(() => {
    if (!gameAddress || !socket) return
    fetchPlayers()
  }, [socket, gameAddress])

  useLayoutEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave the game? You will lose the game if you leave now.';
      event.returnValue = message;
      return message;
    };

    const handleUnload = () => {
      leaveGame();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);


  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('opponentJoined', (data) => {
      fetchPlayers()
    });

    newSocket.on('opponentLeft', (data) => {
      fetchPlayers()
    })

    newSocket.on('gameStarted', (data) => {
      hotToast.success('Game started', { duration: 2000, position: 'bottom-center' })
      fetchPlayers()
    });

    return () => {
      newSocket.off('opponentJoined')
      newSocket.off('opponentLeft')
      newSocket.off('gameStarted')
    }

  }, [gameAddress])

  const fetchPlayers = async () => {
    try {
      const bodyData = { gameAddress }

      const response = await axiosInstance.post('/api/game/poker/players', bodyData)

      const { data: responseData } = response

      if (!responseData.status) {
        return toast.error(<ToastContent status='error' message={responseData.message} />, {
          transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        })
      } else {
        if (socket) addUserToSocket(responseData.data.players)
        setGameInfo(responseData.data)
      }

    } catch (error) {
      toast.error(<ToastContent status='error' message='Error fetching players' />, {
        transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      })
    }
  }
  const userData = JSON.parse(localStorage.getItem('userData'))


  const handlePlayAgain = async () => {
    try {
      const response = await axiosInstance.post('/api/game/poker/play-again', { gameAddress })

      const { data: responseData } = response

      if (!responseData.status) {
        return toast.error(<ToastContent status='error' message={responseData.message} />, {
          position: 'bottom-center', theme: 'dark', autoClose: 3000
        })
      } else {
        const data = { slug: 'poker', gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        fetchPlayers()
      }
    } catch (error) {
      toast.error(<ToastContent status='error' message='Something went wrong' />, {
        position: 'bottom-center', theme: 'dark', autoClose: 3000
      })
    }
  }


  const addUserToSocket = (players) => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const isInGame = players.filter(player => player.address == userData?.address).length > 0
    if (isInGame) {
      socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })
    } else {
      socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })

    }
  }

  const handlePauseForAll = (playing) => {
    if (playing) {
      socket.emit('pauseGameMusic', { roomId: gameAddress })
    } else {
      socket.emit('playGameMusic', { roomId: gameAddress })
    }
  }

  const allPlayers = gameInfo?.players || []
  const currentPlayer = allPlayers.find(player => player.address === JSON.parse(localStorage.getItem('userData')).address)

  const chatingComponentMemo = useMemo(() => <Chat userId={userData?.id} roomId={gameAddress} />, [])
  const pokerAudioMemo = useMemo(() => <PokerAudio createdBy={gameInfo?.createdBy} socket={socket} gameAddress={gameAddress} handlePauseForAll={handlePauseForAll} />, [gameInfo, socket])

  if (isEmpty(gameInfo)) return <div style={{
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }} className='poker_loading h-full flex flex-col items-center justify-center'><Loading /> </div>;



  return (
    <>
      {pokerAudioMemo}
      {chatingComponentMemo}
      {(gameInfo['status'] == 'pending' && currentPlayer) &&  <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />}
      {(gameInfo['status'] == 'pending' && !currentPlayer) && <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />}
      {(gameInfo?.status == 'started') && <PlayPoker fetchGameInfo={fetchPlayers} socket={socket} gameInfo={gameInfo} />}
      {((gameInfo?.status == 'completed') && !currentPlayer) && <GameResult handlePlayAgain={handlePlayAgain} fetchGameInfo={fetchPlayers} socket={socket} gameInfo={gameInfo} />}
      {((gameInfo?.status == 'completed') && currentPlayer) && <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />}
    </>
  )

  // if (gameInfo['status'] == 'pending' && currentPlayer) {
  //   return (
  //     <React.Fragment>

  //       <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />
  //     </React.Fragment>
  //   )
  // }

  // if (gameInfo['status'] == 'pending') {
  //   return (
  //     <div className='relative'>
  //       {chatingComponentMemo}
  //       {pokerAudioMemo}
  //       <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />
  //     </div >
  //   )
  // }

  // if (gameInfo?.status == 'started') {
  //   return (
  //     <React.Fragment>
  //       {chatingComponentMemo}
  //       {pokerAudioMemo}
  //       <PlayPoker fetchGameInfo={fetchPlayers} socket={socket} gameInfo={gameInfo} />
  //     </React.Fragment>
  //   )
  // }

  // if ((gameInfo?.status == 'completed') && !currentPlayer) {
  //   return (
  //     <React.Fragment>
  //       {pokerAudioMemo}
  //       {chatingComponentMemo}
  //       <GameResult handlePlayAgain={handlePlayAgain} fetchGameInfo={fetchPlayers} socket={socket} gameInfo={gameInfo} />
  //     </React.Fragment>
  //   )
  // }

  // if ((gameInfo?.status == 'completed') && currentPlayer) {
  //   return (
  //     <React.Fragment>
  //       {pokerAudioMemo}
  //       {chatingComponentMemo}
  //       <WaitingForPlayers gameInfo={gameInfo} fetchPlayers={fetchPlayers} socket={socket} gameStatus={gameInfo?.status} minPlayers={gameInfo?.minPlayers} maxPlayers={gameInfo?.maxPlayers} players={gameInfo?.players} />
  //     </React.Fragment>
  //   )
  // }

}

export default PokerPlay