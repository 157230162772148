import React, { useContext, useState } from "react";
// import a1 from "../../../Image/bonus 1.png";
import a1 from "../../../assets/img/nft_banner.png";
import a2 from "../../../assets/img/futuregames.png";
import a3 from "../../../assets/img/sharewithfriends.png";
import slide from "../../../Image/slide.PNG";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "../../Shared/Slider";
import { StakingApp } from "../../Authentication/Context/StakingApp";
import image from "../../../Image/tr02.png";
import image1 from "../../../Image/tr01.png";
import image2 from "../../../Image/tr03.png";
import quien from "../../../Image/queen.png";
import king from "../../../Image/king.png";
import jack from "../../../Image/jack.png";
import Loader from "../../../Loader";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ShareModal from "./ShareModal";

export default function Home() {
  const { openAuth } = useContext(StakingApp);
  const [openShareModal, setOpenShareModal] = useState(false)


  const toggleShareModal = () => {
    setOpenShareModal(!openShareModal)
  }
  return (
    <>
      <div className="px-4 md:px-32 py-3 ">
        <Slider openAuth={openAuth} />
        <Loader loading={true} />
        <div className="flex flex-wrap gap-5 w-full items-center justify-center my-5">
          <img src={a1} alt="" className="w-1/3 md:w-auto hover:scale-105 cursor-pointer" />
          <img src={a2} alt="" className="w-1/3 md:w-auto hover:scale-105 cursor-pointer" />
          <img src={a3} alt="" className="w-1/3 md:w-auto hover:scale-105 cursor-pointer" />
        </div>
        <div className="flex flex-col items-center h-auto py-5 my-10 bg-gradient-to-r from-[#9e4fff22] via-purple-900 to-[#8b2cff22]">
          <p className="text-2xl md:text-3xl font-bold text-yellow-500 text-center">
            START PLAYING ONLINE POKER WITH EASE
          </p>
          <p className="w-full md:w-[45%] text-center text-white text-sm">

          </p>
          <div className="flex justify-center items-center my-10">
            <Carousel
              autoPlay
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              showIndicators={true}
              className="w-full md:w-[35%]"
            >
              {[slide, slide, slide, slide].map((src, index) => (
                <div className="flex items-center justify-center" key={index}>
                  <img src={src} className="rounded-lg" alt="" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-2xl md:text-3xl font-bold text-yellow-500">
            TOP WINNERS OF THIS WEEK
          </p>
          <p className="w-full md:w-[45%] text-center text-white text-sm">
            It is a long established fact that a reader will be distracted by the
            readable.
          </p>
          <div className="flex flex-wrap justify-center items-center gap-5 pt-10 md:pt-20">
            {[{ img: quien, bg: image, text: "1st Position", name: "Mark", reward: "300 Sol" }, { img: king, bg: image1, text: "2nd Position", name: "John", reward: " 120 Sol" }, { img: jack, bg: image2, text: "3rd Position", name: "Kane", reward: " 75 Sol" }].map(
              (winner, index) => (
                <div
                  key={index}
                  className="relative flex flex-col bg-black h-80 w-full md:h-96 md:w-80 border-2 border-green-500 rounded-3xl items-center"
                  style={{
                    backgroundImage: `url(${winner.bg})`,
                    backgroundSize: "cover",
                  }}
                >
                  <img src={winner.img} alt="" className="h-20 w-20 md:h-32 md:w-32 -top-10 absolute" />
                  <div className="flex flex-col justify-center items-center mt-20 md:mt-32">
                    <p className="flex font-bold text-2xl md:text-3xl text-black">
                      {winner.text}
                    </p>
                    <p className="text-black text-lg md:text-xl my-3 md:my-5">
                      {winner.name}
                    </p>
                    <p className="flex font-bold text-2xl md:text-3xl text-white">
                      {winner.reward}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {false && <div className="flex justify-center items-center my-5">
          <TableContainer className="!bg-[#FFA800]" component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontSize: "15px" }}
                    className="!font-bold !text-[#420202]"
                  >
                    Position
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "15px" }}
                    className="!font-bold !text-[#420202]"
                  >
                    User name
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "15px" }}
                    className="!font-bold !text-[#420202]"
                  >
                    Prize
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5, 6, 7].map((_, i) => (
                  <TableRow key={i}>
                    <TableCell className="!text-green-800 font-bold">
                      row.name
                    </TableCell>
                    <TableCell className="!text-green-800 font-bold">
                      row.name
                    </TableCell>
                    <TableCell className="!text-green-800 font-bold">
                      row.name
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
      </div>
      {openShareModal && <ShareModal isOpen={openShareModal} handleClose={toggleShareModal} />}
    </>

  );
}
