import React, { useContext, useMemo, useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import sol from '../../../Image/sol.png';
import detect from '../../../Image/detection.png';
import QRCode from 'qrcode.react';
import { StakingApp } from '../../Authentication/Context/StakingApp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PaymentIcon from '@mui/icons-material/Payment';
import { DepositDetectionBot } from "../../index";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ToastContent from '../../../CommonComponent/Toast';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Program, Provider, web3, BN } from '@project-serum/anchor';
import { associated } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { associatedAddress } from '@project-serum/anchor/dist/cjs/utils/token';
import * as anchor from '@project-serum/anchor'
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react'
import axiosInstance from '../../../Config/axios';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment'

const { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL, clusterApiUrl } = require('@solana/web3.js');


const idl = require('../../../Config/idl.json')
const NETWORKS = {
  DEVNET: 'devnet',
  TESTNET: 'testnet',
  MAINNET: 'mainnet-beta'
};

export default function DepositPage() {
  const { user } = useContext(StakingApp);
  const [selectedToken, setSelectedToken] = useState('SOL');
  const [isLoading, setIsLoading] = useState(false);
  const [openDetectionBot, setOpenDetectionBot] = useState(false);
  const [depositHistoryList, setDepositHistoryList] = useState([]);

  const anchorWallet = useAnchorWallet()


  const getAddress = () => {
    if (selectedToken === 'SOL') {
      return user.solVirtualAddress;
    }
    return '';
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: Yup.object({
      amount: Yup.number().required('Required').min(0.0005, 'Minimum deposit is 0.0005 SOL')
    }),
    onSubmit: values => {
      handleDepositSubmit(values.amount)
    }
  })


  const connection = new Connection(clusterApiUrl(process.env.REACT_APP_CONTRACT_ENVIRONMENT), 'confirmed');

  const anchorProgram = useMemo(() => {
    if (anchorWallet) {
      const provider = new anchor.AnchorProvider(connection, anchorWallet, anchor?.AnchorProvider?.defaultOptions())
      return new anchor.Program(idl, process.env.REACT_APP_DEPOSIT_CONTRACT_ADDRESS, provider)
    }
  }, [connection, anchorWallet]);


  const handleDepositSubmit = async (amount) => {
    let transactionLastRef = null;
    try {
      setIsLoading(true);
      const PROGRAM_ID = new PublicKey(process.env.REACT_APP_DEPOSIT_CONTRACT_ADDRESS);
      const connection = new Connection(clusterApiUrl(process.env.REACT_APP_CONTRACT_ENVIRONMENT), 'confirmed');

      const provider = window.solana;
      if (!provider) throw new Error('Phantom is not installed');
      if (!provider.isConnected) throw new Error('Phantom is not connected');

      // Initialize the program
      const program = new Program(idl, PROGRAM_ID, provider);


      const [userAccount] = await PublicKey.findProgramAddress(
        [
          Buffer.from(process.env.REACT_APP_CONTRACT_USER_SEED),
          provider.publicKey.toBuffer()
        ],
        PROGRAM_ID
      );

      // Create user account if it doesn't exist
      let accountInfo = await connection.getAccountInfo(userAccount);

      if (!accountInfo) {
        const createAccountTx = new Transaction();

        const createAccountIx = await program.methods
          .createAccount()
          .accounts({
            authority: provider.publicKey,
            userAccount: userAccount,
            systemProgram: SystemProgram.programId,
          })
          .instruction();

        createAccountTx.add(createAccountIx);

        const { blockhash: createBlockhash } = await connection.getLatestBlockhash();
        createAccountTx.recentBlockhash = createBlockhash;
        createAccountTx.feePayer = provider.publicKey;

        const signedCreateTx = await provider.signTransaction(createAccountTx);
        const createSignature = await connection.sendRawTransaction(signedCreateTx.serialize());

        await connection.confirmTransaction(createSignature);
        console.log("User account created successfully");

        // Wait for account creation to be properly confirmed
        await new Promise(resolve => setTimeout(resolve, 2000));
      }

      const userInfo = await anchorProgram['account']['userProfile'].fetch(userAccount)
      // Get PDA for deposit account with correct seeds

      // const [depositAccount] = await PublicKey.findProgramAddress(
      //   [
      //     Buffer.from("DEPOSIT_SEED"),
      //     provider.publicKey.toBuffer(),
      //     new Uint8Array([Number(userInfo['lastRef'])]),
      //   ],
      //   PROGRAM_ID
      // );

      const [vaultAccount] = await PublicKey.findProgramAddress(
        [
          Buffer.from("VAULT_ACCOUNT"),
        ],
        PROGRAM_ID
      );

      transactionLastRef = userInfo['lastRef'];
      const initializeDepositBody = {
        amount: amount,
        lastRef: userInfo['lastRef'],
      }

      const initializeDepositResponse = await axiosInstance.post('transaction/initialize-deposit', initializeDepositBody);

      const { data: initializeDepositData } = initializeDepositResponse;

      if (!initializeDepositData.status) {
        throw new Error(initializeDepositData.message);
      }

      // Proceed with deposit
      const depositTx = new Transaction();

      const lamports = new BN(amount * LAMPORTS_PER_SOL);


      const depositIx = await program.methods
        .deposit(lamports)
        .accounts({
          authority: provider.publicKey,
          userAccount: userAccount,
          vault: vaultAccount,
          systemProgram: SystemProgram.programId,
        })
        .instruction();

      depositTx.add(depositIx);

      const { blockhash: depositBlockhash } = await connection.getLatestBlockhash();
      depositTx.recentBlockhash = depositBlockhash;
      depositTx.feePayer = provider.publicKey;
      
      const signedDepositTx = await provider.signTransaction(depositTx);
      const depositSignature = await connection.sendRawTransaction(signedDepositTx.serialize());
      const depositConfirmation = await connection.confirmTransaction(depositSignature);

      if (depositConfirmation.value.err) {
        throw new Error("Deposit transaction failed");
      }

      const bodyData = {
        transactionHash: depositSignature,
        lastRef: userInfo['lastRef'],
        amount: amount,
      }
      const response = await axiosInstance.post('transaction/deposit-sol', bodyData);
      const responseData = response.data;

      if (!responseData.status) {
        throw new Error(responseData.message);
      }


      formik.resetForm();
      setIsLoading(false);
      fetchDepositHistory();

      toast.success(<ToastContent status="success" message={responseData.message} />, {
        autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, theme: 'dark', position: 'bottom-center'
      });

    } catch (error) {
      
      const bodyData = {
        transactionSignature: null,
        status: 'failed',
        lastRef: transactionLastRef,
        amount: formik.values.amount,
      }
      updateContractTransaction(bodyData);

      setIsLoading(false);
      const message = error.message || error;
      toast.error(<ToastContent status="Error" message={message} />, {
        autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, theme: 'dark', position: 'bottom-center'
      });
    } finally {
      setIsLoading(false);
    }
  }


  const updateContractTransaction = async (bodyData) => {

    try {

      const response = await axiosInstance.post('transaction/update-contract-transaction', bodyData);
      const responseData = response.data;

      if (!responseData.status) {
        throw new Error(responseData.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchDepositHistory = async () => {
    try {
      const response = await axiosInstance.get('transaction/deposit-history');
      const responseData = response.data;

      if (!responseData.status) {
        throw new Error(responseData.message);
      } else {
        setDepositHistoryList(responseData.data);
      }
    } catch (error) {
      console.log(error);
      const message = error.message || error;
      toast.error(<ToastContent status="Error" message={message} />, {
        autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, theme: 'dark', position: 'bottom-center'
      });
    }
  }

  useEffect(() => {
    fetchDepositHistory();
  }, []);

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8 xl:px-10">
      {openDetectionBot && (
        <DepositDetectionBot Open={openDetectionBot} setOpen={setOpenDetectionBot} />
      )}

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {/* Deposit Details */}
          <div>
            <h2 className="text-white text-xl mb-6">Deposit Details for {selectedToken}</h2>

            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 mt-4">
              <div className='input-group flex flex-col'>
                <label className='text-white block'>Enter Amount</label>
                <div class="flex">
                  <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    <PaymentIcon />
                  </span>
                  <input type="number" value={formik.values.amount} onChange={formik.handleChange} name='amount' id="website-admin" class="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="5" />
                </div>
                {(formik.touched.amount && formik.errors.amount) ? <span className='text-xs text-red-600 mt-0.5 font-bold'>{formik.errors.amount}</span> : null}

              </div>
              <div className='action_buttons flex items-end justify-end'>
                <button disabled={isLoading} type="submit" class="text-gray-900  font-extrabold border border-black rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-around bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] mb-2">
                  {isLoading ? <RefreshIcon className='animate-spin' /> : `Deposit ${selectedToken}`}
                </button>
              </div>

            </form>

            <div className="bg-gray-800 p-6 mt-8 rounded-md">
              <b className="text-red-600">Notice</b>
              <br />
              <span className="text-white text-sm">
                {' '}Please don't refresh the page after depositing until the transaction is confirmed on-chain.
                <br />
                <br />
                Do not deposit any currency other than {selectedToken}.
                <br />
                <br />
                After successfully depositing funds, check the deposit in the history section. It should be updated with the status "completed" and the amount deposited. 
              </span>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-purple-800 hover:bg-purple-500 text-white flex py-4 px-8 rounded-lg mt-4 mx-auto block shadow-md"
              onClick={() => setOpenDetectionBot(true)}
            >
              <img
                src={detect}
                alt="detect"
                style={{ height: '40x', width: '30px' }}
                className="mr-2"
              />
              <span className="text-lg font-medium">Detect your deposit</span>
            </motion.button>
          </div>

          {/* Deposit History */}
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
            <h2 className="text-white text-xl mb-4">Deposit History</h2>
            <div className="overflow-auto max-h-64">
              <table className="min-w-full bg-gray-800 text-white rounded-lg overflow-hidden">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">Date</th>
                    <th className="px-4 py-2 text-left">Amount</th>
                    <th className="px-4 py-2 text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Add your dynamic data here */}
                  {depositHistoryList?.map((deposit, index) => (
                    <tr>
                      <td className="border-t border-gray-700 px-4 py-2">{moment(deposit?.createdAt).format("MMM DD, YYYY")}</td>
                      <td className="border-t border-gray-700 px-4 py-2">{deposit?.amount} Sol</td>
                      <td className="border-t border-gray-700 px-4 py-2 capitalize">{deposit?.status}</td>
                    </tr>
                  ))}


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
