import React, { useEffect, useState } from 'react'
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SelectAudioModal from './SelectAudioModal';
const PokerAudio = ({ createdBy, socket, handlePauseForAll }) => {

    const [showSelectAudioModal, setShowSelectAudioModal] = useState(false)

    const handleToggleAudio = () => {
        setShowSelectAudioModal(!showSelectAudioModal)
    }


    return (
        <>
            <div onClick={handleToggleAudio} className='z-20 absolute bottom-5 left-5 p-2 cursor-pointer shadow-sm shadow-black active:scale-105 bg-white rounded-full'>
                <span className='group/item'>{<MusicNoteIcon />}</span>
            </div>
            {<SelectAudioModal socket={socket} handlePauseForAll={handlePauseForAll} createdBy={createdBy} isOpen={showSelectAudioModal} handleClose={() => setShowSelectAudioModal(false)} />}
        </>
    )
}

export default PokerAudio