import React, { useEffect, useState } from 'react'

const AllInWinnerAnimation = () => {


    const [timer, setTimer] = useState(5)

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer === 0) return clearInterval(interval)
            setTimer(timer - 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [timer])

    if (timer === 0) return null;

    return (
        <div className='absolute bottom-10 h-auto flex w-full items-center justify-center text-center text-white font-extrabold text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            All player placed their bet and now the winner will be announced in {timer} seconds
            <br />
            
        </div>
    )
}

export default AllInWinnerAnimation
