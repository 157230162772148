import { cardValues_seven, cardValues_twenty_seven } from './constants';

const formatNumber = (number, padding) => {
    if (number === undefined) {
        return '';
    }
    if (padding === undefined) {
        padding = 2;
    }

    return String(number).padStart(padding, '0');
};


const formatAddress = (address, slice) => {
    if (!address) return ''
    if (typeof address !== 'string') return address;
    if (address.length <= 6) {
        return address;
    }
    const firstThree = address.slice(0, slice || 2);
    const lastThree = address.slice(slice ? -slice : -2);

    return `${firstThree}.....${lastThree}`;
}

const isEmpty = (value) => {
   if (value === null) return true;
    if (value === undefined) return true;
    if (value === 'undefined') return true;
    if (value === 'null') return true;
    if (value === '') return true;
    if (value === 0) return true;
    if (value === false) return true;

    if (Array.isArray(value) && value.length === 0) return true;
    if (typeof value === 'object' && Object.keys(value).length === 0) return true;

    return false;
}

const sevenCardsTotal = (cards) => {

    let total = 0;
    cards.forEach(card => {
        total += cardValues_seven[card['Rank']];
    });

    return total;
}

const twentySevenCardsTotal = (cards) => {
    let total = 0;
    cards.forEach(card => {
        total += cardValues_twenty_seven[card['Rank']];
    });

    return total;
}
const getCardsTotal = (cards) => {
    let total = 0;
    cards.forEach(card => {
        total += cardValues_twenty_seven[card['Rank']];
    });

    return total;
}

export { formatNumber, formatAddress, isEmpty, sevenCardsTotal, twentySevenCardsTotal, getCardsTotal };