// src/gameData.js
export const holdEmPokerData = [
    { position: 1, name: 'Alice Johnson', score: 1500 },
    { position: 2, name: 'John Doe', score: 1200 },
    { position: 3, name: 'Jane Smith', score: 1100 },
    { position: 4, name: 'Bob Brown', score: 1000 },
  ];
  
  export const fiveCardPokerData = [
    { position: 1, name: 'Bob Brown', score: 1400 },
    { position: 2, name: 'Alice Johnson', score: 1300 },
    { position: 3, name: 'John Doe', score: 1100 },
    { position: 4, name: 'Jane Smith', score: 1000 },
  ];
  
  // Define similar data for other games...
  export const sevenStudData = [
    { position: 1, name: 'Jane Smith', score: 1600 },
    { position: 2, name: 'Alice Johnson', score: 1400 },
    { position: 3, name: 'John Doe', score: 1300 },
    { position: 4, name: 'Bob Brown', score: 1200 },
  ];
  
  export const unoData = [
    { position: 1, name: 'John Doe', score: 1500 },
    { position: 2, name: 'Jane Smith', score: 1300 },
    { position: 3, name: 'Bob Brown', score: 1200 },
    { position: 4, name: 'Alice Johnson', score: 1100 },
  ];
  
  export const stonePaperScissorsData = [
    { position: 1, name: 'Alice Johnson', score: 1500 },
    { position: 2, name: 'John Doe', score: 1400 },
    { position: 3, name: 'Bob Brown', score: 1300 },
    { position: 4, name: 'Jane Smith', score: 1200 },
  ];
  
  export const hiLoData = [
    { position: 1, name: 'Bob Brown', score: 1600 },
    { position: 2, name: 'Alice Johnson', score: 1500 },
    { position: 3, name: 'Jane Smith', score: 1400 },
    { position: 4, name: 'John Doe', score: 1300 },
  ];
  
  export const seven27Data = [
    { position: 1, name: 'Jane Smith', score: 1700 },
    { position: 2, name: 'Bob Brown', score: 1600 },
    { position: 3, name: 'Alice Johnson', score: 1500 },
    { position: 4, name: 'John Doe', score: 1400 },
  ];
  