import React, { useContext, useState } from 'react';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import sol from '../../Image/sol.png';
import detect from '../../Image/detection.png';
import QRCode from 'qrcode.react';
import { StakingApp } from '../Authentication/Context/StakingApp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { DepositDetectionBot } from '../index';

export default function Deposit({ openDeposit, setOpenDeposit }) {
  const { user } = useContext(StakingApp);
  const [copied, setCopied] = useState(false);
  const [openDetectionBot, setOpenDetectionBot] = useState(false);

  const getAddress = () => {
    return user.solVirtualAddress;
  };

  return (
    <div>
      {openDetectionBot && (
        <DepositDetectionBot Open={openDetectionBot} setOpen={setOpenDetectionBot} />
      )}

      <Dialog
        open={openDeposit}
        onClose={() => setOpenDeposit(false)}
        PaperProps={{
          className:
            "!min-w-[90%] sm:!min-w-[70%] md:!min-w-[50%] lg:!min-w-[30%] !h-auto sm:!h-[80%] p-5 flex gap-3 flex-col !rounded-2xl !text-shadow-none",
          style: {
            background: 'black',
            boxShadow: '0px 4px 20px rgba(75, 0, 130, 1)',
          },
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="h-[100%] w-[100%] flex flex-col gap-4"
        >
          <div className="flex justify-between items-center">
            <h3 className="text-white font-bold">Deposit Solana</h3>
            <motion.span
              whileHover={{ rotate: 90 }}
              whileTap={{ scale: 0.8 }}
              transition={{ type: 'spring', stiffness: 300 }}
              onClick={() => setOpenDeposit(false)}
              className="text-white cursor-pointer"
            >
              <CloseIcon />
            </motion.span>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="mt-4 flex flex-col sm:flex-row justify-center items-center gap-4"
          >
            <div className="flex flex-col items-center">
              <QRCode
                className="border-[4px] border-gray-700"
                value={getAddress()}
                size={200} // Increased the size of the QR code
              />
              <p className="text-white mt-2 text-sm">
                Minimum Deposit: 0.0005 SOL
              </p>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-5">
              <div className="h-14 bg-gray-800 w-64 px-3 rounded-md flex items-center">
                <span className="text-white break-words text-sm text-center overflow-auto">
                  {getAddress()}
                </span>
                <CopyToClipboard
                  text={getAddress()}
                  onCopy={() => setCopied(true)}
                >
                  {copied ? (
                    <DoneIcon className="text-green-600 cursor-pointer" />
                  ) : (
                    <ContentCopyIcon className="text-white cursor-pointer" />
                  )}
                </CopyToClipboard>
              </div>
            </div>
          </motion.div>

          <div className="bg-gray-900 items-center flex justify-center mt-8 p-3 rounded-md">
            <b className="text-red-600">Notice: </b>{' '}
            <span className="text-white text-sm text-center">
              Do not deposit any currency other than SOL. After successfully
              depositing funds, ensure you activate the deposit detection bot
              to verify the transaction on-chain.
            </span>
          </div>

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="flex p-9 justify-center !border-none outline-none"
            onClick={() => setOpenDetectionBot(true)}
          >
            <div className="bg-purple-800 shadow-sm shadow-black w-full h-9 p-1 text-white rounded-lg font-bold flex items-center justify-center text-center">
              Detect your deposit
              <img
                src={detect}
                alt="detect"
                style={{ height: '24px', width: '24px', marginLeft: '8px' }}
              />
            </div>
          </motion.button>
        </motion.div>
      </Dialog>
    </div>
  );
}
