import React, { useEffect } from 'react'
import MenuActions from '../PlayGame/MenuActions'
import { useSelector } from 'react-redux'
import Player from './Player'
import { formatAddress, isEmpty } from '../../../../../Config/function'
import axiosInstance from '../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ToastContent from '../../../../../CommonComponent/Toast'
import { useNavigate } from 'react-router-dom'
import hotToast from 'react-hot-toast'
import PotMoney from '../PlayGame/PotMoney'
import Card from '../PlayGame/Cards/Common/Card'
import coins from '../../../../../assets/img/games/coins_yellow_transparent.png';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import Banner from "../../../../../assets/img/games/hilo_background_image.png";
import TableLeft from "../../../../../assets/img/games/hilo/table_left.png";
import TableRight from "../../../../../assets/img/games/hilo/table_right.png";

const PlayAgain = ({ gameInfo, fetchGameInfo }) => {
    gsap.registerPlugin(Flip);

    const userData = useSelector(state => state.auth.userData)
    const gameAddress = gameInfo?.gameAddress
    const navigate = useNavigate()


    let players = gameInfo?.result?.players
    const myProfile = players?.find(player => player.address === userData?.address)
    const otherPlayers = players.filter(player => player.address !== userData?.address)


    if (myProfile) {
        players = [myProfile, ...otherPlayers]
    } else {
        players = otherPlayers
    }




    const WaitingPlayer = () => {
        return null
    }

    const isInGame = !isEmpty(myProfile)


    const handleJoinGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/join', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                const data = { slug: 'highlow', gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
            }


        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const getClassName = (index) => {
        if (index == 0) {
            return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
        }
        if (index == 1) {
            return `absolute bottom-10 left-1/4  -translate-x-1/2`;
        }
        if (index == 2) {
            return `absolute left-5 bottom-1/4 translate-x-1/4`;
        }
        if (index == 3) {
            return `absolute left-0 top-1/4 translate-x-1/4`;
        }
        if (index == 4) {
            return `absolute top-0 left-1/4 -translate-x-1/4 `;
        }
        if (index == 5) {
            return `absolute top-0 right-1/4 translate-x-1/4 `;
        }
        if (index == 6) {
            return `absolute right-0 top-1/4 -translate-x-1/4`;
        }
        if (index == 7) {
            return `absolute right-0 bottom-1/4 -translate-x-1/4`;
        }
        if (index == 8) {
            return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
        }
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }


    const gameResult = gameInfo?.result


    const GameResultComponent = () => {
        if (!gameResult) return null

        const { winner, pot, card, nextCard } = gameResult
        if (!winner) return null

        return (
            <div className='flex flex-col items-center justify-center'>
                {!isEmpty(pot) && <div className='flex items-center justify-center'>
                    <PotMoney pot={pot} />
                </div>}
                <span className='text-white font-bold text-2xl mt-0 mb-2'>Winner</span>
                <div className='hi-lo-player flex items-center justify-start flex-col	'>
                    {<img src={winner.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 hi_lo_player__profile_active`} />}
                    <span className='hi-lo-player__balance text-white font-bold mt-2 text-xs'>{winner.userName ? winner.userName : formatAddress(winner.address, 6)}</span>
                    <div className='winner_cards mt-2 flex flex-row justify-center items-center gap-2'>
                        {card && <Card className='h-14 w-9 rounded-sm' card={card} />}
                        {nextCard && <Card className='h-14 w-9 rounded-sm' card={nextCard} />}
                    </div>

                </div>
            </div>
        )
    }


    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);
        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null

        if (isCard) {
            card = document.createElement('span');
            card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.getElementById('hilo_screen').appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 1.3,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const showCoinAnimation = () => {
        try {
            if (!gameResult) return
            const { players } = gameResult
            if (!players) return

            const winnerPlayer = gameResult?.winner ? [gameResult.winner] : []
            const otherPlayers = players.filter(player => player.userId !== winnerPlayer[0]?.userId)
            for (let i = 0; i < otherPlayers.length; i++) {
                let player = otherPlayers[i]
                for (let j = 0; j < winnerPlayer.length; j++) {
                    let winner = winnerPlayer[j]
                    animateFlowToPot(`high_low_result_player_${player.userId}`, `high_low_result_player_${winner.userId}`, false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        showCoinAnimation()
    }, [gameInfo])

    const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


        if (totalPlayers == 9) {
            if (index == 0) {
                return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
            }
            if (index == 1) {
                return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
            }
            if (index == 2) {
                return `absolute left-0 sm:left-5 bottom-1/4 `;
            }
            if (index == 3) {
                return `absolute left-0 sm:left-5 top-1/4 `;
            }
            if (index == 4) {
                return `absolute top-0 left-1/4 -translate-x-1/4 `;
            }
            if (index == 5) {
                return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
            }
            if (index == 6) {
                return `absolute right-6 sm:right-16 top-1/4 `;
            }
            if (index == 7) {
                return `absolute right-6 sm:right-16 bottom-1/4`;
            }
            if (index == 8) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }
            if (index == 9) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }

        }

    };

    let samplePlayers = players
    if (samplePlayers.length == 2) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 3) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 4) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 5) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 6) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
    } else if (samplePlayers.length == 7) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
    } else if (samplePlayers.length == 8) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        const eighthPlayer = samplePlayers[7];
        samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
    } else {
        samplePlayers = samplePlayers;
    }


    return (
        <>
            {false && <div id='hilo_screen' className='game flex flex-col items-center h-full'>
                <MenuActions gameStatus={gameInfo?.status} isInGame={isInGame} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} />
                <div className='relative w-full h-[60%] p-3 bg-gray-900 border-4  mt-10 container rounded-2xl '>
                    <div className=" rounded-lg w-full h-full  bg-green-900 border-4">
                        <div id='hilo_pot_money_box' className=' absolute top-1/4 left-1/2 transform -translate-x-1/2'>
                            {<GameResultComponent />}
                        </div>
                        {players.map((player, index) => {
                            return (
                                <div key={index} className={getClassName(index)}>
                                    {player.joined == false ? <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                        <WaitingPlayer />
                                    </div> : <Player isWaiting={true} player={player} />}
                                </div>
                            );

                        })}
                    </div>


                </div>

                <div className='w-full h-auto mt-5 container rounded-lg'>
                    <div className='flex items-center justify-center h-full'>
                        <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'>
                            <span className='text-black '>Press here to play/continue...</span>
                        </button>
                    </div>
                </div>

            </div>}

            <div id='hilo_screen' className="bg-gradient-to-br from-[#7023b8] via-[#240341] to-[#5d03ac] h-screen w-full mx-auto p-2 pt-5 ">

                <div className='mx-auto mt-3 px-3 container absolute top-0 left-0 bg-transparent font-extrabold ' >
                    <MenuActions isInGame={isInGame} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} />
                </div>
                <div className="poker_table_golden relative w-full h-[75vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 mt-5"
                // style={{
                //     backgroundImage: `url('https://i.pinimg.com/736x/f6/e6/91/f6e691bc7fed916c02d373464d8c3257.jpg')`,
                //     backgroundSize: 'cover',
                //     backgroundRepeat: 'no-repeat',
                //     backgroundPosition: 'center',
                //     boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                // }}
                >


                    {/* <div className="absolute hidden md:block top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-70 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div> */}
                    <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-50 h-36">
                        <img src={TableLeft} className='h-52 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-50 h-36">
                        <img src={TableRight} className='h-52 w-30' />
                    </div>
                    {/* <div className="absolute hidden md:block top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-70 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div> */}



                    <div className="elative h-full bg-[#250a3d] border-1 rounded-full shadow-xl border-gray-400" style={{
                        boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`,
                        background: 'linear-gradient(90deg, rgba(62,50,0,1) 0%, rgba(108,91,0,1) 18%, rgba(133,113,1,1) 41%, rgba(133,113,1,1) 64%, rgba(108,91,0,1) 84%, rgba(62,50,0,1) 99%)'

                    }}>

                        <div id='hilo_pot_money_box' className=' absolute top-1/4 left-1/2 transform -translate-x-1/2'>
                            {<GameResultComponent />}
                        </div>

                        <div className='flex md:hidden w-full text-center flex-col absolute top-1/4 left-1/2 transform -translate-x-1/2 opacity-30'>
                            <h2 className='text-xl sm:text-2xl md:text-3xl text-yellow-400 w-full font-extrabold mt-2 font-banger'>PVP Solana Casino</h2>
                        </div>
                        <div className='flex w-full text-center flex-col absolute bottom-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
                            <h2 className='text-xl sm:text-2xl md:text-4xl text-white w-full font-extrabold mt-2 font-banger'>Hi-Lo Card</h2>
                        </div>
                        {samplePlayers.map((player, index) => {
                            return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)}`} >
                                <Player isWaiting={true} player={player} />
                            </div>
                        })}


                    </div>

                </div>


                <div className='w-full h-auto mt-5 container rounded-lg'>
                    <div className='flex items-center justify-center h-full'>
                        <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'>
                            <span className='text-black '>Press here to play/continue...</span>
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PlayAgain
