import React, { useEffect, useState } from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import AddIcon from '@mui/icons-material/Add';

const Player = ({ player, position }) => {




  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600 text-white`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }

  return (
    <>
    {false && <div className='hi-lo-player flex items-center flex-col	'>

      {/* {!player.isFolded && player.isLeft && <img src={LeftImage} alt='profile' className={`hi-lo- h-20 w-40 p-1/2 `} />}
      {player.isFolded && !player.isLeft && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-40 p-1/2 `} />}
      {!player.isFolded && !player.isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 borde`} />} */}



      <div className={`bg-black text-white`}>
        <div  className={`flex flex-col items-center justify-center  font-extrabold px-5 text-center border-2 rounded-md `}>
          <div id={`high_low_result_player_${player.userId}`} className='name mb-1 text-sm'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>

          <div className='relative flex flex-row items-center justify-center'>
            {/* {player.userId === userData?.id ? <span className='text-xs'>{(player.amount).toFixed(2)} Sol</span> : <span className='text-xs'>***** Sol</span>} */}
            {/* {player.userId === userData?.id ? (<span className='text-xs ml-1'>
              (<SolToUSD amount={player.amount} symbol='USD' />)
            </span>) : <span className='text-xs ml-1'>
              (***** USD)
            </span>} */}
          </div>

        </div>
      </div>

    </div>}

    <div className='hi-lo-player  rounded-full'>
        <div className='user_info flex items-center justify-center flex-col'>
          {player.isFolded && !player.isLeft && <img src={FoldImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {player.isLeft && !player.isFolded && <img src={LeftImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {player.isLeft && player.isFolded && <img src={FoldImage} alt='profile' className='rounded-full h-10 w-auto border-white border-2' />}
          {!player.isLeft && !player.isFolded && <img src={player.profileUrl} alt='profile' className='rounded-full h-10 w-10 border-white border-2' />}
          {<span id={`hilo_player_${player.userId}`} className='text-xs bg-red-600 text-black px-2 py-1 font-extrabold w-auto'>
            {<span className='text-xs w-full text-white'>{
              player.userName ? player.userName : formatAddress(player.address, 3)
            }</span>}
          </span>}
          {/* {player.userId === userData?.id && <span id={`hilo_player_${player.userId}`} className='text-xs bg-[#fff] px-2 py-1 font-extrabold w-max flex flex-col items-center justify-between rounded-md'>
            {<span className='text-xs'>{isWaiting ? Number(player.amount).toFixed(2) : Number(userBalance?.amount).toFixed(2)} Sol</span>}
            {(<span className='text-xs ml-1'>(<SolToUSD amount={isWaiting ? (player.amount) : userBalance?.amount} symbol='USD' />)
            </span>)}
          </span>} */}
        </div>
      </div>

  </>  
)
}

export default Player
