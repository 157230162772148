import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatAddress, formatNumber } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import Dealer from "../../../../../assets/img/dealer.png"
import AddIcon from '@mui/icons-material/Add';

import Card from "../Common/Cards/index"
import SolToUSD from '../../../../../CommonComponent/SolToUSD'
const Player = ({ showDealer, selectedCards, handleToggleCard, player, index, totalPlayers, gameStatus, foldedPlayers, leftPlayers, userBalances, playerCards, currentPlayer, timer, position }) => {

  const userData = useSelector(state => state.auth.userData)

  const currentPlayerCards = playerCards?.find(playerCard => playerCard.userId === player.userId)?.cards || []

  const playerBalance = userBalances?.find(userBalance => userBalance?.userId === player.userId) || {}


  const isFolded = foldedPlayers.includes(player.userId)
  const isLeft = leftPlayers.includes(player.userId)
  const isPlayerMove = ((player?.userId == currentPlayer) && gameStatus !== 'starting')

  const RenderDealer = () => {
    return <div>
      <img className='absolute -right-2 -bottom-5 h-8 w-8 shadow-2xl rounded-circle z-10 ' src={Dealer} alt='dealer' />
    </div>
  }

  const RenderBlind = () => {
    return <div className=' text-xs flex justify-center items-center text-white font-bold ml-2 bg-red-500  px-0.5 shadow-2xl rounded-sm z-20 '>
      (Small Blind)
    </div>
  }
  const RenderBigBlind = () => {
    return <div className=' text-xs flex justify-center items-center text-white font-bold ml-2 bg-red-500 w-full px-0.5 shadow-2xl rounded-sm z-20'>
      (Big Blind)
    </div>
  }



  const isTimer = timer > 0

  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)

    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])

    if (gameStatus === 'starting') return null;
    if (timeLeft <= 0) return null;

    // return <span className='hi-lo-player__profile absolute rounded-full h-8 w-8 -bottom-4 border-red-800 font-extrabold flex items-center justify-center bg-red-800 text-white shadow-2xl'>
    //   <span className='text-xs'>{timeLeft}</span>
    // </span>

    const calculateDashOffset = () => {
      const circumference = 2 * Math.PI * 45
      const dashOffset = circumference * (1 - timeLeft / 30)
      return dashOffset
    }

    let color = '#55eb34'
    if (timeLeft <= 10) {
      color = 'red'
    } else if (timeLeft <= 20) {
      color = 'yellow'
    }

    return <svg className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="green"
        strokeWidth="6"
        className='z-10 '
      />
      {/* Progress arc */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={calculateDashOffset()}
        transform="rotate(-90 50 50)"
      />
    </svg>
  }


  const timerMemo = React.useMemo(() => <RenderTimer />, [timer])


  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600 text-white`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }


  return (
    <>
      {false && <div className='relative five_card_player flex items-center flex-col'>
        {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
        {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-24 w-36  ${player.active && "active"}`} />}
        {!isFolded && !isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${player.active && "active"}`} />}


        <div className={`${isPlayerMove && " border-2 border-white py-2 px-3 bg-green-400 rounded-full relative"}`}>

          <div className='relative flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>

            {showDealer && <RenderDealer />}

            {isTimer && (currentPlayer === player.userId) && timerMemo}
            <div className='name text-white mb-1 text-xs flex flex-row'>{
              player.userName ? player.userName :
                formatAddress(player.address, 3)

            }
              <span>
                {player.userRole === 'blind' && <RenderBlind />}
                {player.userRole === 'big_blind' && <RenderBigBlind />}
              </span>
            </div>



            {player?.userId === userData?.id ? <div className='balance text-white text-xs'>({Number(playerBalance.amount).toFixed(2)}) Sol</div> : null}
          </div>
        </div>




        {!isFolded && !isLeft && (player.userId === userData?.id) && <div className={`flex items-center justify-center absolute -top-20 gap-0.5 `}>
          {gameStatus !== 'starting' && currentPlayerCards.map((card, index) => (
            <span id={`player_card_${player.userId}_${index}`} role='button' onClick={() => handleToggleCard(index)} className={`fade-in-up ${selectedCards.includes(index) && 'scale-125'}`} >
              <Card textSize={'text-xs'} fontSize={12} card={card} className={"h-16 w-10 rounded-sm border-2"} />
            </span>
          ))}
        </div>}


      </div>}

      <div className={`flex flex-col items-center `}>

        {/* {((gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing')) && <RenderTimer />} */}
        <div className="relative z-20">
          {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
          {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-24 w-36  ${player.active && "active"}`} />}
          {!isFolded && !isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${player.active && "active"}`} />}

          {isTimer && (currentPlayer === player.userId) && <RenderTimer />}

          {showDealer && (
            <div className="absolute -top-1 -right-1 w-4 h-4 bg-yellow-400 text-black font-extrabold rounded-full  text-xs flex items-center justify-center">
              D
            </div>
          )}
          {player.userRole === 'blind' && (
            <div className="absolute -top-5 w-20 text-center rounded-lg left-1/2 transform -translate-x-1/2 bg-yellow-600 text-black font-extrabold text-xs">
              Small Blind
            </div>
          )}
          {player.userRole === 'big_blind' && (
            <div className="absolute -top-5 w-20 text-center rounded-lg left-1/2 transform -translate-x-1/2 bg-yellow-600 text-black font-extrabold text-xs">
              Big Blind
            </div>
          )}
        </div>
        <div className="mt-1 text-xs font-medium px-1 py-1 bg-white text-black rounded-md">
          {player?.userId === userData?.id ? <div className='balance text-black text-xs flex flex-col items-center justify-center'>{Number(playerBalance.amount).toFixed(2)} Sol
            <span>(<SolToUSD amount={Number(playerBalance.amount)} symbol={"USD"} />)</span>
          </div> : <div className='balance text-black text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>}
        </div>

      </div>


    </>
  )
}

export default Player
