import React from 'react'
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import LoopIcon from '@mui/icons-material/Loop';

const Cards = ({ card, className }) => {


    const TwoDrawCardIcon = ({ color }) => {
        return (
            <div className='relative w-auto h-auto bg-gray-800'>
                <span className={`absolute card_one h-4 w-3 rounded-sm -top-1 -left-1 transform -translate-x-1/2 -translate-y-1/2 ${color} border-white `}></span>
                <span className={`absolute card_one h-4 w-3 rounded-sm  border-black ${color} top-0 transform -translate-x-1/2 -translate-y-1/2 left-1`}></span>
            </div>
        )
    }

    const WildCardIcon = ({ height, width }) => {
        return (
            <div className={`relative h-6 w-6 rounded-lg rotate-45`}>
                <span className={`absolute card_one h-3 w-3 top-0 rounded-tl-md left-0 bg-green-500 `}></span>
                <span className={`absolute card_one h-3 w-3 top-0 rounded-tr-md left-3 bg-red-500  ${color}`}></span>
                <span className={`absolute card_one h-3 w-3 bottom-0 left-3 rounded-br-md bg-blue-500  ${color}`}></span>
                <span className={`absolute card_one h-3 w-3 bottom-0 left-0 rounded-bl-md bg-yellow-500  ${color}`}></span>
            </div>
        )
    }


    const { color, value } = card

    if (!color || !value) return null


    let bgColor = 'bg-white'
    if (value === 'wildDrawFour') {
        bgColor = card.color ? `bg-${card.color}-600` : 'bg-black'
    } else if (value === 'wild') {
        bgColor = 'bg-black'
    } else if (color === 'red') {
        bgColor = 'bg-red-600'
    } else if (color === 'yellow') {
        bgColor = 'bg-yellow-600'
    } else if (color === 'green') {
        bgColor = 'bg-green-600'
    } else if (color === 'blue') {
        bgColor = 'bg-blue-600'
    } else if (value === 'wildDrawFour') {
        bgColor = 'bg-black'
    } else if (value === 'wild') {
        bgColor = 'bg-black'
    }


    let cardValue = value

    if (value === 'drawTwo') {
        cardValue = "+2"
    } else if (value === 'reverse') {
        cardValue = <LoopIcon className='-rotate-12' fontSize='18' />
    } else if (value === 'skip') {
        cardValue = <DoDisturbIcon fontSize='12' />
    } else if (value === 'wild') {
        cardValue = 'w'
    } else if (value === 'wildDrawFour') {
        cardValue = '+4'
    } else if (value === '0') {
        cardValue = '0'
    }


    let cardIcon = value
    if (value === 'drawTwo') {
        cardIcon = <TwoDrawCardIcon color={bgColor} />
    } else if (value === 'reverse') {
        cardIcon = <LoopIcon className='font-extrabold' />
    } else if (value === 'skip') {
        cardIcon = <DoDisturbIcon className='font-extrabold' />
    } else if (value === 'wild') {
        cardIcon = <WildCardIcon height={6} width={6} />
    } else if (value === 'wildDrawFour') {
        cardIcon = '+4'
    } else if (value === '0') {
        cardIcon = '0'
    }

    return (
        <div className={`relative uno_card rounded-md border-2 border-white ${bgColor} ${className ? className : 'w-14 h-20'}`}>
            <span className='absolute top-1 left-1 text-xs text-white font-bold'>{cardValue}</span>
            <span className='absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-full h-8 rounded-3xl -rotate-45'></span>
            <span className='absolute text-black top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-extrabold'>{
                cardIcon}</span>
            <span className='absolute bottom-1 rotate-180 right-1 text-xs text-white font-bold'>{cardValue}</span>
        </div>
    )
}

export default Cards
