import React, { useEffect, useState } from 'react'
import PotMoney from './PotMoney';
import Player from './Player';
import MenuActions from './MenuActions';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../../../../Config/function';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

import coins from '../../../../../assets/img/games/coins_transparent.png';
import blankCard from '../../../../../assets/img/games/blankcard.png';
import HighLowActionbuttons from './HighLowActionbuttons';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../../CommonComponent/Toast';
import axiosInstance from '../../../../../Config/axios';
import HiddenCard from './HiddenCard';
import hotToast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import BettingCard from "./Cards/Common/Card"
import { useSpeechSynthesis } from 'react-speech-kit';
import Banner from "../../../../../assets/img/games/hilo_background_image.png";
import TableLeft from "../../../../../assets/img/games/hilo/table_left.png";
import TableRight from "../../../../../assets/img/games/hilo/table_right.png";
const PlayGame = ({ gameInfo, socket }) => {
    const { speak } = useSpeechSynthesis();
    gsap.registerPlugin(Flip);
    const userData = useSelector(state => state.auth.userData)
    const players = gameInfo?.players || []
    const myProfile = gameInfo?.players.find(player => player.userId === userData?.id)
    const isInGame = !isEmpty(myProfile)
    const navigate = useNavigate()
    const { gameAddress } = useParams()


    const [gameBetStatus, setGameBetStatus] = useState({
        buttons: [],
        currentPlayer: 0,
        betAmount: 0,
        isStarted: false,
        pot: 0,
        userBalances: [],
        foldedPlayers: [],
        timer: 0,
    })
    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting') // 'starting', 'betting', 'playing
    const [showResultCard, setShowResultCard] = useState(null)
    const [currentMoveInfo, setCurrentMoveInfo] = useState({
        card: {},
        currentPlayer: 0,
        pot: 0,
        userBalances: [],
        maxBet: 0,
        foldedPlayers: [],
        leftPlayers: [],
        timer: 0,
    })

    const fetchPlayerBetAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/bet-status', { gameAddress: gameInfo.gameAddress })

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                if (responseData.data?.isStarted) {
                    // setGameStatus('playing')
                }
                setGameBetStatus(responseData.data)
            }

        } catch (error) {
            toast.error(<ToastContent title="Error" message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
            })
        }
    }

    const fetchPlayetMoveAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/move-status', { gameAddress: gameInfo.gameAddress })

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                setCurrentMoveInfo(responseData.data)
            }
        } catch (error) {
            toast.error(<ToastContent title="Error" message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
            })
        }
    }

    const animateFlowToPot = (playerId, targetId, isCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null
        if (isCard) {
            card = document.createElement('img');
            card.className = 'card h-24 w-16 bg-transparent absolute rounded-full rounded-md';
            card.src = blankCard;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = ' h-12 w-12 bg-transparent absolute ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);

        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const flipElement = (id) => {
        const element = document.getElementById(id);
        const flipState = Flip.getState(element);

        // Toggle the flipped class
        if (!element) return;
        element.classList.toggle('flipped');

        Flip.from(flipState, {
            duration: 1,
            ease: 'power1.inOut',
            onEnter: (elements) => gsap.fromTo(elements, { rotationY: -180 }, { rotationY: 0, duration: 1 }),
            onLeave: (elements) => gsap.fromTo(elements, { rotationY: 0 }, { rotationY: 180, duration: 1 }),
        });

        setTimeout(() => {
            if (!element) return;
            element.classList.toggle('flipped');
        }, 1000)
    };

    useEffect(() => {
        if (!socket) return;
        socket.on('betPlayed', (data) => {
            animateFlowToPot(`hilo_player_${data.userId}`, 'pot_money_box');
            if (data?.betFinished) {
                hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                setGameStatus('playing');
                fetchPlayetMoveAction();
            } else if (data.gameStatus == 'betting') {

                let speakmessage = ''
                if (data?.amount && data.action) {
                    if (data.action == 'Check') {
                        speakmessage = `Check`
                    } else {
                        speakmessage = `${data.action} with ${data.amount} Solana`
                    }
                }

                if (speakmessage) speak({ text: speakmessage })

                if (data.isStarted) {
                    speak({ text: 'All players placed their bets' })
                    hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                } else {

                    setGameStatus(data.gameStatus);
                    fetchPlayerBetAction();
                }
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })

        socket.on('movePlayed', (data) => {

            const { userId, action, amount, isWin, isDraw, isFinished, nextCard } = data;
            if (action === "Timeout") {
                hotToast.error('Timeout', { duration: 2000, position: 'bottom-center' })
                if (isFinished) {
                    hotToast.success('Round Over !! Now play your bet', { duration: 5000, position: 'bottom-center' })
                    setGameStatus('betting')
                    fetchPlayerBetAction();
                } else {
                    fetchPlayetMoveAction();
                }
                return;
            }
            let speakmessage = ''
            if (action == 'high') {
                speakmessage = `Higher with ${amount} Solana`
            } else if (action == 'low') {
                speakmessage = `Lower with ${amount} Solana`
            }

            if (speakmessage) speak({ text: speakmessage })

            let targetId = ``;
            let sourceId = ``;
            if (isWin) {
                targetId = `hilo_player_${userId}`;
                sourceId = `pot_money_box`;
            } else if (!isDraw) {
                targetId = `pot_money_box`;
                sourceId = `hilo_player_${userId}`;
            } else {
                hotToast.success('Draw', { duration: 1000, position: 'bottom-center' })
            }
            animateFlowToPot(`high_low_hidden_card`, `high_low_betting_card`, true);
            setTimeout(() => {
                setTimeout(() => {
                    if (isWin) {
                        if (userId === userData?.id) {
                            speak({ text: 'You won' })
                            hotToast.success('You won', { duration: 3000, position: 'bottom-center' })
                        } else {
                            speak({ text: 'Opponent won' })
                            hotToast.success('Opponent won', { duration: 3000, position: 'bottom-center' })
                        }
                    } else if (!isDraw) {
                        if (userId === userData?.id) {
                            speak({ text: 'You Lost' })
                            hotToast.success('You Lost', { duration: 1000, position: 'bottom-center' })
                        } else {
                            speak({ text: 'Opponent Lost' })
                            hotToast.success('Opponent Lost', { duration: 1000, position: 'bottom-center' })
                        }
                    } else {
                        speak({ text: 'Draw' })
                        hotToast.success('Draw', { duration: 1000, position: 'bottom-center' })
                    }
                    if (isFinished) {
                        setShowResultCard(nextCard);

                        setTimeout(() => {
                            animateFlowToPot(sourceId, targetId, false);
                            setShowResultCard(null);
                            flipElement('high_low_hidden_card');
                            setTimeout(() => {
                                speak({ text: 'Round Over, Now play your bet' })
                                hotToast.success('Round Over !! Now play your bet', { duration: 2000, position: 'bottom-center' })
                                setGameStatus('betting')
                            }, 3000);
                        }, 3000);


                    } else {
                        setShowResultCard(nextCard);
                        setTimeout(() => {
                            animateFlowToPot(sourceId, targetId, false);
                            setShowResultCard(null);
                            flipElement('high_low_hidden_card');
                            fetchPlayetMoveAction();
                        }, 3000);

                    }
                }, 1000)
            }, 1000)
        })


        socket.on('opponentLeft', (data) => {
            if (gameStatus == 'betting') {
                fetchPlayerBetAction();
            } else if (gameStatus == 'playing') {
                fetchPlayetMoveAction();
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        })


        return () => {
            socket.off('betPlayed');
            socket.off('movePlayed');
        }
    }, [gameInfo])


    useEffect(() => {
        if (gameStatus === 'starting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'betting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'playing') {
            fetchPlayetMoveAction();
        }
    }, [gameStatus])

    let allPlayers = [...players];
    const restOtherPlayers = players.filter(player => player.userId !== userData?.id)
    if (myProfile) {
        allPlayers = [myProfile, ...restOtherPlayers]
    }

    const getClassName = (index) => {
        if (index == 0) {
            return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
        }
        if (index == 1) {
            return `absolute -bottom-0 left-1/4  -translate-x-1/2`;
        }
        if (index == 2) {
            return `absolute left-0 bottom-1/4 translate-x-1/4`;
        }
        if (index == 3) {
            return `absolute left-0 top-1/4 translate-x-1/4`;
        }
        if (index == 4) {
            return `absolute top-0 left-1/4 -translate-x-1/4 `;
        }
        if (index == 5) {
            return `absolute top-0 right-1/4 translate-x-1/4 `;
        }
        if (index == 6) {
            return `absolute right-0 top-1/4 -translate-x-1/4`;
        }
        if (index == 7) {
            return `absolute right-0 bottom-1/4 -translate-x-1/4`;
        }
        if (index == 8) {
            return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
        }
    }

    const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


        if (totalPlayers == 9) {
            if (index == 0) {
                return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
            }
            if (index == 1) {
                return `absolute bottom-5 sm:bottom-0 left-1/4  -translate-x-1/2`;
            }
            if (index == 2) {
                return `absolute -left-0 sm:left-5 bottom-1/4 `;
            }
            if (index == 3) {
                return `absolute left-0 sm:left-5 top-1/4 `;
            }
            if (index == 4) {
                return `absolute top-0 left-1/4 -translate-x-1/4 `;
            }
            if (index == 5) {
                return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
            }
            if (index == 6) {
                return `absolute right-0 sm:right-5 top-1/4 `;
            }
            if (index == 7) {
                return `absolute right-0 sm:right-5 bottom-1/4`;
            }
            if (index == 8) {
                return `absolute right-1/4 bottom-5 sm:bottom-0 translate-x-1/2 `;
            }

        }

    };

    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let timer = gameBetStatus?.timer || 0
    if (gameStatus === 'playing') {
        maxBet = currentMoveInfo?.maxBet || 0
        pot = currentMoveInfo?.pot || 0
        userBalances = currentMoveInfo?.userBalances || []
        foldedPlayers = currentMoveInfo?.foldedPlayers || []
        leftPlayers = currentMoveInfo?.leftPlayers || []
        currentPlayer = currentMoveInfo?.currentPlayer || 0
        timer = currentMoveInfo?.timer || 0
    }



    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    let samplePlayers = allPlayers

    if (samplePlayers.length == 2) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 3) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 4) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 5) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 6) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
    } else if (samplePlayers.length == 7) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
    } else if (samplePlayers.length == 8) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        const eighthPlayer = samplePlayers[7];
        samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
    } else {
        samplePlayers = samplePlayers;
    }



    return (
        <>
            {false && <div id='hilo_screen' className='game flex flex-col items-center h-full'>
                <MenuActions gameStatus={gameInfo?.status} enableFullScreen={enableFullScreen} isInGame={isInGame} handleLeaveGame={handleLeaveGame} />
                <div className='relative w-full h-[55%] p-3 bg-gray-900 border-2  mt-10 container rounded-2xl '>
                    <div className=" rounded-lg w-full h-full  bg-green-900 border-2 container">
                        {allPlayers.map((player, index) => {
                            return (
                                <div key={index} className={getClassName(index)}>
                                    <Player showResultCard={showResultCard} gameStatus={gameStatus} socket={socket} currentPlayer={currentPlayer} leftPlayers={leftPlayers} foldedPlayers={foldedPlayers} userBalances={userBalances} player={player} timer={timer} />
                                </div>
                            );
                        })}
                        <div className="absolute left-1/2 w-100  right-1/2 top-1/2  transform -translate-x-1/2 -translate-y-1/2 ">
                            <div className='relative w-50 mx-auto flex items-center justify-center '>
                                {gameStatus == 'playing' && <div id='high_low_betting_card' className='betting_card absolute left-0 top-1/2 transform -translate-y-1/2'>
                                    <BettingCard card={currentMoveInfo?.card} />
                                </div>}
                                <div id='pot_money_box' className=' mx-4 text-sm absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2'>
                                    <PotMoney pot={pot} />
                                </div>

                                {(gameStatus == 'playing') && <div className={`hidden_card absolute right-0 top-1/2 transform z-10 -translate-y-1/2`}>
                                    <HiddenCard />
                                </div>}
                                {showResultCard && (gameStatus == 'playing') && <div className='fade-in-up z-20 absolute right-0 top-1/2 transform -translate-y-1/2'>
                                    <BettingCard card={showResultCard} />
                                </div>}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='w-full h-auto p-3  mt-5 container '>
                    <HighLowActionbuttons maxBet={maxBet} pot={pot} gameStatus={gameStatus} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} />
                </div>
            </div>}


            {true && <div id='hilo_screen' className="bg-gradient-to-br from-[#7023b8] via-[#240341] to-[#5d03ac] h-screen w-full mx-auto p-2 pt-5 flex justify-center items-start">
                <div className='p-0 mx-auto mt-3 px-0 absolute top-0 left-0 bg-transparent font-extrabold flex w-full items-center justify-between'>
                    <MenuActions gameStatus={gameInfo?.status} enableFullScreen={enableFullScreen} isInGame={isInGame} handleLeaveGame={handleLeaveGame} />
                    {/* <CloseIcon />
                    <FullscreenIcon className='bg-slate-700 border-2 border-black font-extrabold shadow-white cursor-pointer hover:scale-105 active:scale-110 shadow-sm text-yellow-500 rounded-md' /> */}
                </div>
                <div className="poker_table_golden relative w-full h-[80vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 "
                    // style={{
                    //     backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
                    //     backgroundSize: 'cover',
                    //     backgroundRepeat: 'no-repeat',
                    //     backgroundPosition: 'center',
                    //     boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                    // }}
                >

                    <div className="absolute    md:block top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2  text-2xl font-bold opacity-80 h-36">
                        <img src={Banner} className='h-32 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-50 h-36">
                        <img src={TableLeft} className='h-52 w-30' />
                    </div>
                    <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-50 h-36">
                        <img src={TableRight} className='h-52 w-30' />
                    </div>



                    <div className="elative h-full bg-[#250a3d] border-1 rounded-full shadow-xl border-gray-400" style={{
                        boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`,
                        background: 'linear-gradient(90deg, rgba(62,50,0,1) 0%, rgba(108,91,0,1) 18%, rgba(133,113,1,1) 41%, rgba(133,113,1,1) 64%, rgba(108,91,0,1) 84%, rgba(62,50,0,1) 99%)'
                    }}>

                        
                        <div className="absolute md:block bottom-1/4 left-1/2 transform -translate-y-1/4 -translate-x-1/2 text-slate-700 text-4xl font-bold opacity-90">
                            <span className=' text-yellow-400 font-banger'>Hi-Lo Card</span>
                        </div>

                        <div id='pot_money_box' className='w-full text-sm absolute left-1/2 top-1/4 transform -translate-y-1/4 -translate-x-1/2'>
                            <PotMoney pot={pot} />
                        </div>
                        <div className="absolute left-1/2 w-100  right-1/2 top-1/2  transform -translate-x-1/2 -translate-y-1/2 ">
                            <div className='relative w-50 mx-auto flex items-center justify-center '>
                                {gameStatus == 'playing' && <div id='high_low_betting_card' className='betting_card absolute left-0 md:left-1/4 top-1/2 transform -translate-y-1/2'>
                                    <BettingCard className={` h-24 w-16 rounded-sm `} card={currentMoveInfo?.card} />
                                </div>}

                                {(gameStatus == 'playing') && <div className={`hidden_card absolute right-0 md:right-1/4 top-1/2 transform z-10 -translate-y-1/2`}>
                                    <HiddenCard />
                                </div>}
                                {showResultCard && (gameStatus == 'playing') && <div className='fade-in-up z-20 absolute right-0 md:right-1/4 top-1/2 transform -translate-y-1/2'>
                                    <BettingCard card={showResultCard} />
                                </div>}
                            </div>

                        </div>

                        {samplePlayers.map((player, index) => {
                            return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)} }`} >
                                <Player key={index} showResultCard={showResultCard} gameStatus={gameStatus} socket={socket} currentPlayer={currentPlayer} leftPlayers={leftPlayers} foldedPlayers={foldedPlayers} userBalances={userBalances} player={player} timer={timer}
                                    position={player?.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, samplePlayers.length)} /></div>

                        })}


                    </div>

                </div>
                {/* Action buttons */}

                {/* <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex flex-col items-center gap-4 w-full sm:w-full md:w-2/3 lg:w-1/2">
                    <div className='flex flex-row items-center justify-center w-full gap-2'>
                        <button className="px-6 py-2 bg-blue-800 w-full text-white text-xs font-bold rounded-md hover:bg-blue-600 transition-colors">
                            Call - 0.20 Sol
                        </button>
                        <button className="px-6 py-2 bg-red-500 text-xs text-black font-extrabold rounded-md hover:bg-red-600 transition-colors">
                            Fold
                        </button>
                        <button outline className="px-6 py-1.5 border-2 border-red-500 text-xs text-red-500 w-full font-extrabold rounded-md transition-colors">
                            All In
                        </button>
                    </div>
                    <div className='flex flex-row items-center justify-center w-full gap-2 text-white  font-extrabold'>
                        <input type="text" className="w-full py-1.5 bg-slate-700 text-white rounded-md text-center" placeholder="" />
                        <button className="px-6 py-2 bg-green-500 text-white text-xs font-bold rounded-md hover:bg-green-600 transition-colors"> Raise </button>
                        <button className="px-6 py-2 bg-green-500 text-white text-xs font-bold rounded-md hover:bg-green-600 transition-colors"> + </button>
                        <button className="px-6 py-2 bg-green-500 text-white text-xs font-bold rounded-md hover:bg-green-600 transition-colors"> - </button>
                    </div>

                </div> */}
                <div className='absolute bottom-4 left-1/2 -translate-x-1/2 flex flex-col items-center gap-4 w-full sm:w-full md:w-2/3 lg:w-1/2'>
                    <HighLowActionbuttons maxBet={maxBet} pot={pot} gameStatus={gameStatus} currentMoveInfo={currentMoveInfo} gameBetStatus={gameBetStatus} />
                </div>
            </div>}
        </>
    )
}

export default PlayGame
