import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify';
import socketIOClient from 'socket.io-client';
import axiosInstance from '../../../../Config/axios';
import ToastContent from '../../../../CommonComponent/Toast';
import hotToast from 'react-hot-toast';
import Loading from './Common/Loading';
import JoinUsers from './JoinUsers';
import PlayGame from './PlayGame';
import GameResult from './Result';
import Chat from '../../../ChatingComponent/chatingComponent';
import PokerAudio from '../../../../CommonComponent/PokerAudio';

const ENDPOINT = process.env.REACT_APP_API_URL;

const SevenCardStudPoker = () => {

    const { gameAddress } = useParams()
    const userData = useSelector(state => state.auth.userData)

    const [gameInfo, setGameInfo] = useState(null)
    const [socket, setSocket] = useState(null)



    const fetchGameInfo = async () => {
        try {
            const bodyData = { gameAddress }
            const response = await axiosInstance.post('/api/game/7-card-poker/info', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent status="error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, theme: 'dark', position: 'bottom-center'
                })
            } else {
                setGameInfo(responseData.data)
                if (socket) {
                    socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })
                }
            }
        } catch (error) {
            toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, theme: 'dark', transition: Slide })
        }
    }

    useEffect(() => {
        fetchGameInfo()
    }, [gameAddress, socket])


    useEffect(() => {
        const newSocket = socketIOClient(ENDPOINT);
        setSocket(newSocket);

        newSocket.on('opponentJoined', (data) => {
            fetchGameInfo()
        });

        newSocket.on('opponentLeft', (data) => {
            const userId = data.userId
            if (userId === userData?.id) {
                hotToast.success('You left the game!', { duration: 5000, position: 'bottom-center' })
            } else {
                hotToast.success('Opponent Left!', { duration: 5000, position: 'bottom-center' })
                fetchGameInfo()
            }
        })

        newSocket.on('gameStarted', (data) => {
            hotToast.success('Game Started!', { duration: 2000, position: 'bottom-center' })
            fetchGameInfo()
        });

        newSocket.on('gameOver', (data) => {
            hotToast.success('Game Over! ', { duration: 2000, position: 'bottom-center' })
            setTimeout(() => {
                fetchGameInfo()
            }, 500);
        })


        return () => {
            newSocket.off('opponentJoined')
            newSocket.off('opponentLeft')
            newSocket.off('gameStarted')
            newSocket.off('gameOver')
        }

    }, [gameAddress])

    const handlePauseForAll = (playing) => {
        if (playing) {
            socket.emit('pauseGameMusic', { roomId: gameAddress })
        } else {
            socket.emit('playGameMusic', { roomId: gameAddress })
        }
    }

    const gamePlayers = gameInfo?.players || []
    const myProfile = gamePlayers.find(player => player.userId === userData?.id)
    const chatingComponentMemo = useMemo(() => <Chat userId={userData?.id} roomId={gameAddress} />, [])
    const pokerAudioMemo = useMemo(() => <PokerAudio createdBy={gameInfo?.createdBy} socket={socket} gameAddress={gameAddress} handlePauseForAll={handlePauseForAll} />, [gameInfo, socket])

    if (!gameInfo) return <Loading />


    return (
        <>
            {pokerAudioMemo}
            {chatingComponentMemo}
            {(gameInfo['status'] == 'pending' && myProfile) && <JoinUsers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
            {(gameInfo['status'] == 'pending' && !myProfile) && <JoinUsers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
            {(gameInfo?.status == 'started') && <PlayGame gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
            {((gameInfo?.status == 'completed') && !myProfile) && <GameResult gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
            {((gameInfo?.status == 'completed') && myProfile) && <JoinUsers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
        </>
    )
}

export default SevenCardStudPoker
