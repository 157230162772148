import React, { useEffect, useMemo, useState } from 'react'
import WaitingForPlayers from './WaitingForPlayers'
import PlayGame from './PlayGame'
import { useParams } from 'react-router-dom'
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from "../../../../CommonComponent/Toast"
import axiosInstance from "../../../../Config/axios"
import socketIOClient from 'socket.io-client';
import { useSelector } from 'react-redux';
import hotToast from 'react-hot-toast';
import GameResult from './GameResult';
import Chat from '../../../ChatingComponent/chatingComponent';
import { useSpeechSynthesis } from 'react-speech-kit';
import PokerAudio from '../../../../CommonComponent/PokerAudio';

const ENDPOINT = process.env.REACT_APP_API_URL;

const SevenTwentySevenPoker = () => {

  const { gameAddress } = useParams()
  const { speak } = useSpeechSynthesis()
  const userData = useSelector(state => state.auth.userData)

  const [gameInfo, setGameInfo] = useState({})
  const [socket, setSocket] = useState(null)

  const fetchGameInfo = async () => {
    try {
      const bodyData = { gameAddress }
      const response = await axiosInstance.post('/api/game/seven-twenty-seven/info', bodyData)

      const { data: responseData } = response

      if (!responseData.status) {
        toast.error(<ToastContent title="Error" message={responseData.message} />, {
          transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
        })
      } else {
        setGameInfo(responseData.data)
        if (socket) {
          socket.emit('joinedGame', { userId: userData?.id, roomId: gameAddress })
        }
      }
    } catch (error) {
      toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, transition: Slide })
    }
  }



  useEffect(() => {
    fetchGameInfo()
  }, [gameAddress, socket])


  useEffect(() => {
    const newSocket = socketIOClient(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('opponentJoined', (data) => {
      fetchGameInfo()
    });

    newSocket.on('opponentLeft', (data) => {
      const userId = data.userId
      if (userId === userData?.id) {
        hotToast.success('You left the game!', { duration: 5000, position: 'bottom-center' })
      } else {
        hotToast.success('Opponent Left!', { duration: 5000, position: 'bottom-center' })
        fetchGameInfo()
      }
    })

    newSocket.on('gameStarted', (data) => {
      speak({ text: 'Game started'})
      hotToast.success('Game Started!', { duration: 2000, position: 'bottom-center' })
      fetchGameInfo()
    });

    newSocket.on('gameOver', (data) => {
      speak({ text: 'Game over'})
      hotToast.success('Game Over! ', { duration: 2000, position: 'bottom-center' })
      setTimeout(() => {
        fetchGameInfo()
      }, 500);
    })


    return () => {
      newSocket.off('opponentJoined')
      newSocket.off('opponentLeft')
      newSocket.off('gameStarted')
      newSocket.off('gameOver')
    }

  }, [gameAddress])
  const handlePauseForAll = (playing) => {
    if (playing) {
        socket.emit('pauseGameMusic', { roomId: gameAddress })
    } else {
        socket.emit('playGameMusic', { roomId: gameAddress })
    }
}


  const gamePlayers = gameInfo?.players || []
  const myProfile = gamePlayers.find(player => player.userId === userData?.id)
  const chatingComponentMemo = useMemo(() => <Chat userId={userData?.id}  roomId={gameAddress} />, [])
  const pokerAudioMemo = useMemo(() => <PokerAudio createdBy={gameInfo?.createdBy} socket={socket} gameAddress={gameAddress} handlePauseForAll={handlePauseForAll} />, [gameInfo, socket])

  return (
    <>
            {pokerAudioMemo}

        {/* {chatingComponentMemo} */}
      {gameInfo.status === 'pending' && <WaitingForPlayers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
      {gameInfo.status === 'started' && <PlayGame gameInfo={gameInfo} socket={socket} />}
      {gameInfo.status === 'completed' && !myProfile && <GameResult gameInfo={gameInfo} socket={socket} />}
      {gameInfo.status === 'completed' && myProfile && <WaitingForPlayers gameInfo={gameInfo} socket={socket} fetchGameInfo={fetchGameInfo} />}
    </>
  )


}

export default SevenTwentySevenPoker
