import React from 'react'
import { BsDiamondFill } from "react-icons/bs";

const DiamondCard = ({ Rank }) => {

    if (!Rank) return null

    return (
        <div className='relative w-full h-full'>
            <div className='absolute top-0.5 left-1 flex flex-col font-extrabold text-2xl'>
                <span className='text-red-500 font-extrabold text-sm mb-0'>{Rank}</span>
            </div>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col font-extrabold text-2xl'>
                <BsDiamondFill className='text-red-500' fontSize={20} />
            </div>
            <div className='absolute bottom-0.5 right-1 flex flex-col font-extrabold text-2xl'>
                <span className='text-red-500 font-extrabold text-sm mb-0'>{Rank}</span>
            </div>
        </div>
    )
}

export default DiamondCard
