import React from 'react'
import Card from '../Common/Cards';

const PlayerShowCardsModal = ({ show, handleClose, cards }) => {

    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
            <div className="text-black rounded-lg pb-2 py-4 px-3 w-full h-auto max-w-md relative bg-[#125309]">
                <button
                    type="button"
                    className="absolute -top-4 -right-3 px-3 py-1 bg-gray-300 text-gray-700 rounded-3xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                    onClick={handleClose}
                >
                    X
                </button>
                <div className='flex w-full items-center justify-between gap-0.5 flex-wrap'>
                    {cards.map((card, index) => (
                        <div className=''>
                            <Card textSize={'text-xs'} fontSize={12} className={`z-20 h-24 w-16 rounded-md bg-red-600`} key={index} card={card} index={index} /> 
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PlayerShowCardsModal