import React from 'react'
import SolToUSD from '../../../../../../CommonComponent/SolToUSD'

const PotMoney = ({pot}) => {

  if (pot === 0) return null

  return (
    <div className='text-white px-5 py-2.5 rounded shadow-sm bg-black font-extrabold flex flex-col justify-center items-center'>
      <span className='text-sm'>{Number(pot).toFixed(2)} Sol</span>
      <span className='text-xs'>
        (<SolToUSD amount={pot} symbol={'USD'}/>)
      </span>
    </div>
  )
}

export default PotMoney
