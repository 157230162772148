import React, { useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import axiosInstance from '../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../../CommonComponent/Toast';

const StartGameAction = ({ fetchGameInfo }) => {
  const [isStartButtonLoading, setIsStartButtonLoading] = useState(false)
  const { gameAddress } = useParams()

  const handleStartGame = async () => {
    setIsStartButtonLoading(true)

    const response = await axiosInstance.post('/api/game/highlow/start', { gameAddress })
    const { data: responseData } = response

    if (!responseData.status) {
      toast.error(<ToastContent title="Error" message={responseData.message} />, {
        transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
      })
    } else {
      fetchGameInfo()    
    }
    setIsStartButtonLoading(false)

  }

  return (
    <button className='w-full md:w-1/2 flex flex-row justify-center items-center bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg' onClick={handleStartGame}>
      {isStartButtonLoading ? <HourglassEmptyIcon className='animate-spin' /> : 
        <span className='flex items-center text-xs'> <PlayArrowIcon fontSize='16' className='mr-1'/> Start Game </span>
    }
    </button>
  )
}

export default StartGameAction
