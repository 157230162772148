'use client'
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image from "../../../Image/banner.webp";
import { useNavigate } from "react-router-dom";
import { SparklesCore } from "../../UI/sparkels";
import Login from "../../Login";

export default function Slider({ openAuth }) {
  const[open,setOpen]=useState(false)

 function handleDuireaction(){
  if (openAuth){
    navigate("/all-game-page")
  }
  else{
    setOpen(true)
  }
 }

  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-tl from-[#050205] from-10% via-[#1e0720] via-30% to-[#26042a] to-90%">
          {open && (
        <Login
          open={open}
          setOpen={setOpen}
        />
      )}
      <div className="h-[40rem] relative w-full  flex flex-col items-center justify-center overflow-hidden rounded-md">
      <div className="w-full absolute  h-screen">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={80}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
      </div>
      {/* <h1 className="md:text-7xl text-3xl lg:text-6xl font-bold text-center text-white relative z-20">
        Build great products
      </h1> */}
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={true}
        >
        {[1, 2, 3].map((_, index) => (
          <div className="lg:flex p-5 md:p-10 items-center lg:w-full lg:mt-0 pt-10 md:pt-24" key={index}>
            <div className="w-full md:w-[50%] flex flex-col justify-center items-center gap-5">
              <span>
                <p className="text-5xl text-white uppercase mb-3">Welcome to the <br /> PVP Solana Casino</p>
                <p className="text-xl text-white">Play your favorite casino games against real people and win big</p>        
                {/* <p className="text-4xl md:text-5xl text-yellow-500 font-bold my-6">
                  $ 20,000.00
                </p> */}
              </span>
              <span
                onClick={() =>handleDuireaction()}
                className="bg-gradient-to-r w-1/2 cursor-pointer from-[#F628FC] via-[#B723DD] to-[#7A1EBF] py-2 px-3 rounded-md text-white font-bold"
                >
                {!openAuth ? "Sign In Now!" : "Let's play"}
              </span>
            </div>
            <div className="w-full md:w-[50%]">
              <img src={image} height={50} width={50} alt="" className="object-contain h-[500px]" />
            </div>
          </div>
        ))}
      </Carousel>
        </div>
    </div>
  );
}
