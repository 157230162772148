import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {

    const numbers = [4, 0, 4]

    const navigate = useNavigate()
    
    const handleBackToHome = () => {
        navigate('/all-game-page')
    }

    return (
        <div className='flex justify-center items-center h-screen'>

            <div className=" poker_table container h-full sm:h-full md:h-2/3 lg:h-2/3 rounded-lg shadow-lg top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                <div className=" p-3 shadow-in h-full w-full  rounded-full">
                    <div className=" relative gap-4 p-4 h-full w-full  rounded-full ">
                        <div id='dealer_target' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <div className="flex space-x-2 mb-4 justify-center">
                                    {numbers.map((number, index) => (
                                        <div
                                            key={index}

                                            className="p-1 cursor-pointer active:scale-95 w-16 h-24 bg-gray-200 rounded-md flex items-center justify-center text-4xl font-bold"
                                        >
                                            <span className='bg'>{number}</span>
                                        </div>
                                    ))}
                                </div>

                                <h4 className='text-center text-white font-extrabold text-2xl'>
                                    Opps....  Page is not found
                                </h4>

                                <button onClick={handleBackToHome} className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5 font-bold px-5 rounded-lg block mx-auto mt-4'>
                                    Back to Home
                                </button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default NotFound
