import React, { useState, useContext } from "react";
import { useAccount } from "wagmi";
import logo from "../../../Image/logo.png";
import Login from "../../Login/index";
import Deposit from "../../Deposit";
import { StakingApp } from "../../Authentication/Context/StakingApp";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../UserProfile/index";
import WalletHeader from "../../WalletHeader/index";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CasinoIcon from "@mui/icons-material/Casino";
import { title } from "process";

export default function Header() {
  const { user } = useContext(StakingApp);
  const navigate = useNavigate();
  const { openAuth } = useContext(StakingApp);
  const { address, isConnected, isDisconnected } = useAccount();
  const [openDeposit, setOpenDeposit] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const gamesList = [
    {
      title: "Rock Paper Scissors",
      route: "rock-paper-scissors",
    },
    {
      title: "Texas Hold'em",
      route: "poker",
    },
    {
      title: "Hi-Lo Card Game",
      route: "hi-lo-card-game",
    },
    {
      title: "7/27 Poker",
      route: "7-27-poker",
    },
    {
      title: "5 Card Draw",
      route: "5-card-poker",
    },
    {
      title: "Uno Card Game",
      route: "uno-card-game",
    },
    {
      title: "7 Card Stud Poker",
      route: "7-card-poker",
    }
  ]

  return (
    <div className="flex flex-col bg-[#1A1A1A] shadow-md">
      {open && <Login open={open} setOpen={setOpen} />}
      {openDeposit && (
        <Deposit openDeposit={openDeposit} setOpenDeposit={setOpenDeposit} />
      )}

      <div className="flex flex-row justify-between items-center bg-gradient-to-r from-[#c407f822] via-purple-700 to-[#c407f822]  px-4 md:px-16 py-4">
        <div className="flex justify-center items-center">
          <img
            src={logo}
            alt="Logo"
            className="h-14 w-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex flex-row justify-center items-center gap-6">
          <div className="relative">
            <span
              className="flex gap-3 justify-center items-center cursor-pointer text-white font-bold text-lg"
              onClick={toggleDropdown}
            >
              <CasinoIcon className="text-[#FFD700]" />
              <p className="cursor-pointer">Casino</p>
            </span>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <ul className="absolute mt-2 bg-[#252525] rounded-lg shadow-lg w-48 py-2 z-10">
                {gamesList.map(
                  (game) => (
                    <li onClick={()=>navigate(`/game-view/${game.route}`)+setIsDropdownOpen(false)}
                      key={game.title}
                      className="px-4 py-2 hover:bg-[#FFD700] hover:text-black text-white transition duration-200 cursor-pointer"
                    >
                      {game.title}
                    </li>
                  )
                )}
              </ul>
            )}
          </div>

          {/* Wallet & User Profile */}
          {openAuth && (
            <>
              <WalletHeader />
              <span
                // onClick={() => setOpenDeposit(true)}
                onClick={() => navigate("/deposit")}
                className="flex items-center gap-2 text-black font-bold bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 shadow-lg rounded-lg text-sm px-5 py-2.5 text-center"
              >
                <AttachMoneyIcon />
                Deposit
              </span>
              <UserProfile />
            </>
          )}

          {/* Sign In Button */}
          {!openAuth && (
            <p
              className="px-5 py-2 bg-[#252525] text-white rounded-lg cursor-pointer"
              onClick={() => setOpen(true)}
            >
              Sign in
            </p>
          )}
        </div>
      </div>

      {/* Marketing Line */}
      <div className="bg-[#252525] py-2">
        <marquee behavior="scroll" direction="left" scrollamount="5" className="text-[#FFD700] font-semibold">
          Welcome to the best PVP Casino experience! Enjoy our exciting games including Texas Hold'em, 5 Card Draw, 7 Card Stud, and Rock Paper Scissors. Win big!
        </marquee>
      </div>
    </div>
  );
}
