import React from 'react'
import Card from "../Pages/Cards/index"
import { IoMdHeart } from "react-icons/io";
import { ImSpades } from "react-icons/im";
import { BsDiamondFill } from "react-icons/bs";
import { GiClubs } from "react-icons/gi";

const CurrentPlayer = ({ gameResult, showResult, player, gameMoveStatus }) => {
    const cards = player['Hand'] || []

    const CardIcon = ({ suit }) => {
        if (suit == 'H') return <IoMdHeart className='text-[#aea306] text-sm ' fontSize={16} />
        if (suit == 'S') return <ImSpades className='text-[#aea306] text-sm ' fontSize={16} />
        if (suit == 'D') return <BsDiamondFill className='text-[#aea306] text-sm ' fontSize={16} />
        if (suit == 'C') return <GiClubs className='text-[#aea306] text-sm ' fontSize={16} />
    }

    return (
        <div className={`relative flex  justify-center items-center `}>
            <div className={`w-full flex items-center justify-center gap-1`}>
                {gameMoveStatus.betStatus === 'playing' && cards.map((item, index) => {
                    return <div className="relative w-10 h-14 bg-black rounded-lg flex items-center justify-center text-white border-2 border-[#aea306] pvp_casino_card">
                        <span className='text-[#aea306] text-xs sm:text-xs md:text-sm font-extrabold absolute right-1 bottom-1'>{item['Rank']}</span>
                        <span className='text-[#aea306] text-xs sm:text-xs md:text-sm font-extrabold absolute top-1 left-1'>{item['Rank']}</span>
                        <span className='text-[#aea306] font-extrabold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'><CardIcon suit={item['Suit']} /></span>
                    </div>
                })}
            </div>
        </div>
    )
}

export default CurrentPlayer