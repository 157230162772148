// import React from 'react'

// import HeartCard from './HeartCard'
// import SpadeCard from './SpadeCard'
// import DiamondCard from './DiamondCard'
// import ClubCard from './ClubCard'

// const Card = ({ card, className }) => {

//   const Rank = card['Rank']
//   const Suit = card['Suit']


//   return (
//     <div role='button' className={`${className ? className : "w-12 h-20 rounded-md "} bg-white  hover:scale-105'`}>
//       {Suit == 'H' && <HeartCard Rank={Rank} />}
//       {Suit == 'S' && <SpadeCard Rank={Rank} />}
//       {Suit == 'D' && <DiamondCard Rank={Rank} />}
//       {Suit == 'C' && <ClubCard Rank={Rank} />}

//     </div>
//   )
// }

// export default Card


import React from 'react'
import { IoMdHeart } from "react-icons/io";
import { ImSpades } from "react-icons/im";
import { BsDiamondFill } from "react-icons/bs";
import { GiClubs } from "react-icons/gi";

const CardIcon = ({ suit }) => {
  if (suit == 'H') return <IoMdHeart className='text-[#aea306] text-sm ' fontSize={16} />
  if (suit == 'S') return <ImSpades className='text-[#aea306] text-sm ' fontSize={16} />
  if (suit == 'D') return <BsDiamondFill className='text-[#aea306] text-sm ' fontSize={16} />
  if (suit == 'C') return <GiClubs className='text-[#aea306] text-sm ' fontSize={16} />
}

const Card = ({ card, className }) => {
  return (
    <div className={`relative ${className ? className : "w-10 h-14"} bg-black rounded-lg flex items-center justify-center text-white border border-slate-700 pvp_casino_card`}>
      <span className='text-[#aea306] text-xs sm:text-xs md:text-sm font-extrabold absolute right-1 bottom-1'>{card['Rank']}</span>
      <span className='text-[#aea306] text-xs sm:text-xs md:text-sm font-extrabold absolute top-1 left-1'>{card['Rank']}</span>
      <span className='text-[#aea306] font-extrabold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'><CardIcon suit={card['Suit']} /></span>
    </div>
  )
}

export default Card