
// import rootReducer from "./rootReducer";
// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

// const store = configureStore({
//   reducer: rootReducer,

//   middleware: getDefaultMiddleware({
//     serializableCheck: false
//   })
// })

// export { store }


import { configureStore } from '@reduxjs/toolkit';
import balanceReducer from './balanceSlice';
import userData from './userProfile'
import auth from './authentication';

  const store = configureStore({
  reducer: {
    auth,
    balance: balanceReducer,
    userData: userData
  }
});
export default   store ;