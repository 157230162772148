import React, { useEffect, useState } from 'react'
import { formatAddress } from '../../../../../../Config/function'
import Card from '../../ common/index'
import HiddenCard from '../../ common/HiddenCard'
import { useSelector } from 'react-redux'
import FoldImage from '../../../../../../assets/img/fold.png'
import LeftImage from '../../../../../../assets/img/left.png'
import BustImage from '../../../../../../assets/img/busted.png'
import AddIcon from '@mui/icons-material/Add';
import SolToUSD from '../../../../../../CommonComponent/SolToUSD'

const Player = ({ gameStatus, timer, player, showCards, currentPlayer, playerCards, foldedPlayers, leftPlayers, userBalances, bustedPlayers, position }) => {
  const userData = useSelector(state => state.auth.userData)

  const [cards, setCards] = useState(playerCards || [])

  const myProfile = (player?.userId == userData?.id)
  const isPlayerMove = player?.userId == currentPlayer


  useEffect(() => {
    setCards(playerCards)
  }, [playerCards])

  const newPlayerCard = cards.find((item) => item.userId === player.userId)?.cards || []

  const isFolded = foldedPlayers?.includes(player.userId)
  const isLeft = leftPlayers?.includes(player.userId)
  const isBusted = bustedPlayers?.includes(player.userId)

  const playerBalance = userBalances?.find((item) => item?.userId === player.userId)?.amount || 0

  const ProgressTimer = ({ value }) => {
    const [timeLeft, setTimeLeft] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (timeLeft === 0) return;
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);

    }, [timeLeft, value])


    let progressColor = 'bg-green-500 text-black';
    if (timeLeft < 10) {
      progressColor = 'bg-red-500 text-black';
    } else if (timeLeft < 20) {
      progressColor = 'bg-yellow-500 text-black';
    }

    let textColor = 'text-black';

    if (timeLeft == 0) return null;

    const calculateDashOffset = () => {
      const circumference = 2 * Math.PI * 45
      const dashOffset = circumference * (1 - timeLeft / 30)
      return dashOffset
    }
    let color = '#55eb34'
    if (timeLeft <= 10) {
      color = 'red'
    } else if (timeLeft <= 20) {
      color = 'yellow'
    }

    return <svg className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="green"
        strokeWidth="6"
        className='z-10 '
      />
      {/* Progress arc */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={calculateDashOffset()}
        transform="rotate(-90 50 50)"
      />
    </svg>
  }

  const totalHiddenCards = newPlayerCard.filter((card) => !card.isOpen).length




  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600 text-white font-extrabold`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }


  return (
    <>
      {false && <div className={`hi-lo-player flex items-center flex-col	`}>


        <div className='relative cards flex flex-row items-center justify-between gap-2 mb-2 '>


          {!isBusted && !isFolded && !isLeft && (showCards) && !myProfile &&
            newPlayerCard.map((card, index) => {
              return (
                card.isOpen
                  ? <Card className={'fade-in-up h-16 w-12 rounded-md shadow-md shadow-gray-400'} key={index} card={card} />
                  : ''
              )
            })}
          {!isFolded && !isLeft && showCards && !myProfile && !isBusted && <HiddenCard cards={totalHiddenCards} className={'fade-in-up h-20 w-14'} />}
          {isFolded && !isLeft && <img src={FoldImage} alt='fold' className='w-24 h-12' />}
          {isLeft && !isFolded && <img src={LeftImage} alt='left' className='w-24 h-12' />}
          {isBusted && !isFolded && !isLeft && <img src={BustImage} alt='busted' className='w-24 h-12' />}



        </div>
        <div className={`${isPlayerMove && "border-2 border-white py-2 flex flex-row items-center justify-between px-3 bg-green-400 rounded-full relative"}`}>
          <div className={`flex flex-row items-center justify-center p-1 bg-black font-extrabold px-1 text-center border-2 rounded-md `}>
            <img src={player.profileUrl} alt='avatar' className='w-10 h-10 rounded-full mr-3 border-2 border-white' />
            <div className='flex flex-col justify-between items-start'>
              <div className='name text-white mb-1 text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>
              {player.userId === userData?.id ? <div className='balance text-white text-xs'>{Number(playerBalance).toFixed(2)} Sol</div> : <div className='balance text-white  text-xs'> *** Sol</div>}
            </div>
          </div>
          <div className={`absolute ${player.userId === userData?.id ? '-top-4' : '-bottom-4'} rounded-full left-1/2 transform -translate-x-1/2 text-xs h-8 w-8 font-extrabold`}>
            {timer && (currentPlayer === player.userId) && (gameStatus != 'starting') && <ProgressTimer value={timer} />}
          </div>
        </div>
      </div>}

      <div className={`hi-lo-player  flex flex-col items-center `}>

        <div className="relative z-20">

        
          <div className='flex flex-row justify-center items-center gap-0.5'>
          {!isBusted && !isFolded && !isLeft && (showCards) && !myProfile &&
            newPlayerCard.map((card, index) => {
              return (
                card.isOpen
                  ? <Card className={'fade-in-up h-16 w-10 rounded-sm shadow-md shadow-gray-400'} key={index} card={card} />
                  : ''
              )
            })}
          {!isFolded && !isLeft && showCards && !myProfile && !isBusted && <HiddenCard cards={totalHiddenCards} className={'fade-in-up h-16 w-10 rounded-sm'} />}

          </div>
          
          {isFolded && !isLeft && <img src={FoldImage} alt='fold' className='w-24 h-12' />}
          {isLeft && !isFolded && <img src={LeftImage} alt='left' className='w-24 h-12' />}
          {isBusted && !isFolded && !isLeft && <img src={BustImage} alt='busted' className='w-24 h-12' />}

          {timer && (currentPlayer === player.userId) && (gameStatus != 'starting') && <ProgressTimer value={timer} />}

          {(!isBusted && !isFolded && !isLeft) && <img src={player.profileUrl} alt='profile' className={`mx-auto hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${player.active && "active"}`} />}

        </div>
        <div className="mt-1 text-xs font-medium px-1 py-1 bg-red-500 text-black rounded-md w-full">
          {player?.userId === userData?.id ? <div className='balance text-black text-xs flex flex-col w-full justify-center items-center'>
            {Number(playerBalance).toFixed(2)} Sol
            <span>(<SolToUSD amount={Number(playerBalance)} symbol={"USD"} />)</span>
          </div> : <div className='balance text-black text-xs text-center'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>}
        </div>

      </div>
    </>
  )
}

export default Player
