export const cardValues_seven = {
    'A': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6,
    '7': 7, '8': 8, '9': 9, '10': 0.5,
    'J': 0.5, 'Q': 0.5, 'K': 0.5
};


export const cardValues_twenty_seven = {
    '2': 2, '3': 3, '4': 4, '5': 5, '6': 6,
    '7': 7, '8': 8, '9': 9, '10': 0.5,
    'J': 0.5, 'Q': 0.5, 'K': 0.5, 'A': 11
}
