import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import CasinoIcon from "@mui/icons-material/Casino";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentIcon from "@mui/icons-material/Payment";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from '@mui/icons-material/Clear';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false); // Start with the sidebar closed
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: isOpen ? 280 : 60 }}
          exit={{ width: 60 }}
          transition={{ duration: 0.3 }}
          className={`bg-gradient-to-r from-[#26042a] via-[#1e0720] to-[#1a0325] shadow-xl h-full flex flex-col justify-start p-2  top-0 left-0 z-50`}
          style={{
            overflowY: "auto",
            maxHeight: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <span
            onClick={toggleSidebar}
            className="text-black bg-[#bda400] cursor-pointer hover:bg-[#bda400] rounded-full h-12 w-12 flex justify-center items-center outline-none transition-all duration-300 ease-in-out"
            style={{
           
              top: '1rem',
              right: isOpen ? '-1.5rem' : '-1.5rem', // Center it when closed
            }}
          >
            {isOpen ? (
              <ClearIcon  />
            ) : (
              <MenuIcon  />
            )}
          </span>
          <div className="flex flex-col justify-center items-start  ">
            {/* {isOpen && (
              <div className="flex cursor-pointer items-center justify-center bg-gradient-to-r from-red-500 to-yellow-400 w-full p-3 rounded-lg shadow-lg">
                <CardGiftcardIcon className="text-white mr-3" />
                <p className="font-bold text-white text-lg">Bonus Points</p>
              </div>
            )} */}
            <div className="flex flex-col space-y-2 w-full mt-4">
              <MenuItem text="Casino" icon={<CasinoIcon />} position="/all-game-page" isOpen={isOpen} />
              <MenuItem text="Game Rules" icon={<AlignHorizontalLeftIcon />} position="/game-rules" isOpen={isOpen}  />
              <MenuItem text="White Paper" icon={<ImportContactsOutlinedIcon />} position="#" isOpen={isOpen}  onClickFunction={() => {
                window.open('https://docs.google.com/document/d/1AONe0Io5WDMnc2CcSEEFOCQNV7EkCZIH17Gn_4fe54Q', '_blank');
              }} />
              <MenuItem text="Leader board" icon={<FavoriteIcon />} position='/leader-board' isOpen={isOpen} />
              <MenuItem text="Poker Tournaments" icon={<ListAltIcon />} position='/poker-tournament' isOpen={isOpen} />
              <MenuItem text="Future projects" icon={<SportsEsportsIcon />} position='/future-project' isOpen={isOpen} />
              {/* <MenuItem text="Referral Earning" icon={<MoneyOffIcon />} position="/referralearning" isOpen={isOpen} /> */}
              <MenuItem text="Withdrawal" icon={<AccountBalanceWalletIcon />} position="/withdrawal" isOpen={isOpen} />
              <MenuItem text="Deposit" icon={<PaymentIcon />} position="/deposit" isOpen={isOpen} />
              <MenuItem text="Wallet" icon={<AccountBalanceWalletIcon />} position="/wallet" isOpen={isOpen} />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

function MenuItem({ text, icon, position, isOpen, onClickFunction }) {
  const navigate = useNavigate();
  const location = useLocation();

  const bgColor = (location.pathname === position) ? "bg-[#bda400]" : "";

  return (
    <div
      onClick={() => onClickFunction ? onClickFunction() : navigate(position)}
      className={`flex items-center ${bgColor} hover:bg-[#bda400] ${isOpen ? 'justify-start' : "justify-center"} hover:text-black bg-[#26042a] bg-opacity-80 hover:bg-opacity-100 p-3 rounded-lg transition-all duration-200 ease-in-out cursor-pointer text-white  hover:shadow-md`}
    >
      <span className="flex items-center justify-center">
        {icon && <span className=" text-center mr-2">{icon}</span>}
        {isOpen && <p className=" font-semibold">{text}</p>}
      </span>
    </div>
  );
}
