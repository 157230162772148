import React from 'react'
import Cards from '../Common/Cards';
const WildCardFourModal = ({ card }) => {
    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-90">
            <div className="animated_scale text-white rounded-lg max-w-md relative flex items-center justify-center">
                <Cards card={card} className='h-36 w-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
            </div>
        </div>
    )
}

export default WildCardFourModal
