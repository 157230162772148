import React from 'react'

const HiddenCard = ({ cards, className }) => {
    return (
        <div className='w-10 flex items-center flex-col justify-center h-16 bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none  rounded-sm'>
             <span className='font-extrabold text-md'>+{cards}</span>
             <span className='text-xs italic font-bold'>more</span>
        </div>
    )
}

export default HiddenCard
