import React from 'react'
import Card from "../Common/Cards/index"
import { useSelector } from 'react-redux'
const CurrentPlayerCards = ({ foldedPlayers, leftPlayers, gameStatus, currentPlayer, playerCards, handleToggleCard, selectedCards }) => {

    const userData = useSelector(state => state.auth.userData)

    const isFolded = foldedPlayers.includes(currentPlayer?.userId)
    const isLeft = leftPlayers.includes(currentPlayer?.userId)
    const currentPlayerCards = playerCards?.find(playerCard => playerCard.userId === currentPlayer.userId)?.cards || []


    return (
        <div>
            {!isFolded && !isLeft && (currentPlayer.userId === userData?.id) && <div className={`flex items-center justify-center gap-0.5 `}>
                {gameStatus !== 'starting' && currentPlayerCards.map((card, index) => (
                    <span id={`player_card_${currentPlayer.userId}_${index}`} role='button' onClick={() => handleToggleCard(index)} className={`fade-in-up ${selectedCards.includes(index) && 'scale-125'}`} >
                        <Card textSize={'text-xs'} fontSize={12} card={card} className={"h-16 w-10 rounded-sm border-2"} />
                    </span>
                ))}
            </div>}
        </div>
    )
}

export default CurrentPlayerCards