import React from 'react'

const SelectColorModal = ({ handleColorSubmit }) => {
    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center  bg-black bg-opacity-80">
            <div className="rounded-lg max-w-md relative flex flex-col border-white border-4 justify-center gap-2 py-3 bg-black px-5">
                <span className='text-sm text-center text-white font-bold uppercase'>Select A Color</span>
                <div className='flex items-center justify-evenly gap-5'>

                    <span role='button' onClick={() => handleColorSubmit('red')} className='rotate-45 hover:scale-125 rounded-md h-8 w-8 border-2  bg-red-600 ' />
                    <span role='button' onClick={() => handleColorSubmit('blue')} className='rotate-45 hover:scale-125 rounded-md h-8 w-8 border-2  bg-blue-600 ' />
                    <span role='button' onClick={() => handleColorSubmit('green')} className='rotate-45 hover:scale-125 rounded-md h-8 w-8 border-2  bg-green-600 ' />
                    <span role='button' onClick={() => handleColorSubmit('yellow')} className='rotate-45 hover:scale-125 rounded-md h-8 w-8 border-2  bg-yellow-600 ' />
                </div>
            </div>
        </div>
    )
}

export default SelectColorModal
