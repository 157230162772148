import React from 'react';
import { useState, useEffect } from "react";
import { useAudioPlayer } from "react-use-audio-player";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { TbRewindBackward10 } from "react-icons/tb";
import { TbRewindForward10 } from "react-icons/tb";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { ImVolumeIncrease } from "react-icons/im";
import { ImVolumeDecrease } from "react-icons/im";
import { BiSolidVolumeMute } from "react-icons/bi";
import { useSelector } from 'react-redux';

const API_URL = process.env.REACT_APP_MUSIC_URL;
const SelectAudioModal = ({ isOpen, handleClose, createdBy, handlePauseForAll, socket }) => {
    const allMusics = [
        { title: 'Saliva - Click Click Boom', url: `${API_URL}/uploads/audios/poker-music/saliva_click_click_boom.mp3` },
        { title: 'Eminem - Tobey feat. Big Sean & BabyTron', url: `${API_URL}/uploads/audios/poker-music/house_of_pain.mp3` },
        { title: 'House of Pain - Jump Around', url: `${API_URL}/uploads/audios/poker-music/eminem_tobey feat_bigsean_babytron.mp3` },
        { title: 'Rev Theory', url: `${API_URL}/uploads/audios/poker-music/rev_theory.mp3` },
        { title: 'Remember The Name', url: `${API_URL}/uploads/audios/poker-music/remember_the_name.mp3` },
        { title: 'Eminem - Houdini', url: `${API_URL}/uploads/audios/poker-music/eminem_houdini.mp3` },
        { title: 'Limp bizkit', url: `${API_URL}/uploads/audios/poker-music/limp_bizkit.mp3` },
        { title: 'Ed Sheeran - Shape of You', url: `${API_URL}/uploads/audios/poker-music/ed_sheeran.mp3` },
        { title: 'Charlie Puth', url: `${API_URL}/uploads/audios/poker-music/charlie_puth.mp3` },
        { title: 'Paramore Misery', url: `${API_URL}/uploads/audios/poker-music/paramore_ misery.mp3` },
        { title: 'Awolnation Sail', url: `${API_URL}/uploads/audios/poker-music/awolnation_sail.mp3` },
        { title: 'Benson Boone', url: `${API_URL}/uploads/audios/poker-music/benson_boone.mp3` },
        { title: 'Camila Cabello', url: `${API_URL}/uploads/audios/poker-music/camila_cabell.mp3` },
        { title: 'Charlie Puth Attention', url: `${API_URL}/uploads/audios/poker-music/charlie_puth_attention.mp3` },
        { title: 'Coolio Gangsta Paradise', url: `${API_URL}/uploads/audios/poker-music/coolio_gangsta_paradise.mp3` },
        { title: 'Eminem Lose Yourself', url: `${API_URL}/uploads/audios/poker-music/eminem_lose_yourself.mp3` },
        { title: 'Eminem Till Collapse', url: `${API_URL}/uploads/audios/poker-music/eminem_till_collapse.mp3` },
        { title: 'Eminem Without Me', url: `${API_URL}/uploads/audios/poker-music/eminem_without_me.mp3` },
        { title: 'Paramore Misery', url: `${API_URL}/uploads/audios/poker-music/eurotrip_scotty.mp3` },
        { title: 'Eurotrip Scotty', url: `${API_URL}/uploads/audios/poker-music/eurotrip_scotty.mp3` },
        { title: 'Fun We Are Young Ft', url: `${API_URL}/uploads/audios/poker-music/fun_ we _are_young_ft.mp3` },
        { title: 'Imagine Dragons Believer', url: `${API_URL}/uploads/audios/poker-music/Imagine_dragons_believer.mp3` },
        { title: 'Imagine Dragons Radioactive', url: `${API_URL}/uploads/audios/poker-music/imagine_dragons_radioactive.mp3` },
        { title: 'Jimmy Eat World', url: `${API_URL}/uploads/audios/poker-music/jimmy_eat_world.mp3` },
        { title: 'Just Intimberlake', url: `${API_URL}/uploads/audios/poker-music/just_intimberlake.mp3` },
        { title: 'Katy Perry Dark Horse', url: `${API_URL}/uploads/audios/poker-music/katy_perry_dark_horse.mp3` },
        { title: 'Lmfao Party Rock Anthem', url: `${API_URL}/uploads/audios/poker-music/lmfao_party_rock_anthem.mp3` },
        { title: 'Macklemore And Ryan Lewis', url: `${API_URL}/uploads/audios/poker-music/macklemore_and_ryan_lewis.mp3` },
        { title: 'Maroon 5 Girls Like', url: `${API_URL}/uploads/audios/poker-music/maroon_5_girls_like.mp3` },
        { title: 'Maroon 5 Sugar', url: `${API_URL}/uploads/audios/poker-music/maroon_5_sugar.mp3` },
        { title: 'One Republic', url: `${API_URL}/uploads/audios/poker-music/one_republic.mp3` },
        { title: 'Sia Cheap', url: `${API_URL}/uploads/audios/poker-music/sia_cheap.mp3` },
        { title: 'Taking Back Sunday', url: `${API_URL}/uploads/audios/poker-music/taking_back_sunday.mp3` },
        { title: 'The All American Rejects', url: `${API_URL}/uploads/audios/poker-music/the_all_american_rejects.mp3` },
        { title: 'The Black Eyed Peas', url: `${API_URL}/uploads/audios/poker-music/the_black_eyed_peas.mp3` },
        { title: 'Zac Brown Band Chicken Fried', url: `${API_URL}/uploads/audios/poker-music/zac_brown_band_chicken_fried.mp3` },
        { title: 'Taking Back Sunday', url: `${API_URL}/uploads/audios/poker-music/taking_back_sunday.mp3` },

    ];

    const [isPlaying, setIsPlaying] = useState(false);
    const [musicIndex, setMusicIndex] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolumeState] = useState(0.5); 
    const userData = useSelector(state => state.auth.userData)

    const { togglePlayPause, ready, loading, playing, duration, seek, getPosition, load, pause, play, setVolume, cleanup } = useAudioPlayer();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(getPosition());
        }, 1000);
        return () => clearInterval(interval);
    }, [getPosition]);

    useEffect(() => {
        setIsPlaying(playing);
    }, [playing]);

    const handlePauseMusic = (data) => {
        pause()
    }
    const handlePlayMusic = () => {
        play()
    }

    useEffect(() => {
        if (!socket) return;
        socket.on('pauseMusic', handlePauseMusic);
        socket.on('playMusic', handlePlayMusic);

        return () => {
            socket.off('pauseMusic');
            socket.off('playMusic');
        }
    }, [socket])


    useEffect(() => {
        setVolume(volume); // Set initial volume
    }, [volume, setVolume]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const handlePlayPause = () => {
        if (playing) {
            pause();
        } else {
            play();
        }
    };

    useEffect(() => {
        load(allMusics[musicIndex]['url'], {
            autoplay: true,
            onend: () => setMusicIndex((musicIndex + 1) % allMusics.length),
        });

        return () => {
            pause();
            cleanup();
        };
    }, [musicIndex, load, cleanup]);

    const handleSeek = (e) => {
        const newPosition = parseFloat(e.target.value);
        seek(newPosition);
    };

    const handlePrevMusic = () => {
        setMusicIndex((musicIndex === 0) ? allMusics.length - 1 : musicIndex - 1);
    };

    const handleNextMusic = () => {
        setMusicIndex((musicIndex + 1) % allMusics.length);
    };

    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolumeState(newVolume);
        setVolume(newVolume);
    };

    const handleVolumeIncrease = () => {
        if (volume >= 1) return;
        setVolumeState(volume + 0.1)
        setVolume(volume + 0.1);

    }
    const handleVolumeDecrease = () => {
        if (volume <= 0) return;
        setVolumeState(volume - 0.1)
        setVolume(volume - 0.1)
    }


    if (!isOpen) return null;


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-95">
            <div className="text-white rounded-lg p-2 w-full max-w-md relative bg-gradient-to-r from-[#050133] via-[#63604e] to-[#050133] bg-opacity-30">
                <button
                    type="button"
                    onClick={handleClose}
                    className="absolute -top-4 -right-3 px-3 py-1 bg-gray-300 text-gray-700 rounded-3xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >
                    X
                </button>
                <div className="flex items-center justify-center bg-gray-950">
                    <div className="w-full max-w-md rounded-lg bg-gray-900 p-2 text-white shadow-lg">
                        <div className="mb-3 flex items-center justify-between">
                            <div>
                                <h2 className="text-sm font-semibold">{allMusics[musicIndex]['title']}</h2>
                            </div>
                            {createdBy === userData?.id && <span onClick={() => handlePauseForAll(playing)} type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-black bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br rounded-lg ">
                                Pause/Play for all 

                            </span>}
                        </div>

                        <div className="mb-6">
                            <input
                                type="range"
                                min={0}
                                max={duration || 100}
                                value={currentTime}
                                onChange={handleSeek}
                                className="w-full appearance-none bg-gray-800 h-1 rounded-full outline-none"
                                style={{
                                    background: `linear-gradient(to right, white ${(currentTime / (duration || 1)) * 100}%, gray ${(currentTime / (duration || 1)) * 100}%)`,
                                }}
                            />
                            <div className="flex justify-between text-xs text-gray-400 mt-2">
                                <span>{formatTime(currentTime)}</span>
                                <span>{duration ? `-${formatTime(duration - currentTime)}` : "--:--"}</span>
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <SkipPreviousIcon onClick={handlePrevMusic} className='cursor-pointer active:scale-110' />
                            <div className="flex items-center justify-center gap-4">
                                <button className="text-gray-400 hover:text-white" onClick={() => seek(currentTime - 10)}>
                                    <TbRewindBackward10 className="h-6 w-6 hue-rotate-30" />
                                </button>
                                <button
                                    onClick={handlePlayPause}
                                    className="h-12 w-12 rounded-full bg-white text-black hover:bg-gray-200 flex items-center justify-center"
                                >
                                    {isPlaying ? (
                                        <PauseIcon className="h-6 w-6" />
                                    ) : (
                                        <PlayArrowIcon className="h-6 w-6" />
                                    )}
                                </button>
                                <button className="text-gray-400 hover:text-white" onClick={() => seek(currentTime + 10)}>
                                    <TbRewindForward10 className="h-6 w-6" />
                                </button>
                            </div>
                            <SkipNextIcon onClick={handleNextMusic} className='cursor-pointer active:scale-110' />
                        </div>

                        {/* Volume Control */}
                        <div className="mt-2 w-1/2 mx-auto flex items-center gap-3">
                            {volume <= 0 ? <BiSolidVolumeMute /> : <ImVolumeDecrease onClick={handleVolumeDecrease} className='cursor-pointer text-xl' />}
                            <input
                                type="range"
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume}
                                onChange={handleVolumeChange}
                                className="w-full appearance-none bg-gray-800 h-1 rounded-full outline-none"
                                style={{
                                    background: `linear-gradient(to right, white ${volume * 100}%, gray ${volume * 100}%)`,
                                }}
                            />
                            <ImVolumeIncrease onClick={handleVolumeIncrease} className='cursor-pointer text-xl' />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectAudioModal;
