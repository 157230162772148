import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../../../Config/axios';
import { Slide, toast } from 'react-toastify';
import Toast from "../../../CommonComponent/Toast";
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

const WithdrawalPage = () => {
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [isWithdrawalPending, setIsWithdrawalPending] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);

  const userData = useSelector(state => state.auth.userData);

  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      try {
        const response = await axiosInstance.post('/withdrawalhistory');
        setWithdrawalHistory(response.data.message);
      } catch (err) {
        console.error(err);
      }
    };
    fetchWithdrawalHistory();
  }, []);

 

  const withdrwalrRequest = async (amount) => {
    try {
      const response = await axiosInstance.post("/withdrawalrequest", {
        amount: amount,
      });
      if (response.status) {
        toast.success(<Toast message={"Withdrawal requested submitted successfully"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      }
    } catch (err) {
      console.error(err);
    }
  };


  const handleSubmit = async (values) => {
    // Simulate withdrawal process with a 30-minute delay for demonstration
    setIsWithdrawalPending(true);
    await withdrwalrRequest(values.amount);
    setIsWithdrawalPending(false);
    setWithdrawalAmount('');
  };

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: yup.object({
      amount: yup.number().required('Required'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  })

  return (
    <div className=" overflow-auto h-[100%] flex justify-center p-2 gap-5" >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg  "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>


              <div className="mb-4">
                <label htmlFor="amount" className="text-white block mb-2">Amount</label>
                <input
                  type="number"
                  name='amount'
                  id="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  className="bg-gray-800 text-white py-2 px-4 rounded-md w-full"
                  placeholder={`Enter amount`}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <p className="text-red-500 text-sm mt-1">{formik.errors.amount}</p>
                )}
              </div>

            </div>
          </div>
          <motion.button
            className={`bg-green-600 py-2 hover:bg-green-700 text-white  px-8 rounded-lg block shadow-md ${isWithdrawalPending ? 'cursor-not-allowed opacity-50' : ''}`}
            type="submit"
            disabled={isWithdrawalPending || formik.isSubmitting}
          >
            {isWithdrawalPending ? 'Withdrawal Pending...' : 'Withdraw'}
          </motion.button>

          {/* Submit Button */}

        </form>
        <div className="bg-gray-600 text-white p-4 mt-4 rounded-lg">
          <p className="font-medium">Notice:</p>
          <p className="text-sm mt-2">
            {/* Please ensure you enter a valid blockchain address for withdrawal. Entering an incorrect address may result in permanent loss of your funds.
            Withdrawal may take up to 30 minutes to process. */}
            Please fill the amount and submit the withdrawal request. After verification, the amount will be transferred to your wallet address <span className='font-bold text-green-500'>{userData?.address}</span>.
          </p>
        </div>
      </motion.div>

      {/* Withdrawal History */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal History</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-white">Asset Id</th>
                <th className="px-4 py-2 text-left text-white">Amount</th>
                <th className="px-4 py-2 text-left text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalHistory.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-4 py-2 text-center text-gray-400">No withdrawal history available</td>
                </tr>
              ) : (
                withdrawalHistory.map((withdrawal) => (
                  <tr key={withdrawal.id}>
                    <td className="px-4 py-2 text-white">{withdrawal.assetId}</td>
                    <td className="px-4 py-2 text-white">{withdrawal.amount}</td>
                    <td className="px-4 py-2 text-white">{withdrawal.status}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </motion.div>
    </div>
  );
};

export default WithdrawalPage;
