// frontend/src/components/Loader.js

import React from 'react';

function Loader({ className }) {
  return (
    <div className="loader flex justify-center items-center">
      <div className={`"spinner-border animate-spin inline-block ${className ? className : 'w-8 h-8'} border-4 rounded-full"`} />
    </div>
  );
}

export default Loader;
