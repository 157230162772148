// slices/userData.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../Config/axios';
import { useEffect } from 'react';

const BASEURL = process.env.REACT_APP_API_URL;

export const fetchUserDate = createAsyncThunk('balance/fetchUserDate', async () => {
  const response = await axiosInstance.get(`${BASEURL}/auth/refresh-token`);
  return response.data.data;
});
 


const userData = createSlice({
  name: 'UserData',
  initialState: {
    data: '',
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchUserDate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchUserDate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userData.reducer;
