import React, { useState } from 'react'
import ForwardIcon from '@mui/icons-material/Forward';
const ShowDownAction = ({ handleSelectedCardSubmit }) => {

  const [isSelected, setIsSelected] = useState(false);


  const handleSubmit = () => {
    setIsSelected(true);
    handleSelectedCardSubmit().then(() => {
      setIsSelected(false);
    }).catch(() => {
      setIsSelected(false);
    })
  }

  if (isSelected) return null

  return (
    <div className='flex flex-col justify-center items-center mt-2'>
      <span className='font-bold text-white flex flex-row justify-center items-center'>
         <span className='text-xl '>Select any 5 cards to show down</span> <span className='ml-2 text-xs'> (Click on cards to select)</span>
      </span> 

      <button onClick={handleSubmit} className='bg-green-500 text-white font-bold px-4 py-0.5 rounded-lg mt-4 text-xs'>Show Down <ForwardIcon /></button>
    </div>
  )
}

export default ShowDownAction
