import React from 'react'
import MenuActions from '../MenuActions'
import Player from './Player'
import { useSelector } from 'react-redux'
import { Slide, toast } from 'react-toastify'
import ToastContent from '../../../../../CommonComponent/Toast'
import axiosInstance from "../../../../../Config/axios"
import hotToast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import StartGameAction from "./StartGameAction"
import TableSideImage from '../../../../../assets/img/games/seven-twenty-seven/table_side.png'

const WaitingForPlayers = ({ gameInfo, socket, fetchGameInfo }) => {

  const userData = useSelector(state => state.auth.userData)

  const navigate = useNavigate()

  const joinedPlayers = gameInfo?.players || []
  const minPlayers = gameInfo?.minPlayers
  const createdBy = gameInfo?.createdBy

  const myProfile = joinedPlayers.find(player => player.userId == userData?.id)
  const otherPlayers = joinedPlayers.filter(player => player.userId != userData?.id)


  let allPlayers = []
  if (myProfile) {
    allPlayers = [myProfile, ...otherPlayers]
  } else {
    allPlayers = otherPlayers
  }


  const getClassName = (index) => {
    if (index == 0) {
      return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
    }
    if (index == 1) {
      return `absolute -bottom-0 left-1/4  -translate-x-1/2`;
    }
    if (index == 2) {
      return `absolute left-0 bottom-1/4 translate-x-1/4`;
    }
    if (index == 3) {
      return `absolute left-0 top-1/4 translate-x-1/4`;
    }
    if (index == 4) {
      return `absolute top-0 left-1/4 -translate-x-1/4 `;
    }
    if (index == 5) {
      return `absolute top-0 right-1/4 translate-x-1/4 `;
    }
    if (index == 6) {
      return `absolute right-0 top-1/4 -translate-x-1/4`;
    }
    if (index == 7) {
      return `absolute right-0 bottom-1/4 -translate-x-1/4`;
    }
    if (index == 8) {
      return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
    }
  }


  const handleJoinGame = async () => {
    try {
      const bodyData = { gameAddress: gameInfo.gameAddress }
      const response = await axiosInstance.post('/api/game/seven-twenty-seven/join', bodyData)

      const { data: responseData } = response
      if (!responseData.status) {
        toast.error(<ToastContent title="Error" message={responseData.message} />, {
          transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
        })
      } else {
        const data = { slug: 'seven-twenty-seven', gameAddress: gameInfo.gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        hotToast.success('Joined game successfully', { duration: 2000, position: 'bottom-center' })
        if (socket) {
          socket.emit('joinedGame', { userId: userData?.id, roomId: gameInfo.gameAddress })
        }
      }

    } catch (error) {
      toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, transition: Slide })
    }
  }


  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('seven_tws_screen');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }


  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/seven-twenty-seven/leave', { gameAddress: gameInfo.gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        toast.error(<ToastContent title='Error' message={responseData.message} />, {
          transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
        })
      } else {
        navigate('/all-game-page');
      }

    } catch (error) {
      toast.error(<ToastContent title='Error' message={error.message} />, {
        transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
      })
    }
  }


  const minPlayersJoined = allPlayers.length >= minPlayers


  const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


    if (totalPlayers == 9) {
      if (index == 0) {
        return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
      }
      if (index == 1) {
        return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
      }
      if (index == 2) {
        return `absolute left-0 sm:left-5 bottom-1/4 `;
      }
      if (index == 3) {
        return `absolute left-0 sm:left-5 top-1/4 `;
      }
      if (index == 4) {
        return `absolute top-0 left-1/4 -translate-x-1/4 `;
      }
      if (index == 5) {
        return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
      }
      if (index == 6) {
        return `absolute right-0 sm:right-16 top-1/4 `;
      }
      if (index == 7) {
        return `absolute right-0 sm:right-16 bottom-1/4`;
      }
      if (index == 8) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }
      if (index == 9) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }

    }

  };


  let samplePlayers = allPlayers
  if (samplePlayers.length == 2) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 3) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 4) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 5) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 6) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
  } else if (samplePlayers.length == 7) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
  } else if (samplePlayers.length == 8) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    const eighthPlayer = samplePlayers[7];
    samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
  } else {
    samplePlayers = samplePlayers;
  }

  return (
    <>
      {false && <div id='seven_tws_screen' className='relative game  flex flex-col items-center h-full'>
        <MenuActions isInGame={myProfile} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} gameInfo={gameInfo} />
        <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-20 container rounded-full '>
          <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

            <div className="  w-full h-full rounded-full border-2" style={{
              backgroundColor: '#222222'
            }}>
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                {!myProfile && <button onClick={handleJoinGame} className='text-xs bg-white text-black font-extrabold px-5 py-2 btn text-center mt-5 p-2 rounded-lg'>
                  Join Game
                </button>}

              </div>
              {allPlayers.map((player, index) => {
                return (
                  <div key={index} className={getClassName(index)}>
                    <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                      <Player isWaiting={true} player={player} />
                    </div>
                  </div>
                );

              })}
            </div>
          </div>
        </div>
        {gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {myProfile && !minPlayersJoined && (gameInfo?.createdBy === userData?.id) && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!myProfile && (
              <button
                onClick={handleJoinGame}
                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                Join Game
              </button>
            )}
            {myProfile && minPlayersJoined && (gameInfo?.createdBy === userData?.id
              ? <StartGameAction fetchGameInfo={fetchGameInfo} />
              : <span className='text-white font-bold '>
                {`Waiting to start the game`}
              </span>
            )}
          </div>
        </div>}

        {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {myProfile && !minPlayersJoined && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!myProfile && (
              <button
                onClick={handleJoinGame}
                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                Join Game
              </button>
            )}
            {minPlayersJoined && <StartGameAction fetchGameInfo={fetchGameInfo} />}
          </div>
        </div>}
      </div>}

      <div id='seven_tws_screen' className="bg-gradient-to-br from-[#0c0016] via-[#0c0016] to-[#0c0016] h-screen w-full mx-auto p-2 pt-5 ">
        <div className='p-0 mx-auto mt-1 px-0 absolute top-0 left-0 bg-transparent font-extrabold flex w-full items-center justify-between'>
          <MenuActions isInGame={myProfile} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} gameInfo={gameInfo} />
        </div>
        <div className="relative w-full h-[80vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 poker_table_golden"
        // style={{
        //   backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
        //   backgroundSize: 'cover',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'center',
        //   boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
        // }} 
        >


          {/* <div className=" h-full bg-[#0d5b8e] border-1 rounded-full shadow-xl border-gray-400" style={{ */}
          <div className=" h-full bg-[#0d5b8e] border-1 rounded-full shadow-xl border-gray-400" style={{
            boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`,
            background: 'rgb(9,37,58)',
            background: 'linear-gradient(90deg, rgba(9,37,58,1) 0%, rgba(12,70,108,1) 18%, rgba(11,82,128,1) 41%, rgba(11,82,128,1) 64%, rgba(12,70,108,1) 84%, rgba(9,37,58,1) 99%)'
          }}>

            <div className="absolute    md:block bottom-0 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90 ">
              <img src={TableSideImage} className='h-56 w-30' />
            </div>
            <div className="absolute    md:block bottom-0 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90">
              <img src={TableSideImage} className='h-56 w-30' />
            </div>


            <div className="absolute md:block top-1/2 left-1/2 transform -translate-y-1/4 -translate-x-1/2 text-slate-700 text-2xl font-bold opacity-70">
              <span className='text-4xl md:text-6xl font-extrabold select-none text-white font-banger'
                style={{
                  textShadow: `1px 1px white, -1px -1px #444`
                }}
              >7/27 Poker</span>
            </div>


            {samplePlayers.map((player, index) => {
              return <div id={`stws_player_${player?.userId}`} className={`absolute ${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)} }`}>
                <Player key={index} player={player}
                  position={player?.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, samplePlayers.length)} /></div>
            })}



          </div>

        </div>
        <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full text-center text-xs'>
            {myProfile && !minPlayersJoined && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!myProfile && <button onClick={handleJoinGame} className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none  text-black font-bold px-4 py-3 rounded-lg shadow-lg w-full md:w-1/2'>
              Join Game
            </button>}
            {(minPlayersJoined && createdBy == userData?.id) && <StartGameAction fetchGameInfo={fetchGameInfo} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default WaitingForPlayers
