import React from 'react'
import LoopIcon from '@mui/icons-material/Loop';

const ReverseCardModal = ({ card }) => {
    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-70">
            <div className="animated_round scale-150 text-white rounded-lg max-w-md relative flex items-center justify-center">
                <LoopIcon fontSize='100' className=' text-white text-9xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
            </div>
        </div>
    )
}

export default ReverseCardModal
