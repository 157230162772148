import React from 'react'
import Card from '../../Cards/index'
import { IoMdHeart } from "react-icons/io";
import { ImSpades } from "react-icons/im";
import { BsDiamondFill } from "react-icons/bs";
import { GiClubs } from "react-icons/gi";

const CommunityCards = ({ showResult, card, gameResult }) => {


  const highlightCard = (card) => {
    if (!showResult) return false
    if (!gameResult) return false;
    if (gameResult?.isAllIn) return false
    return false
  }

  const CardIcon = ({suit}) => {
    if (suit == 'H') return <IoMdHeart className='text-[#aea306] text-sm sm:text-xl md:text-2xl lg:text-3xl' fontSize={16} />
    if (suit == 'S') return <ImSpades className='text-[#aea306] text-sm sm:text-xl md:text-2xl lg:text-3xl' fontSize={16} />
    if (suit == 'D') return <BsDiamondFill className='text-[#aea306] text-sm sm:text-xl md:text-2xl lg:text-3xl' fontSize={16} />
    if (suit == 'C') return <GiClubs className='text-[#aea306] text-sm sm:text-xl md:text-2xl lg:text-3xl' fontSize={16} />
  }


  return (
    <div className='absolute w-5/6 sm:w-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 grid grid-cols-5 grid-rows-1 gap-1 '>
      {card.map((item, index) => {
        return <div key={index} className=" font-extrabold bg-[#000000] relative shadow-[#aea306] cursor-pointer hover:scale-110 h-16 w-10 md:w-16 md:h-24 rounded-md sm:rounded-md flex items-center justify-center  border-2 border-[#FFD700] mx-auto fade-in-up pvp_casino_card"        > 
            <span className='text-[#aea306] font-extrabold absolute right-1 bottom-1'>{item['Rank']}</span>
            <span className='text-[#aea306] font-extrabold absolute top-1 left-1'>{item['Rank']}</span>
            <span className='text-[#aea306] font-extrabold absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'><CardIcon suit={item['Suit']}/></span>
        </div>
      })}
    </div>
  )

}

export default CommunityCards