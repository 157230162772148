import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import BlurOnIcon from '@mui/icons-material/BlurOn';
import Card from "../Common/Cards/index"
import AddIcon from '@mui/icons-material/Add';
import SolToUSD from '../../../../../CommonComponent/SolToUSD'


const Player = ({ player, index, totalPlayers, gameStatus, foldedPlayers, leftPlayers, userBalances, playerCards, currentPlayer, handleToggleCard, selectedCards, timer, gameBetStatus, position }) => {

  const userData = useSelector(state => state.auth.userData)

  const getCardsClassName = () => {

    if (index === 0) return "-top-20 left-1/2 transform -translate-x-1/2"
    if (index === 1) return "left-10 -bottom-1/2 transform translate-x-1/4 -translate-y-1/2"
    if (index === 2) return "left-10 top-1/2 transform -translate-y-1/2 translate-x-1/4"
    if (index === 3) return "left-0 top-1/2 transform -translate-x-1/4 translate-y-1/2"
    if (index === 4) return "right-0 top-1/2 transform translate-x-1/4 translate-y-1/2"
    if (index === 5) return "right-10 top-1/2 transform -translate-y-1/2 -translate-x-1/4"
    if (index === 6) return "right-1/2 bottom-1/2 transform -translate-x-1/4 -translate-y-1/2"

  }

  const currentPlayerCards = playerCards?.find(playerCard => playerCard.userId === player.userId)?.cards || []

  const playerBalance = userBalances?.find(userBalance => userBalance?.userId === player.userId) || {}


  const isFolded = foldedPlayers.includes(player.userId)
  const isLeft = leftPlayers.includes(player.userId)
  const isPlayerMove = ((player?.userId == currentPlayer) && gameStatus !== 'starting')


  const isTimer = timer > 0

  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)


    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])


    if (gameStatus === 'starting') return null;
    // if (gameBetStatus.round === 'showdown') return null;
    if (gameBetStatus.round === 'starting') return null;
    if (timeLeft <= 0) return null;

    // return <span className='hi-lo-player__profile absolute rounded-full h-8 w-8 -bottom-4 border-red-800 font-extrabold flex items-center justify-center bg-red-800 text-white shadow-2xl'>
    //   <span className='text-xs'>{timeLeft}</span>
    // </span>

    const calculateDashOffset = () => {
      const circumference = 2 * Math.PI * 45
      const dashOffset = circumference * (1 - timeLeft / 30)
      return dashOffset
    }

    let color = '#55eb34'
    if (timeLeft <= 10) {
      color = 'red'
    } else if (timeLeft <= 20) {
      color = 'yellow'
    }

    return <svg className="z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36" viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="green"
        strokeWidth="6"
        className='z-10 '
      />
      {/* Progress arc */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeDasharray={2 * Math.PI * 45}
        strokeDashoffset={calculateDashOffset()}
        transform="rotate(-90 50 50)"
      />
    </svg>
  }

  const renderTimerMemo = useMemo(() => <RenderTimer />, [timer, gameStatus, gameBetStatus])

  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-2 flex flex-col items-center bg-yellow-600 rounded-full border-2 border-yellow-600`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }

  return (
    <>
      {false && <div className='relative five_card_player flex items-center flex-col'>
        {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
        {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-20 w-36  ${player.active && "active"}`} />}
        {!isFolded && !isLeft && <img src={player?.profileUrl} alt='profile' className={`hi-lo-player__profile h-12 w-12 rounded-full border-2 border-white  ${player.active && "active"}`} />}


        <div className={`${isPlayerMove && "py-2 px-3 bg-green-400 rounded-2xl relative"}`}>
          <div className='relative flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>
            {isTimer && currentPlayer === player.userId && renderTimerMemo}
            <div className='name text-white mb-1 text-xs flex flex-row'>{
              player.userName ? player.userName :
                formatAddress(player.address, 3)
            }
            </div>
            {((player?.userId === userData?.id) && playerBalance.amount) ? <div className='balance text-white text-xs'>({Number(playerBalance.amount).toFixed(2)}) Sol</div> : null}
          </div>
        </div>

        {<div className={`flex items-center justify-center gap-0.5 absolute ${getCardsClassName(index)}`}>
          {gameStatus !== 'starting' && !isFolded && !isLeft && currentPlayerCards.map((card, index) => (
            card['Rank'] ? <span onClick={() => handleToggleCard(index, player.userId)} id={`player_card_${player.userId}_${index}`} role='button' className={`relative fade-in-up flex flex-col items-center justify-between ${(selectedCards.includes(index) && currentPlayer == player?.userId) && 'scale-125'}`} >
              {(player.userId === userData?.id) && (<span className='absolute -top-4 font-semibold w-full text-white text-xs text-center'>{card.isOpen ? 'Up' : 'Down'}</span>)}
              <Card card={card} className={"rounded-sm h-14 w-10"} textSize={'text-xs'} fontSize={12} />
            </span> : <>
              <span id={`player_card_${player.userId}_${index}`} role='button' className={`rounded-sm fade-in-up h-14 w-10 bg-red-500 border-2 flex items-center justify-center`} >
                <BlurOnIcon className='text-white text-2xl ' />
              </span>
            </>
          ))}
        </div>}


      </div>}

      {<div className={`relative flex flex-col items-center `}>

        <div className="relative z-20">
          {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
          {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-20 w-36  ${player.active && "active"}`} />}
          {((!isFolded && !isLeft) && (player?.userId !== userData?.id)) && <img src={player?.profileUrl} alt='profile' className={`hi-lo-player__profile h-12 w-12 rounded-full border-2 border-white  ${player.active && "active"}`} />}


          {isTimer && (currentPlayer === player.userId) && <RenderTimer />}

        </div>
        <div className="relative mt-1 text-xs font-medium px-1 py-1 bg-white text-black rounded-md flex flex-row items-center justify-between">
          {(player?.userId === userData?.id) && <img src={player?.profileUrl} alt='profile' className={`hi-lo-player__profile h-10 w-10 mr-2 rounded-full border-2 border-white  ${player.active && "active"}`} />}

          {player?.userId === userData?.id ? <div className='balance text-black text-xs flex flex-col items-center justify-center'>{Number(playerBalance.amount).toFixed(2)} Sol
            <span>(<SolToUSD amount={Number(playerBalance.amount)} symbol={"USD"} />)</span>
          </div> : <div className='balance text-black text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>}

          {<div className={`flex items-center justify-center gap-0.5 absolute ${getCardsClassName(index)}`}>
            {gameStatus !== 'starting' && !isFolded && !isLeft && currentPlayerCards.map((card, index) => (
              card['Rank'] ? <span onClick={() => handleToggleCard(index, player.userId)} id={`player_card_${player.userId}_${index}`} role='button' className={`relative fade-in-up flex flex-col items-center justify-between ${(selectedCards.includes(index) && currentPlayer == player?.userId) && 'scale-125'}`} >
                {(player.userId === userData?.id) && (<span className='absolute -top-4 font-semibold w-full text-white text-xs text-center'>{card.isOpen ? 'Up' : 'Down'}</span>)}
                <Card card={card} className={"rounded-sm h-14 w-10"} textSize={'text-xs'} fontSize={12} />
              </span> : <>
                <span id={`player_card_${player.userId}_${index}`} role='button' className={`rounded-sm fade-in-up h-14 w-10 bg-gradient-to-tr from-[#FFD700] via-[#aa9414] to-[#FFD700] border-2 flex items-center justify-center`} >
                  <BlurOnIcon className='text-black text-2xl ' />
                </span>
              </>
            ))}
          </div>}


        </div>



      </div>}
    </>
  )
}

export default Player
