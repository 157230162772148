import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import sol from '../../Image/sol.png';

const DropdownComponent = () => {
  const { assets } = useSelector((state) => state.balance);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative w-full" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
      <span className="gap-1 cursor-pointer h-full w-full text-white font-bold rounded-lg text-lg py-2.5 text-center flex items-center justify-center">
        <AccountBalanceWalletIcon className='text-[#FFD700]' /> Wallet
      </span>

      {isDropdownOpen && (
        <div id="userDropdown" className="absolute right-0 mt-2 w-64 bg-[#1A1A1A] rounded-xl shadow-lg overflow-hidden z-20">
          <div className="p-4 text-center border-b border-[#303030]">
            <div className='text-center text-[#FFD700] font-bold text-lg'>Assets</div>
          </div>
          <ul className="py-2">
            {assets?.map((asset, index) => (
              <li key={index} className='flex justify-between items-center px-4 py-2 hover:bg-[#FFD700] hover:text-black transition duration-300'>
                <img
                  src={sol}
                  alt="sol"
                  style={{ height: '20px', width: '20px' }}
                />
                <span className="ml-2 text-white">{asset.assetKey}</span>
                <span className="ml-auto text-white">
                  {asset.chains.map((chain, chainIndex) => (
                    <span key={chainIndex}>{chain.balance}</span>
                  ))}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
