import React, { useEffect, useMemo, useRef, useState } from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { useSelector } from 'react-redux';
import coins from '../../../../../assets/img/games/coins_transparent.png';
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import PotMoney from '../Common/PotMoney';
import PlayerCards from './PlayerCards';
import TableCards from '../Common/TableCards';
import UnoActionButtons from "./UnoActionButtons"
import ShowSkipCardModal from './ShowSkipCardModal';
import ReverseCardModal from './ReverseCardModal';
import SelectColorModal from './SelectColorModal';
import UnoCallButton from './UnoCallButton';
import WildCardFourModal from './WildCardFourModal';
import UnoCallSound from '../../../../../assets/audio/uno/uno_call_sound.mp3';
import wilddrawfourSound from '../../../../../assets/audio/uno/wilddrawfour.mp3';
import drawtwoSound from '../../../../../assets/audio/uno/drawtwo.mp3';
import reverseSound from '../../../../../assets/audio/uno/reverse.mp3';
import skipSound from '../../../../../assets/audio/uno/skip.mp3';
const PlayGame = ({ gameInfo, socket }) => {
    gsap.registerPlugin(Flip);
    const usersRef = useRef([])
    const unoCallSoundAudio = new Audio(UnoCallSound);
    const wilddrawfourSoundAudio = new Audio(wilddrawfourSound);
    const drawtwoSoundAudio = new Audio(drawtwoSound);
    const reverseSoundAudio = new Audio(reverseSound);
    const skipSoundAudio = new Audio(skipSound);

    const [gameStatus, setGameStatus] = useState(gameInfo?.round || 'starting')
    const [showSkipCardModal, setShowSkipCardModal] = useState(null)
    const [wildCardFourModal, setWildCardFourModal] = useState(null)
    const [reverseCardModal, setReverseCardModal] = useState(null)
    const [selectedCards, setSelectedCards] = useState([])
    const [selectColorModal, setSelectColorModal] = useState(null)
    const [showUnoCallButton, setShowUnoCallButton] = useState(false)
    const [showUnoCallImage, setShowUnoCallImage] = useState(null)

    const { gameAddress } = useParams()
    const userData = useSelector(state => state.auth.userData)

    const [gameBetStatus, setGameBetStatus] = useState({
        currentPlayer: 0,
        tableCard: {},
        pot: 0,
        foldedPlayers: [],
        leftPlayers: [],
        playerCards: [],
        playersOrder: 'clockwise',
    })

    const [gameMoveStatus, setGameMoveStatus] = useState({
        currentPlayer: 0,
        tableCard: {},
        pot: 0,
        foldedPlayers: [],
        leftPlayers: [],
        playerCards: [],
        userBalances: [],
        timer: 0,
        playersOrder: 'clockwise',

    })

    const allPlayers = gameInfo.players || []

    let allNewPlayers = []
    const myProfile = allPlayers.find(player => player.userId === userData.id)
    if (myProfile) {
        const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
        allNewPlayers = [myProfile, ...otherPlayers]
    } else {
        allNewPlayers = allPlayers
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allNewPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allNewPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allNewPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allNewPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
            if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allNewPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allNewPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allNewPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        } else if (allNewPlayers.length == 10) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/4 transform -translate-x-1/4 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
            if (index == 9) return 'absolute flex flex-col items-center bottom-0 right-1/4 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }

    const servedCardsToPlayers = (playerCard) => {
        const totalPlayers = allPlayers.length;
        const dealer = document.getElementById('stws_pot_money_box');
        const totalRounds = playerCard || 1;
        const dealInterval = 300; // Time between each card deal

        for (let round = 0; round < totalRounds; round++) {
            allPlayers.forEach((_, index) => {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-16 w-10 bg-black border-2 border-gray-900 border border-2 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = index % totalPlayers;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();

                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 0.5,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, round * totalPlayers * dealInterval + index * dealInterval);
            });
        }

    }

    const fetchPlayerBetAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/uno-card-game/bet-status', { gameAddress });

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setGameBetStatus(responseData.data);
            }

        } catch (error) {
            hotToast.error('Error while fetching player bet action', { duration: 3000, position: 'bottom-center' })
        }

    }
    const fetchPlayetMoveAction = async () => {
        try {
            const response = await axiosInstance.post('/api/game/uno-card-game/move-status', { gameAddress });
            const { data: responseData } = response;
            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setGameMoveStatus(responseData.data);
            }
        } catch (error) {
            hotToast.error('Error while fetching player bet action', { duration: 3000, position: 'bottom-center' })
        }

    }


    const animateFlowToPot = (playerId, targetId, playCard) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);
        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        // Create a card element (or image)
        let card = null
        if (playCard) {
            card = document.createElement('span');
            card.className = 'card h-20 w-14 bg-black rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        } else {
            card = document.createElement('img');
            card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
            card.src = coins;
            card.style.top = `${playerRect.top + window.scrollY}px`;
            card.style.left = `${playerRect.left + window.scrollX}px`;
        }

        document.body.appendChild(card);
        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };


    let userBalances = gameBetStatus?.userBalances || []
    let pot = gameBetStatus?.pot || 0
    let maxBet = gameBetStatus?.maxBet || 0
    let foldedPlayers = gameBetStatus?.foldedPlayers || []
    let leftPlayers = gameBetStatus?.leftPlayers || []
    let currentPlayer = gameBetStatus?.currentPlayer || 0
    let playerCards = gameBetStatus?.playerCards || []
    let timer = gameBetStatus?.timer || 30
    let playersOrder = gameBetStatus?.playersOrder || 'clockwise'
    let tableCard = {}
    if (gameStatus == 'playing') {
        currentPlayer = gameMoveStatus?.currentPlayer || 0
        pot = gameMoveStatus?.pot || 0
        foldedPlayers = gameMoveStatus?.foldedPlayers || []
        leftPlayers = gameMoveStatus?.leftPlayers || []
        playerCards = gameMoveStatus?.playerCards || []
        timer = gameMoveStatus?.timer || 30
        tableCard = gameMoveStatus?.tableCard || {}
        userBalances = gameMoveStatus?.userBalances || []
        playersOrder = gameMoveStatus?.playersOrder || 'clockwise'
    }


    const handleToggleCard = (index, playerUserId) => {
        if (gameBetStatus.round != 'showdown') return;
        if (playerUserId !== userData.id) return;
        if (currentPlayer !== userData.id) return;
        if (selectedCards.includes(index)) {
            setSelectedCards(selectedCards.filter(cardIndex => cardIndex !== index))
        } else {
            if (selectedCards.length >= 5) return hotToast.error('You can only select 5 cards', { duration: 2000, position: 'bottom-center' })
            setSelectedCards([...selectedCards, index])
        }
    }

    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('uno_card_table');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }
    const handleLeaveGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo?.gameAddress }
            const response = await axiosInstance.post('/api/game/uno-card-game/leave', bodyData);

            const { data: responseData } = response

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                hotToast.success(responseData.message, { duration: 3000, position: 'bottom-center' })
                window.location.href = '/all-game-page'
            }

        } catch (error) {
            console.log(error)
            hotToast.error('Something went wrong', { duration: 3000, position: 'bottom-center' })
        }

    }

    useEffect(() => {
        socket.on('betPlayed', (data) => {

            const { isBetFinished, userId, isStarted } = data;
            animateFlowToPot(`stws_player_${data.userId}`, 'stws_pot_money_box');

            if (isBetFinished) {
                fetchPlayerBetAction();
                hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                servedCardsToPlayers(7)

                const totalTimeToServeCards = 1000 * allPlayers.length * 2;
                setTimeout(() => {
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                }, totalTimeToServeCards);


            } else if (data.gameStatus == 'betting') {
                if (isStarted) {
                    hotToast.success('All players placed their bets', { duration: 3000, position: 'bottom-center' })
                    setGameStatus('playing');
                    fetchPlayetMoveAction();
                } else {
                    // setGameStatus(data.gameStatus);
                    fetchPlayerBetAction();
                }
            } else {
                setGameStatus('starting');
                fetchPlayerBetAction();
            }
        });

        socket.on('cardPlayed', (data) => {
            const { card, nextPlayer, wildCardPlayer, gameStatus } = data;
            // animateFlowToPot(`current_player_card_${cardIndex}`, 'table_card', card);
            animateFlowToPot(`stws_player_${userData?.id}`, 'table_card', card);

            if (card.value == 'skip') {
                setTimeout(() => {
                skipSoundAudio.play();

                    setShowSkipCardModal(card)
                    setTimeout(() => {
                        setShowSkipCardModal(null)
                        if (gameStatus == 'betting') {
                            hotToast.success('Betting round !!', { duration: 2000, position: 'bottom-center' })
                            setGameStatus('betting');
                            fetchPlayerBetAction();
                        } else {
                            fetchPlayetMoveAction();
                        }
                    }, 1500);
                }, 700);

            } else if (card.value == 'reverse') {
                setTimeout(() => {
                    reverseSoundAudio.play();

                    setReverseCardModal(card)
                    setTimeout(() => {
                        setReverseCardModal(null)
                        if (gameStatus == 'betting') {
                            hotToast.success('Betting round !!', { duration: 2000, position: 'bottom-center' })

                            setGameStatus('betting');
                            fetchPlayerBetAction();
                        } else {
                            fetchPlayetMoveAction();
                        }
                    }, 1500);
                }, 700);
            } else if (card.value == 'drawTwo') {
                const totalNewCard = 2;
                setTimeout(() => {
                drawtwoSoundAudio.play();

                    setTimeout(() => {
                        for (let i = 0; i < totalNewCard; i++) {
                            setTimeout(() => {
                                animateFlowToPot('table_card', `stws_player_${nextPlayer}`, card);
                                if (i == totalNewCard - 1) {
                                    setTimeout(() => {
                                        if (gameStatus == 'betting') {
                                            hotToast.success('Betting round !!', { duration: 2000, position: 'bottom-center' })

                                            setGameStatus('betting');
                                            fetchPlayerBetAction();
                                        } else {
                                            fetchPlayetMoveAction();
                                        }
                                    }, 700);
                                }
                            }, 700 * (i + 1));
                        }
                    }, 700);
                }, 700);
            } else if (card.value == 'wildDrawFour') {
                setWildCardFourModal(card)
                wilddrawfourSoundAudio.play();
                setTimeout(() => {
                    setWildCardFourModal(null)
                    const totalNewCard = 4;
                    setTimeout(() => {
                        setTimeout(() => {
                            for (let i = 0; i < totalNewCard; i++) {
                                setTimeout(() => {
                                    animateFlowToPot('table_card', `stws_player_${wildCardPlayer}`, card);
                                    if (i == totalNewCard - 1) {
                                        setTimeout(() => {
                                            if (gameStatus == 'betting') {
                                                hotToast.success('Betting round !!', { duration: 2000, position: 'bottom-center' })

                                                setGameStatus('betting');
                                                fetchPlayerBetAction();
                                            } else {
                                                fetchPlayetMoveAction();
                                            }
                                        }, 700);
                                    }
                                }, 700 * (i + 1));
                            }
                        }, 700);
                    }, 700);
                }, 1500);

            } else {
                setTimeout(() => {
                    if (gameStatus == 'betting') {
                        hotToast.success('Betting round !!', { duration: 2000, position: 'bottom-center' })
                        setGameStatus('betting');
                        fetchPlayerBetAction();
                    } else {
                        fetchPlayetMoveAction();
                    }
                }, 700);
            }

        });

        socket.on('deckCardTaken', (data) => {
            const { gameAddress, userId, card, cardIndex } = data;
            animateFlowToPot(`deck_card`, `current_player_card_${cardIndex}`, card);

            setTimeout(() => {
                fetchPlayetMoveAction();
            }, 700);
        });

        socket.on('selectWildCardFourColor', (data) => {
            const { userId, card } = data;
            if (userId !== userData.id) return;
            setSelectColorModal(card);
        })

        socket.on('selectWildCardColor', (data) => {
            const { userId, card } = data;
            if (userId !== userData.id) return;
            setSelectColorModal(card);
        });

        socket.on('unoCall', (data) => {
            const { gameAddress, userId, timeout } = data;
            const userData = JSON.parse(localStorage.getItem('userData'));
            if (userId !== userData.id) return;
            setShowUnoCallButton(true)
            setTimeout(() => {
                setShowUnoCallButton(false)
            }, timeout * 1000);
        });

        socket.on('unoCallTimeout', (data) => {
            const { gameAddress, userId, cards } = data;

            for (let i = 0; i < cards; i++) {
                setTimeout(() => {
                    animateFlowToPot('table_card', `stws_player_${userId}`, { value: 'uno', color: 'black' });
                }, (700 * (i + 1)));
            }

            setTimeout(() => {
                fetchPlayetMoveAction()
            }, 700 * cards);

        });

        socket.on('unoCalled', (data) => {
            unoCallSoundAudio.play();
            setShowUnoCallImage(data?.userId)
            setTimeout(() => {
                setShowUnoCallImage(null)
            }, 1000);

        });

        socket.on('opponentLeft', (data) => {
            fetchPlayetMoveAction();
        });

        socket.on('playerTimeout', (data) => {
            fetchPlayetMoveAction();
        });

        return () => {
            socket.off('betPlayed');
            socket.off('cardPlayed');
            socket.off('deckCardTaken');
            socket.off('selectWildCardFourColor');
            socket.off('selectWildCardColor');
            socket.off('unoCall');
            socket.off('unoCallTimeout');
            socket.off('unoCalled');
        }

    }, [gameInfo])


    useEffect(() => {
        if (gameStatus === 'starting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'betting') {
            fetchPlayerBetAction();
        }
        if (gameStatus === 'playing') {
            fetchPlayetMoveAction();
        }
    }, [gameInfo])


    const dealerPosition = gameInfo?.dealerPosition || 0

    const dealerUserId = gameInfo['players'][dealerPosition]?.userId || 0

    let currentPlayerCards = []
    let isNoCardPlayable = true

    if (myProfile) {
        const playerCard = playerCards.find(playerCard => playerCard.userId === myProfile.userId)
        if (playerCard) {
            currentPlayerCards = playerCard.cards
        }
        let colors = currentPlayerCards.map(card => card.color)
        let values = currentPlayerCards.map(card => card.value)


        if (values.includes('wild') || values.includes('wildDrawFour')) {
            isNoCardPlayable = false
        } else {
            if (tableCard && tableCard['color'] && tableCard['value']) {
                isNoCardPlayable = !colors.includes(tableCard['color']) && !values.includes(tableCard['value'])
            }
        }
    }


    if (currentPlayer != userData.id) {
        isNoCardPlayable = false
    }


    const handleColorSubmit = async (color) => {
        try {
            const bodyData = { gameAddress, color, card: { ...selectColorModal, color: color } }
            const response = await axiosInstance.post('/api/game/uno-card-game/play-card', bodyData);

            const { data: responseData } = response;

            if (!responseData.status) {
                hotToast.error(responseData.message, { duration: 3000, position: 'bottom-center' })
            } else {
                setSelectColorModal(null)
            }


        } catch (error) {
            console.log(error)
            hotToast.error('Failed to submit color', { duration: 3000, position: 'bottom-center' })
        }
    }



    return (
        <>
            <div id='uno_card_table' className='relative game  flex flex-col items-center h-full container'>
                <MenuActions isInGame={myProfile} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
                <div className='relative h-[60%] p-4 md:p-3 lg:p-4  mt-10 container rounded-full poker_table_golden '>
                    {/* <div className=" p-2 h-full rounded-full border-2 bg-slate-900"> */}
                        <div className="h-full rounded-full border-2" style={{
                            backgroundColor: '#222222'
                        }}>
                            <div id='stws_pot_money_box' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                {(gameStatus != 'playing') && <PotMoney pot={Number(pot).toFixed(2)} />}
                                {(gameStatus == 'playing') && <TableCards playersOrder={playersOrder} isNoCardPlayable={isNoCardPlayable} card={tableCard} />}
                            </div>
                            {allNewPlayers.map((player, index) => {
                                return (
                                    <div key={index} className={getClassName(index)} >
                                        <div className='stws-lo-player flex items-center flex-col shadow-2xl'>
                                            <Player showUnoCallImage={showUnoCallImage} usersRef={usersRef} handleToggleCard={handleToggleCard} selectedCards={selectedCards} showDealer={dealerUserId === player.userId} foldedPlayers={foldedPlayers} leftPlayers={leftPlayers} userBalances={userBalances} playerCards={playerCards}
                                                gameBetStatus={gameBetStatus} pot={pot} maxBet={maxBet} gameStatus={gameStatus} totalPlayers={allNewPlayers.length} index={index} key={player.userId} player={player} currentPlayer={currentPlayer} timer={timer} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    {/* </div> */}


                </div>
                {(gameStatus == 'playing') && <div className='absolute bottom-24 h-auto flex w-full items-center justify-center'>
                    <PlayerCards cards={currentPlayerCards} tableCard={tableCard} />
                </div>}

                {(gameStatus == 'betting') && <div className='absolute bottom-24 h-auto flex w-full items-center justify-center'>
                    <UnoActionButtons maxBet={maxBet} pot={pot} gameStatus={gameStatus} gameBetStatus={gameBetStatus} />
                </div>}

                <div className='absolute z-50 bottom-8 left-1/2 w-full  -translate-x-1/2 flex items-center justify-center'>
                    {showUnoCallButton && <UnoCallButton />}
                </div>


            </div>
            {wildCardFourModal && <WildCardFourModal card={wildCardFourModal} />}
            {showSkipCardModal && <ShowSkipCardModal card={showSkipCardModal} />}
            {reverseCardModal && <ReverseCardModal />}
            {selectColorModal && <SelectColorModal handleColorSubmit={handleColorSubmit} />}
        </>
    )
}

export default PlayGame
