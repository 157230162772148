import React from 'react'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import BustImage from '../../../../../assets/img/busted.png'
import { formatAddress, getCardsTotal } from '../../../../../Config/function'
import Card from '../ common/index'
import AddIcon from '@mui/icons-material/Add';
import { FaEye } from "react-icons/fa";

const Player = ({ player, totalPlayers, index, position, handleShowCards }) => {

  const newPlayerCard = player.cards || []



  const RenderCardTotal = ({ cards }) => {
    const aceCard = cards.filter((card) => card.Rank === 'A');
    const otherCards = cards.filter((card) => card.Rank !== 'A');

    let total = getCardsTotal(otherCards);

    if (aceCard && aceCard.length > 0) {
      return `${total + aceCard.length * 1} or ${total + aceCard.length * 11}`
    }

    return total

  }

  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }

  return (
    <div className={`hi-lo-player flex items-center flex-col `}>
      <div className=' cards flex flex-row items-center justify-between gap-2 mb-0 relative'>
        {player.isFolded && <img src={FoldImage} alt='fold' className='w-full h-12' />}
        {player.isLeft && !player.isFolded && <img src={LeftImage} alt='left' className='w-28 h-12' />}
        {player.isBusted && <img src={BustImage} alt='busted' className='w-28 h-12' />}

        {/* {!player.isBusted && !player.isFolded && !player.isLeft && <div className={` flex items-center justify-center gap-0.5 ${getCardsClassName(index)}`}>
          {newPlayerCard.map((card, index) => {
            return (
              <Card fontSize={12} textSize={'text-xs'} className={'fade-in-up h-14 w-9 rounded-xs'} key={index} card={card} />
            )
          })}
        </div>} */}
      </div>
      {/* <div className={`flex flex-row items-center justify-center bg-black font-extrabold px-2 text-center border-2 rounded-md `}>
        {<img src={player?.profileUrl} alt='left' className='w-9 h-9 mr-2 rounded-full' />}
        <div className='flex flex-col items-start  name text-white mb-1 text-xs'>
          <span>{player.userName ? player.userName : formatAddress(player.address, 3)}</span>
          Total: <RenderCardTotal cards={newPlayerCard} />
        </div>
      </div> */}
      {<img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${player.active && "active"}`} />}
      <div onClick={() => handleShowCards(newPlayerCard)}  className='flex flex-col items-center font-bold text-xs justify-center bg-red-600 text-white px-3 text-center border-2 rounded-md'>
        {player.userName ? player.userName : formatAddress(player.address, 3)} {" "}
        <span className='flex items-center justify-center w-full'>
          <FaEye className='inline mr-2' />
          Total: <RenderCardTotal cards={newPlayerCard} />
        </span>
      </div>
    </div>
  )
}

export default Player
