import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MenuActions from '../MenuActions'
import Player from './Player'
import hotToast from 'react-hot-toast'
import axiosInstance from '../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import ToastContent from '../../../../../CommonComponent/Toast'
import { formatAddress, isEmpty } from '../../../../../Config/function'
import SolToUSD from '../../../../../CommonComponent/SolToUSD'
import PlayerShowCardsModal from './PlayerShowCardsModal'
import TableSideImage from '../../../../../assets/img/games/seven-twenty-seven/table_side.png'

const GameResult = ({ gameInfo, socket, fetchGameInfo }) => {
    const userData = useSelector(state => state.auth.userData)

    const { result } = gameInfo
    const [showCards, setShowCards] = useState([])

    let players = []
    let pot = 0
    let winner = null
    if (result) {
        const resultData = JSON.parse(result)
        players = resultData.players
        pot = resultData.pot
        winner = resultData.winner
    }


    let allPlayers = players || []

    const myProfile = players.find(player => player.userId === userData?.id)

    const otherPlayers = players.filter(player => player.userId !== userData?.id)

    if (myProfile) {
        allPlayers = [myProfile, ...otherPlayers]
    } else {
        allPlayers = otherPlayers
    }

    // }
    const handleJoinGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo.gameAddress }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/join', bodyData)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                const data = { slug: 'seven-twenty-seven', gameAddress: gameInfo.gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
                hotToast.success('Joined game successfully', { duration: 2000, position: 'bottom-center' })
                if (socket) {
                    socket.emit('joinedGame', { userId: userData?.id, roomId: gameInfo.gameAddress })
                }
            }

        } catch (error) {
            toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, transition: Slide })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('seven_tws_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }

    const RenderGameWinner = () => {
        if (!winner) return null;

        // const { profileUrl, address } = winner

        let winnerData = []


        for (let i = 0; i < winner.length; i++) {
            if (winnerData.findIndex(item => item.userId === winner[i].userId) === -1) {
                winnerData.push(winner[i])
            } else {
                let player = winnerData.find(item => item.userId === winner[i].userId)
                let playerIndex = winnerData.findIndex(item => item.userId === winner[i].userId)

                winnerData[playerIndex] = {
                    ...player,
                    amount: player.amount + winner[i].amount
                }
            }
        }




        return (
            <div className='flex flex-row items-center justify-between w-full container gap-11'>
                {winnerData.map(item => {
                    return <div className='flex flex-col items-center justify-center'>
                        <span className='text-white font-bold text-2xl mt-2 mb-2'>Winner</span>
                        <div className='hi-lo-player flex items-center flex-col	'>
                            {<img src={item.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-12 w-12 border border-white p-1/2 hi_lo_player__profile_active`} />}
                            <span className='hi-lo-player__balance text-white font-bold mt-3'>{item.userName ? item.userName : formatAddress(item.address, 6)}</span>
                            <div className='flex items-center justify-center flex-col text-white'>
                                <span className='text-sm'>{Number(item.amount).toFixed(2)} Sol</span>
                                <span className='text-xs'>
                                    (<SolToUSD amount={item.amount} symbol={'USD'} />)
                                </span>
                            </div>

                        </div>
                    </div>
                })}
            </div>
        )

    }

    const getClassName = (index) => {
        if (index == 0) {
            return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
        }
        if (index == 1) {
            return `absolute -bottom-0 left-1/4  -translate-x-1/2`;
        }
        if (index == 2) {
            return `absolute left-0 bottom-1/4 translate-x-1/4`;
        }
        if (index == 3) {
            return `absolute left-0 top-1/4 translate-x-1/4`;
        }
        if (index == 4) {
            return `absolute top-0 left-1/4 -translate-x-1/4 `;
        }
        if (index == 5) {
            return `absolute top-0 right-1/4 translate-x-1/4 `;
        }
        if (index == 6) {
            return `absolute right-0 top-1/4 -translate-x-1/4`;
        }
        if (index == 7) {
            return `absolute right-0 bottom-1/4 -translate-x-1/4`;
        }
        if (index == 8) {
            return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
        }
    }

    const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


        if (totalPlayers == 9) {
            if (index == 0) {
                return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
            }
            if (index == 1) {
                return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
            }
            if (index == 2) {
                return `absolute left-0 sm:left-5 bottom-1/4 `;
            }
            if (index == 3) {
                return `absolute left-0 sm:left-5 top-1/4 `;
            }
            if (index == 4) {
                return `absolute top-0 left-1/4 -translate-x-1/4 `;
            }
            if (index == 5) {
                return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
            }
            if (index == 6) {
                return `absolute right-0 sm:right-16 top-1/4 `;
            }
            if (index == 7) {
                return `absolute right-0 sm:right-16 bottom-1/4`;
            }
            if (index == 8) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }
            if (index == 9) {
                return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
            }

        }

    };


    let samplePlayers = allPlayers
    if (samplePlayers.length == 2) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 3) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 4) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 5) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
    } else if (samplePlayers.length == 6) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
    } else if (samplePlayers.length == 7) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
    } else if (samplePlayers.length == 8) {
        const firstPlayer = samplePlayers[0];
        const secondPlayer = samplePlayers[1];
        const thirdPlayer = samplePlayers[2];
        const fourthPlayer = samplePlayers[3];
        const fifthPlayer = samplePlayers[4];
        const sixthPlayer = samplePlayers[5];
        const seventhPlayer = samplePlayers[6];
        const eighthPlayer = samplePlayers[7];
        samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
    } else {
        samplePlayers = samplePlayers;
    }

    console.log({ allPlayers })
    const handleShowCards = (cards) => {
        setShowCards(cards)
    }

    return (
        <>
            {false && <div id='seven_tws_screen' className='relative game  flex flex-col items-center container h-full'>
                <MenuActions isInGame={false} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
                <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-3 rounded-full '>
                    <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

                        <div className="  w-full h-full rounded-full border-2 container relative" style={{
                            backgroundColor: '#222222'
                        }}>
                            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                <RenderGameWinner />
                            </div>
                            {allPlayers.map((player, index) => {
                                return (
                                    <div key={index} className={getClassName(index)}>
                                        <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                            <Player totalPlayers={allPlayers.length} index={index} isWaiting={true} player={player} />
                                        </div>
                                    </div>
                                );

                            })}
                        </div>
                    </div>
                </div>
                <div className='absolute bottom-5 text-white left-1/2 transform -translate-x-1/2'>
                    <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'> Press here to play/continue... </button>
                </div>
            </div>}


            <div id='seven_tws_screen' className=" bg-gradient-to-br from-[#0c0016] via-[#0c0016] to-[#0c0016] h-screen w-full mx-auto p-2 pt-5 ">
                <div className='p-0 mx-auto mt-1 px-0 absolute top-0 left-0 bg-transparent font-extrabold flex w-full items-center justify-between'>
                    <MenuActions isInGame={false} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
                </div>
                <div className="container relative w-full h-[80vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 poker_table_golden"
                // style={{
                //     backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
                //     backgroundSize: 'cover',
                //     backgroundRepeat: 'no-repeat',
                //     backgroundPosition: 'center',
                //     boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
                // }}
                >

                    <div className="absolute    md:block bottom-0 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90 ">
                        <img src={TableSideImage} className='h-56 w-30' />
                    </div>
                    <div className="absolute    md:block bottom-0 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-90">
                        <img src={TableSideImage} className='h-56 w-30' />
                    </div>


                    <div className=" h-full border-1 rounded-full shadow-xl border-gray-400" style={{
                        boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`,
                        background: 'rgb(9,37,58)',
                        background: 'linear-gradient(90deg, rgba(9,37,58,1) 0%, rgba(12,70,108,1) 18%, rgba(11,82,128,1) 41%, rgba(11,82,128,1) 64%, rgba(12,70,108,1) 84%, rgba(9,37,58,1) 99%)'
                    }}>
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <RenderGameWinner />
                        </div>

                        {samplePlayers.map((player, index) => {
                            return <div id={`stws_player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)} }`}>
                                <Player handleShowCards={handleShowCards} index={index} isWaiting={true} player={player} totalPlayers={samplePlayers.length} winner={winner} key={index}
                                    position={player?.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, samplePlayers.length)} /></div>
                        })}
                    </div>
                </div>

                <div className='absolute bottom-2 left-1/2 transform -translate-x-1/2 w-max'>
                    <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] disabled:cursor-not-allowed hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5 font-bold px-5 rounded-lg'>Press here to play/continue... </button>
                </div>
            </div>
            {showCards && <PlayerShowCardsModal show={!isEmpty(showCards)} handleClose={() => setShowCards([])} cards={showCards} />}

        </>
    )
}

export default GameResult
