import "../../../../assets/css/custom.css";

import React from 'react'
import Home from './Components/Pages/Home';


const PlayPoker = ({ showResult, gameInfo, socket, fetchGameInfo }) => {
    return (
        <div>
            <Home showResult={showResult} fetchGameInfo={fetchGameInfo} gameInfo={gameInfo} socket={socket} />
        </div>
    )
}

export default PlayPoker