import React, { useState, useEffect } from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { useSelector } from 'react-redux'
import hotToast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../Config/axios'
import SolToUSD from '../../../../../CommonComponent/SolToUSD'
import { formatAddress, isEmpty } from '../../../../../Config/function'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import coins from '../../../../../assets/img/games/coins_yellow_transparent.png';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import PlayerShowCardsModal from './PlayerShowCardsModal'
import Banner from "../../../../../assets/img/games/poker_background_image.png";

const GameResult = ({ gameInfo, fetchGameInfo }) => {
  gsap.registerPlugin(Flip);

  const { gameAddress } = useParams()
  const navigate = useNavigate()
  const userData = useSelector(state => state.auth.userData)
  const [showCards, setShowCards] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const { result: gameResult } = gameInfo || {}

  const allPlayers = gameResult?.players || []
  const winner = gameResult?.winner || {}


  const myProfile = allPlayers.find(player => player.userId === userData.id)
  let allNewPlayers = []
  if (myProfile) {
    const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
    allNewPlayers = [myProfile, ...otherPlayers]
  } else {
    allNewPlayers = allPlayers
  }


  const getClassName = (index) => {
    if (index == 0) {
      return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
    }
    if (index == 1) {
      return `absolute bottom-1/4 left-0 -translate-y-1/4`;
    }
    if (index == 2) {
      return `absolute left-0 top-1/4 -translate-y-1/4`;
    }
    if (index == 3) {
      return `absolute top-0 left-1/4`;
    }
    if (index == 4) {
      return `absolute top-0 right-1/4 `;
    }
    if (index == 5) {
      return `absolute top-1/4 right-0 -translate-x-1/4`;
    }
    if (index == 6) {
      return `absolute right-0 bottom-1/4 -translate-x-1/4`;
    }
    if (index == 7) {
      return `absolute right-0 bottom-0 -translate-x-1/4`;
    }
  }


  const handleJoinGame = async () => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.post('/api/game/7-card-poker/join', { gameAddress })


      const { data: responseData } = response

      if (!responseData.status) {
        setIsLoading(false)
        hotToast.error(responseData.message)
      } else {
        setIsLoading(false)
        const data = { slug: '7-card-poker', gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        hotToast.success('Joined', { position: 'bottom-center' })
      }

    } catch (error) {
      setIsLoading(false)
      hotToast.error('Error in joining game');
    }
  }

  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/7-card-poker/leave', { gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message)
      } else {
        navigate('/all-game-page')
      }

    } catch (error) {
      hotToast.error('Error in leaving game')
    }
  }

  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('five_card_poker_screen');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }

  const RenderGameWinner = () => {
    if (!winner) return null;

    // const { profileUrl, address } = winner
    return (
      <div className='flex flex-row items-center justify-between w-full container gap-11'>
        {[winner].map(item => {
          return <div className='flex flex-col items-center justify-center'>
            <span className='text-white font-bold text-2xl mt-2 mb-2'>Winner</span>
            <div className='hi-lo-player flex items-center flex-col	'>
              {<img src={item.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-12 w-12 border border-white p-1/2 hi_lo_player__profile_active`} />}
              <span className='hi-lo-player__balance text-white font-bold mt-3'>{item.userName ? item.userName : formatAddress(item.address, 6)}</span>
              <div className='flex items-center justify-center flex-row text-white'>
                <span className='text-sm'>{Number(item.amount).toFixed(2)} Sol</span>
                <span className='text-xs ml-2'>
                  (<SolToUSD amount={item.amount} symbol={'USD'} />)
                </span>
              </div>

            </div>
          </div>
        })}
      </div>
    )

  }


  const animateFlowToPot = (playerId, targetId, isCard) => {
    const playerElement = document.getElementById(playerId);
    const targetElement = document.getElementById(targetId);
    if (!playerElement || !targetElement) return;

    const playerRect = playerElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    // Create a card element (or image)
    let card = null

    if (isCard) {
      card = document.createElement('span');
      card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    } else {
      card = document.createElement('img');
      card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
      card.src = coins;
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    }

    document.getElementById('seven_card_poker_screen').appendChild(card);
    const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
    const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

    gsap.to(card, {
      duration: 1.3,
      x: x,
      y: y,
      onComplete: () => {
        card.remove();
      }
    });
  };

  const showCoinAnimation = () => {
    try {
      if (!gameResult) return
      const { players } = gameResult
      if (!players) return

      const winnerPlayer = players.filter(player => player.type === 'deposit')
      const otherPlayers = players.filter(player => player.type === 'withdrawal')
      for (let i = 0; i < otherPlayers.length; i++) {
        let player = otherPlayers[i]
        for (let j = 0; j < winnerPlayer.length; j++) {
          let winner = winnerPlayer[j]
          animateFlowToPot(`seven_card_result_player_${player.userId}`, `seven_card_result_player_${winner.userId}`, false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showCoinAnimation()
  }, [])

  if (!gameInfo) return <div>Loading...</div>
  let allLeftExceptWinner = false
  const allFoldedorLeftPlayers = allNewPlayers.filter(player => player.isFolded || player.isLeft)

  if (allFoldedorLeftPlayers.length === allNewPlayers.length - 1) {
    allLeftExceptWinner = true
  }


  const handleShowCards = (cards) => {
    setShowCards(cards)
  }

  const getPlaceHolderPlayerPosition = (index) => {
    if (index == 0) {
      return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
    }
    if (index == 1) {
      return `absolute -left-0 sm:left-5 bottom-1/4 `;
    }
    if (index == 2) {
      return `absolute left-0 sm:left-5 top-1/4 `;
    }
    if (index == 3) {
      return `absolute top-0 left-1/4 -translate-x-1/4 `;
    }
    if (index == 4) {
      return `absolute top-0 right-1/4 translate-x-1/4 `;
    }
    if (index == 5) {
      return `absolute right-0 sm:right-5 top-1/4 `;
    }
    if (index == 6) {
      return `absolute right-0 sm:right-5 bottom-1/4`;
    }

  };

  let samplePlayers = allNewPlayers

  if (samplePlayers.length == 2) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 3) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, { fake: true }];
  } else if (samplePlayers.length == 4) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer];
  } else if (samplePlayers.length == 5) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer];
  } else if (samplePlayers.length == 6) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer];
  } else {
    samplePlayers = samplePlayers;
  }




  return (
    <>
      {false && <div id='seven_card_poker_screen' className='relative game  flex flex-col items-center h-full container'>

        <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-5 container rounded-full '>
          <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

            <div className=" w-full h-full rounded-full border-2" style={{
              backgroundColor: '#222222'
            }}>
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                {/* <RenderGameWinner /> */}
              </div>

              {allNewPlayers.map((player, index) => {
                return (
                  <div key={index} className={getClassName(index)}>
                    <div className='hi-lo-player flex items-center flex-col shadow-2xl	' id={`seven_card_result_player_${player.userId}`}>
                      <Player result={gameInfo?.result} allLeftExceptWinner={allLeftExceptWinner} allNewPlayers={allNewPlayers} index={index} totalPlayers={allNewPlayers.length} winner={winner} player={player} />
                    </div>
                  </div>
                );

              })}
            </div>
          </div>
        </div>

        <div className='absolute bottom-5 left-1/2 transform -translate-x-1/2'>
          <button disabled={isLoading} onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5 font-extrabold
      px-5 rounded-lg w-max'> {isLoading && <HourglassEmptyIcon className='animate-spin font-bold' fontSize='14' />} Press here to play/continue... </button>
        </div>
      </div>}

      <div id='seven_card_poker_screen' className="bg-gradient-to-br from-[#0c0016] via-[#0c0016] to-[#0c0016] h-screen w-full mx-auto p-2 pt-5 ">
        <div className='p-0 mx-auto mt-1 px-0 absolute top-0 left-0 bg-transparent font-extrabold flex w-full items-center justify-between'>
          <MenuActions isInGame={false} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo} />
        </div>
        <div className="relative w-full h-[80vh] sm:h-[75vh] lg:h-[70vh]  border-2 border-[#250a3d] rounded-full shadow-xl xs:p-3 sm:p-4 p-6 md:p-6 lg:p-8 poker_table_golden"
        // style={{
        //   backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
        //   backgroundSize: 'cover',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'center',
        //   boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
        // }} 
        >
          <div className="absolute    md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
            <img src={Banner} className='h-32 w-30' />
          </div>
          <div className="absolute    md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
            <img src={Banner} className='h-32 w-30' />
          </div>

          <div className=" h-full bg-[#0e3e03] border-1 rounded-full shadow-xl border-gray-400" style={{
            boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
          }}>



            <div className="absolute md:block top-1/2 left-1/2 transform -translate-y-1/4 -translate-x-1/2 text-slate-700 text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-bold opacity-90">
              <span className=' text-white font-banger'
                style={{
                  textShadow: `1px 1px white, -1px -1px #444`
                }}
              >7 Card Stud</span>
            </div>


            {samplePlayers.map((player, index) => {
              return <div id={`seven_card_result_player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)} }`}>
                <Player result={gameInfo?.result} allLeftExceptWinner={allLeftExceptWinner} allNewPlayers={allNewPlayers} index={index} totalPlayers={allNewPlayers.length} winner={winner} player={player} handleShowCards={handleShowCards} gameResult={gameResult} key={index}
                  position={player?.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, samplePlayers.length)} /></div>
            })}
          </div>
        </div>

        <div className='absolute bottom-5 left-1/2 transform -translate-x-1/2 w-max'>
          <button disabled={isLoading} onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] disabled:cursor-not-allowed hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5 font-bold px-5 rounded-lg'>{isLoading && <HourglassEmptyIcon className='animate-spin font-bold' fontSize='14' />} Press here to play/continue... </button>
        </div>
      </div>
      {showCards && <PlayerShowCardsModal show={!isEmpty(showCards)} handleClose={() => setShowCards([])} cards={showCards} />}

    </>
  )
}

export default GameResult
