import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import hotToast from 'react-hot-toast';
import axiosInstance from '../../../../../../Config/axios';

const PlayButtons = ({ pot, maxBet, currentMoveInfo, animateFlow }) => {
    const userData = useSelector(state => state.auth.userData)
    const { gameAddress } = useParams()
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if (currentMoveInfo?.userId === userData.id) {
            setIsSelected(false)
        }
    }, [currentMoveInfo])

    const handleMoveSubmit = async (move) => {
        try {
            setIsSelected(true)
            const bodyData = { gameAddress, move }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/move', bodyData)   
            
            const { data: responseData } = response

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { position: 'top-right', duration: 2000 })
            }
            
        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { position: 'top-right', duration: 2000 })
        }
    }

    if (isSelected) return null

    return (
            <div className='h-full flex flex-col w-full justify-start items-center  ml-3 '>
            <span onClick={() => handleMoveSubmit('draw')} role='button' className='w-full text-center mb-2 highlow_action_buttons_high_and_low_button bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black font-bold py-2 px-5 rounded   text-xs'>
                Draw  
            </span>
            <span onClick={() => handleMoveSubmit('pass')} role='button' className='w-full text-center highlow_action_buttons_high_and_low_button bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black font-bold py-2 px-5 rounded inset-10 text-xs'>
                Pass 
            </span>
            </div>
    )
}

export default PlayButtons
