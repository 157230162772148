import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../Config/axios';
import { useNavigate } from 'react-router-dom';
import JoinGameModal from '../../../CommonComponent/JoinGameModal';
import NodataFound from '../../../CommonComponent/NodataFound';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

function App() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [joinModalInfo, setJoinModalInfo] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [favorites, setFavorites] = useState([]); // State for favorites

  const getgameAdr = (id) => {
    axiosInstance.post('/api/game/create', { gameId: id }).then((res) => {
      navigate(`/gameplay/${res.data.data.gameAddress}`);
    });
  };

  const fetchGameList = () => {
    axiosInstance.post("/api/game/fetchfav")
      .then((res) => {
        setGameList(res.data.data.data);
        setFavorites(res.data.data.data.map(game => game.id)); // Set initial favorites
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const RemovetoFav = (id) => {
    axiosInstance.post("/api/game/remtofav", { gameId: id })
      .then((res) => {
        toast.success("Removed successfully");
        fetchGameList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const AddtoFav = (id) => {
    axiosInstance.post("/api/game/addtofav", { gameId: id })
      .then((res) => {
        toast.success("Added to Favorites");
        fetchGameList();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchGameList();
  }, []);

  const handleJoinModal = (game) => {
    setJoinModalInfo(game);
  };

  const handleJoinGameSubmit = (values) => {
    navigate(`/gameplay/${values.gameAddress}?join=true`);
  };

  const handleMouseEnter = (id) => {
    setDropdownOpen(id);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  const handleFavoriteToggle = (gameId) => {
    if (favorites.includes(gameId)) {
      RemovetoFav(gameId);
      setFavorites(favorites.filter((id) => id !== gameId));
    } else {
      AddtoFav(gameId);
      setFavorites([...favorites, gameId]);
    }
  };

  return (
    <>
      {gameList.length !== 0 ? (
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {gameList?.map((game) => (
            <motion.div
              key={game.id}
              className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.1 }}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex justify-end px-4 pt-4">
                <button
                  id="dropdownButton"
                  onMouseEnter={() => handleMouseEnter(game.id)}
                  className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                  type="button"
                >
                  <span className="sr-only">Open dropdown</span>
                  <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                    <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                  </svg>
                </button>
                <div
                  id="dropdown"
                  onMouseLeave={handleMouseLeave}
                  className={`z-10 absolute right-0 mt-2 ${dropdownOpen === game.id ? 'block' : 'hidden'} mt-5 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}
                >
                  <ul className="py-2" aria-labelledby="dropdownButton">
                    <li>
                      <a
                        onClick={() => RemovetoFav(game.id)}
                        className="block px-4 py-2 cursor-pointer text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                      >
                        Remove from Favorites
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col items-center pb-10 relative">
                <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={game.image} alt={game.title} />
                <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{game.title}</h5>
                <p className="text-sm text-gray-500 dark:text-gray-400"><strong>Min Players:</strong> {game.min_players}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400"><strong>Max Players:</strong> {game.max_players}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400"><strong>Minimum Bet:</strong> {game.minimum_bet}</p>
                <div className="flex mt-4 md:mt-6">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => getgameAdr(game.id)}
                  >
                    Create Table
                  </button>
                  <button
                    className="text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 ms-2 text-center"
                    onClick={() => handleJoinModal(game)}
                  >
                    Join With ID
                  </button>
                </div>
                {/* Favorite Icon */}
                <button
                  onClick={() => handleFavoriteToggle(game.id)}
                  className="absolute top-4 right-4 focus:outline-none"
                >
                  {favorites.includes(game.id) ? (
                    <FavoriteIcon style={{ color: '#FF0000' }} />
                  ) : (
                    <FavoriteBorderIcon style={{ color: '#FF0000' }} />
                  )}
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <NodataFound />
      )}

      {!isEmpty(joinModalInfo) && (
        <JoinGameModal
          data={joinModalInfo}
          show={setJoinModalInfo}
          handleSubmit={handleJoinGameSubmit}
          handleClose={() => setJoinModalInfo(null)}
        />
      )}
    </>
  );
}

export default App;
