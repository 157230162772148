import React, { useEffect, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import axiosInstance from '../../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const PlayButtons = ({ pot, maxBet, currentMoveInfo, handleSelected, isSelected }) => {
    const userData = useSelector(state => state.auth.userData)
    const { gameAddress } = useParams()

    const formik = useFormik({
        initialValues: {
            betAmount: maxBet
        },
        validationSchema: Yup.object({
            betAmount: Yup.number(),
        }),
        onSubmit: (values) => {
            handleActionSubmit(values)
        }
    })

    const handleActionSubmit = async (values) => {
        try {
            handleSelected(true)
            const body = { action: values.action, amount: values.betAmount, gameAddress }
            const response = await axiosInstance.post('/api/game/highlow/play-move', body)

            const { data: responseData } = response

            if (!responseData.status) {
                handleSelected(false)
                toast.error(responseData.message, { duration: 1000, position: 'bottom-center' })
            }

        } catch (error) {
            handleSelected(false)
            toast.error(`Error: ${error.message}`, { duration: 1000, position: 'bottom-center'})
        }
    }

    const handleHighSubmit = (e) => {
        e.preventDefault();
        formik.setFieldValue('action', 'high', false);
        formik.handleSubmit();
    };

    const handleLowSubmit = (e) => {
        e.preventDefault();
        formik.setFieldValue('action', 'low', false);
        formik.handleSubmit();
    };

    const handleIncrement = () => {
        if (Number(formik.values.betAmount).toFixed(2) >= Number(Number(pot).toFixed(2))) {
            toast.error(`Amount cannot be more than ${Number(pot).toFixed(2)}`,
                {
                    duration: 1000,
                    position: 'bottom-center',
                }
            )
            return
        }
        formik.setValues({
            ...formik.values,
            betAmount: formik.values.betAmount + 0.1
        })
    }

    const handleDecrement = () => {
        if (Number(formik.values.betAmount).toFixed(2) <= Number(maxBet).toFixed(2)) {
            toast.error(`Amount cannot be less than ${maxBet}`,
                {
                    duration: 1000,
                    position: 'bottom-center',
                }
            )
            return
        }
        formik.setValues({
            ...formik.values,
            betAmount: formik.values.betAmount - 0.1
        })
    }

    // useEffect(() => {
    //     if (currentMoveInfo?.currentPlayer == userData?.id) {
    //         handleSelected(true)
    //     }
    // }, [currentMoveInfo])

    if (isSelected) return


    return (
        <form onSubmit={formik.handleSubmit} className='px-2 highlow_action_buttons_high_and_low flex flex-col items-center justify-between w-full '>

            <div className='hilow_action_inputs flex items-center justify-between w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 mb-3'>
                <span role='button' onClick={handleDecrement} className='py-2 px-4 rounded w-50  border-2 border-white  text-white w-auto'>
                    <RemoveIcon />
                </span>
                <div className='w-100  mx-2'>
                    <input disabled value={Number(formik.values.betAmount).toFixed(2)} type='number' min={maxBet} max={pot} className='py-2 px-4 rounded w-100 bg-transparent border-2 border-white text-white font-extrabold' text-white />
                </div>

                <span role='button' onClick={handleIncrement} className='py-2 px-4 rounded w-50 mr-1 border-2 border-white  text-white w-auto'>
                    <AddIcon />
                </span>

            </div>


            <div className='highlow_action_buttons_high_and_low flex items-center justify-between w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 '>
                <span role='button' onClick={handleHighSubmit} className='poker_table_golden text-black text-xs text-center highlow_action_buttons_high_and_low_button bg-yellow-600  font-bold py-1 px-4 rounded w-50 mr-1'>
                    High  <ArrowUpwardIcon />
                </span>
                <span role='button' onClick={handleLowSubmit} className='poker_table_golden text-black text-xs text-center highlow_action_buttons_high_and_low_button bg-yellow-600  font-bold py-1 px-4 rounded w-50 ml-1 inset-10'>
                    Low  <ArrowDownwardIcon />
                </span>
            </div>
        </form>
    )
}

export default PlayButtons
