import React, { useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify';
import ToastContent from '../../../../../CommonComponent/Toast';
import axiosInstance from '../../../../../Config/axios';

import Player from './Player';
import StartGameAction from './StartGameAction';
import MenuActions from './MenuActions';
import hotToast from 'react-hot-toast';
import Loader from '../../../../ChatingComponent/loader';
import Banner from "../../../../../assets/img/games/poker_background_image.png";

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useSelector } from 'react-redux';
import Card from '../../PokerTest/Components/Pages/Cards';
const WaitingPlayers = ({ gameInfo, fetchPlayers, players }) => {

  const gameCreated = gameInfo?.createdBy
  const navigate = useNavigate()
  const newPlayers = players

  let joinedPlayers = newPlayers


  const { gameAddress } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const userData = useSelector(state => state.auth.userData)

  const isInGame = newPlayers.filter(player => player.userId == userData?.id).length > 0


  const handleJoinGame = async () => {


    try {
      if (gameInfo.status === 'started') {
        // toast.error(<ToastContent status='error' message='Game already started' />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // });
        hotToast.error('Game already started', { duration: 5000, position: 'bottom-center' })

        fetchPlayers()
        return
      }

      setIsLoading(true)
      const bodyData = { gameAddress: gameAddress }
      const response = await axiosInstance.post('/api/game/poker/join', bodyData)

      const { data: responseData } = response

      if (!responseData.status) {
        setIsLoading(false)
        // return toast.error(<ToastContent status='error' message={responseData.message} />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // })
        return hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
      }
      setIsLoading(false)
      // toast.success(<ToastContent status='success' message={responseData.message} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.success(responseData.message, { duration: 5000, position: 'bottom-center' })

      const data = { slug: 'poker', gameAddress: gameAddress }
      localStorage.setItem('currentJoinedGame', JSON.stringify(data))

      fetchPlayers()
    } catch (error) {
      setIsLoading(false)
      hotToast.error('Error joining game', { duration: 5000, position: 'bottom-center' })
    }
  }



  let isJoined = false
  const currentUser = players.find(player => player.address == userData?.address)
  if (currentUser && currentUser.status == 'pending') {
    isJoined = true
  }

  if (isJoined) {
    const myProfile = players.find(player => player.address == userData?.address)
    const otherPlayers = players.filter(player => player.address != userData?.address)

    joinedPlayers = [myProfile, ...otherPlayers]
  }

  let gameCanStart = false
  const totalPlayers = joinedPlayers.length
  if (totalPlayers >= gameInfo?.minPlayers) {
    gameCanStart = true
  }

  let allPlayers = []
  const myProfile = joinedPlayers.find(player => player.address == userData?.address)
  if (myProfile) {
    allPlayers = [myProfile, ...joinedPlayers.filter(player => player.address != userData?.address)]
  } else {
    allPlayers = joinedPlayers
  }

  const getClassName = (index) => {
    if (index == 0) {
      return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
    }
    if (index == 1) {
      return `absolute bottom-10 left-1/4  -translate-x-1/2`;
    }
    if (index == 2) {
      return `absolute left-5 bottom-1/4 translate-x-1/4`;
    }
    if (index == 3) {
      return `absolute left-0 top-1/4 translate-x-1/4`;
    }
    if (index == 4) {
      return `absolute top-0 left-1/4 -translate-x-1/4 `;
    }
    if (index == 5) {
      return `absolute top-0 right-1/4 translate-x-1/4 `;
    }
    if (index == 6) {
      return `absolute right-0 top-1/4 -translate-x-1/4`;
    }
    if (index == 7) {
      return `absolute right-0 bottom-1/4 -translate-x-1/4`;
    }
    if (index == 8) {
      return `absolute right-1/4 -bottom-0 translate-x-1/2 `;
    }

  }

  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('poker_waiting_table');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }

  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/poker/leave', { gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
      } else {
        navigate('/all-game-page');
      }

    } catch (error) {
      hotToast.error('Error leaving game', { duration: 5000, position: 'bottom-center' })
    }
  }

  const getPlaceHolderPlayerPosition = (index, totalPlayers) => {


    if (totalPlayers == 9) {
      if (index == 0) {
        return `absolute -bottom-10 left-1/2 -translate-x-1/2 -translate-y-1/2`;
      }
      if (index == 1) {
        return `absolute bottom-10 sm:bottom-0 left-1/4  -translate-x-1/2`;
      }
      if (index == 2) {
        return `absolute left-0 sm:left-5 bottom-1/4 `;
      }
      if (index == 3) {
        return `absolute left-0 sm:left-5 top-1/4 `;
      }
      if (index == 4) {
        return `absolute top-0 left-1/4 -translate-x-1/4 `;
      }
      if (index == 5) {
        return `absolute top-4 sm:top-0 right-1/4 translate-x-1/4 `;
      }
      if (index == 6) {
        return `absolute right-6 sm:right-16 top-1/4 `;
      }
      if (index == 7) {
        return `absolute right-6 sm:right-16 bottom-1/4`;
      }
      if (index == 8) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }
      if (index == 9) {
        return `absolute right-1/4 bottom-10 sm:bottom-0 translate-x-1/2 `;
      }

    }

  };

  let samplePlayers = newPlayers
  if (samplePlayers.length == 2) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 3) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, { fake: true }, thirdPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 4) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, { fake: true }, thirdPlayer, fourthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 5) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, { fake: true }, { fake: true }];
  } else if (samplePlayers.length == 6) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    samplePlayers = [firstPlayer, { fake: true }, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, { fake: true }];
  } else if (samplePlayers.length == 7) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    samplePlayers = [firstPlayer, { fake: true }, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, { fake: true },];
  } else if (samplePlayers.length == 8) {
    const firstPlayer = samplePlayers[0];
    const secondPlayer = samplePlayers[1];
    const thirdPlayer = samplePlayers[2];
    const fourthPlayer = samplePlayers[3];
    const fifthPlayer = samplePlayers[4];
    const sixthPlayer = samplePlayers[5];
    const seventhPlayer = samplePlayers[6];
    const eighthPlayer = samplePlayers[7];
    samplePlayers = [firstPlayer, secondPlayer, thirdPlayer, fourthPlayer, fifthPlayer, sixthPlayer, seventhPlayer, eighthPlayer, { fake: true }];
  } else {
    samplePlayers = samplePlayers;
  }
  const handleFullScreen = () => {
    let id = document.getElementById('poker_waiting_table')

    // if full screen is enable then disable it
    if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) { /* Chrome */
        document.webkitCancelFullScreen();
      } else if (document.webkitCancelFullscreen) { /* Chrome */
        document.webkitCancelFullscreen();
      }
      return;
    }
    if (id.requestFullscreen) {
      id.requestFullscreen();
    } else if (id.webkitRequestFullscreen) { /* Safari */
      id.webkitRequestFullscreen();
    } else if (id.msRequestFullscreen) { /* IE11 */
      id.msRequestFullscreen();
    } else if (id.mozRequestFullScreen) { /* Firefox */
      id.mozRequestFullScreen();
    } else if (id.webkitEnterFullscreen) { /* Chrome */
      id.webkitEnterFullscreen();
    } else if (id.webkitEnterFullScreen) { /* Chrome */
      id.webkitEnterFullScreen();
    }
  }


  const sideCards = [
    { Rank: 'A', Suit: 'D' },
    { Rank: 'K', Suit: 'D' },
    { Rank: 'Q', Suit: 'D' },
    { Rank: 'J', Suit: 'D' },
    { Rank: '10', Suit: 'D' },
  ]

  return (
    // <div id='poker_waiting_table'>

    //   <MenuActions isInGame={isInGame} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} />
    //   <div className=" poker_table container px-5 h-2/3 rounded-lg shadow-lg top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">

    //     <div className=" p-3 border-2 shadow-in h-[80%] w-full bg-green-900  rounded-full"
    //     >
    //       <div className=" relative gap-4 p-4 border-2 h-full w-full  rounded-full bg-gradient-to-r from-emerald-800 to-emerald-900">
    //         {allPlayers.map((player, index) => {
    //           return (
    //             <div id={`player_${player?.userId}`} className={`${getClassName(index, player.userId)}`} >
    //               <Player index={index} player={player} />
    //             </div>
    //           )
    //         })}

    //       </div>

    //     </div>

    // <div className='w-full h-auto mt-5 container rounded-lg'>
    //   <div className='flex items-center justify-center h-full'>
    //     {isInGame && !gameCanStart && <span className='text-white font-bold text-md'>
    //       {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
    //     </span>}
    //     {!isInGame && (
    //       <button
    //         onClick={handleJoinGame}
    //         className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
    //       >
    //         Join Game <Loader />
    //       </button>
    //     )}
    //     {gameCanStart && <StartGameAction fetchGameInfo={fetchPlayers} />}
    //   </div>
    // </div>



    //   </div>
    // </div>


    <div id='poker_waiting_table' className=" bg-gradient-to-br from-slate-950 via-slate-800 to-slate-950 h-screen w-full mx-auto p-2 pt-5 flex justify-center items-start flex-col">

      <div className='mx-auto mt-3 px-3 container absolute top-0 left-0 bg-transparent font-extrabold ' >
        {/* <CloseIcon onClick={() => navigate(-1)} className='cursor-pointer text-white ' />
        <div className='flex flex-row items-center justify-between gap-1 absolute top-0 right-0 p-0 '>
          <FullscreenIcon onClick={handleFullScreen} className='bg-slate-700 border-2 border-black font-extrabold shadow-white cursor-pointer hover:scale-105 active:scale-110 shadow-sm text-yellow-500 rounded-md' />
        </div> */}
        <MenuActions isInGame={isInGame} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} />

      </div>
      {/* bg-gradient-to-tr from-[#A67C00] via-[#FFD700] to-[#A67C00] */}
      <div className="poker_table_golden relative w-full h-[70vh] sm:h-[75vh] lg:h-[67vh] border-2 border-gray-300 rounded-full shadow-xl  p-4 sm:p-4 md:p-4 lg:p-6 "
        // style={{
        //   backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/01/62/74/18/1000_F_162741816_xRzkqyvs0QlxyLGystND2FjXW6IUP8XD.jpg')`,
        //   backgroundSize: 'cover',
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'center',
        //   boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
        // }}
      >

      

        <div className="absolute   md:block top-1/2 left-1/4 -translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
          <img src={Banner} className='h-32 w-30' />
        </div>
        <div className="absolute   md:block top-1/2 right-1/4 translate-x-1/2  -translate-y-1/2 text-slate-700 text-2xl font-bold opacity-30 h-36">
          <img src={Banner} className='h-32 w-30' />
        </div>

        <div className=" h-full bg-gradient-to-tr from-green-950 via-green-900 to-green-950 border-1 rounded-full shadow-xl border-gray-400" style={{
          boxShadow: `0px 5px 50px -4px #000 inset, 0px -7px 10px -4px #000 inset`
        }}>

          <div className='flex md:hidden w-full text-center flex-col absolute top-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
            <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
          </div>
          <div className='flex w-full text-center flex-col absolute bottom-1/4 left-1/2 transform -translate-x-1/2 opacity-90'>
            <h2 className=' sm:text-2xl md:text-6xl lg:text-6xl xl:text-6xl text-[#FFD700] w-full font-extrabold mt-2 font-banger'>Texas Hold'em </h2>
          </div>
          {samplePlayers.map((player, index) => {
            return <div id={`player_${player?.userId}`} className={`${player.fake ? getPlaceHolderPlayerPosition(index, samplePlayers.length) : getClassName(index, player.userId)}`} >
              <Player index={index} player={player} />
            </div>
          })}


        </div>

      </div>


      <div className='w-full h-auto mt-5 container rounded-lg'>
        <div className='flex items-center justify-center h-full'>
          {isInGame && !gameCanStart && <span className='text-white font-bold text-md text-center'>
            {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
          </span>}
          {!isInGame && (
            <button
              onClick={handleJoinGame}
              className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
            >
              Join Game
            </button>
          )}
          {(gameCanStart && (gameCreated == userData?.id)) && <StartGameAction fetchGameInfo={fetchPlayers} />}
        </div>
      </div>
    </div>
  )
}

export default WaitingPlayers