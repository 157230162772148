import React from 'react'
import { GiCardAceHearts } from "react-icons/gi";

const HiddenCard = () => {
  return (
    <div id='high_low_hidden_card' className='flipped hi_lo_hidded_card w-16 h-24 bg-[#ac8812] text-white text-xs flex justify-center items-center flex-col font-bold rounded-lg border-2 bordewhtie
    '>
    </div>
  )
}

export default HiddenCard
