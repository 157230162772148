// src/GameModal.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Leaderboard from './Leaderboard';
import { styled } from '@mui/material/styles';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#333',
    color: 'white',
    borderRadius: theme.shape.borderRadius,
  },
}));

const GameModal = ({ open, onClose, title, data }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{ backgroundColor: '#4444', color: 'white' }}
      >
        <div className="flex justify-between items-center">
          <span className="text-xl font-bold">{title}</span>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ backgroundColor: '#333', padding: '24px' }}
      >
        <Leaderboard title={title} data={data} />
      </DialogContent>
    </CustomDialog>
  );
};

export default GameModal;
